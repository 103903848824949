import { ComponentProps, FC, useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { clearRegistrationForm, createOrUpdateOfferCourse, getOfferCourse, getOfferPricingCourse } from '../../../global-state/actions/course-actions';
import { useTranslation } from "react-i18next";
import HeaderTrainingFormComponent from "../../../components/formations-card/header-formation-form";
import SelectElement from "../../../elements/select/select-element";
import TarificationPeriod from "../../../components/tarification/payment-period.component";
import { debounce } from "lodash";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useHistory } from "react-router";
import { returnStartPathUrl } from "../../../helpers/domainCheck";
import { clearHistoryArray, redirect } from "../../../global-state/actions/breadcrumb-actions";
import axiosInstance from "../../../helpers/interceptors";
import { useParams } from "react-router-dom";
import { fetchOfferIdByTrainingId } from "../../../services/finance.service";

interface Params {
    id: string
}


const TarificationTrainingPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const history = useHistory();

    const [snackBarOpen, setSnackBarOpen] = useState(false)
    const [typeTarification, setTypeTarification] = useState<any>();
    const [offerTraining, setOfferTraining] = useState<any>(props?.courseTarificationForm ?? {});
    const [paymentSubscription, setPaymentSubscription] = useState<boolean>();
    const [paymentPeriodic, setPaymentPeriodic] = useState<boolean>();
    const [monthRulesClass, setMonthRulesClass] = useState<any>('form-control input text-default')
    const [priceUniqueClass, setPriceUniqueClass] = useState<any>('form-control input text-default')
    const [priceUniqueEror, setPriceUniqueError] = useState<any>('')

    const [priceAbnClass, setPriceAbnClass] = useState<any>('form-control input text-default')
    const [priceAbnEror, setPriceAbnError] = useState<any>('')
    let [defaultValueAbn, setDefaultValueAbn] = useState("none");
    const [priceAbn, setPriceAbn] = useState<any>();
    const [paymentUnique, setPaymentUnique] = useState<boolean>(false);
    const [unique, setUnique] = useState<boolean>(false);
    const [priceUnique, setPriceUnique] = useState<any>();
    let [defaultValue, setDefaultValue] = useState("none");
    const { id } = useParams<Params>();
    const [payRules, setPayRules] = useState<any>(1);
    const [idOffer, setIdOffer] = useState<Number | undefined>(undefined);

    useEffect(() => {
        if (props?.courseForm?.id !== undefined && props?.courseForm?.id !== null) {
            fetchTrainingOffer(props?.courseForm?.id)
        }
    }, [props.courseForm]);


    const fetchTrainingOffer = async (idTraining: number) => {
        await fetchOfferIdByTrainingId(idTraining).then((res: any) => {
            if (typeof res === 'number') {
                props?.getOfferPricingCourse(res)
            }
        })
    }

    useEffect(() => {
        if (props?.courseTarificationForm !== undefined && offerTraining !== props?.courseTarificationForm) {
            setOfferTraining(props?.courseTarificationForm)
        }
    }, [props?.courseTarificationForm])




    useEffect(() => {
        if (props?.actualOffer?.id !== undefined) {
            setIdOffer(props?.actualOffer?.id)
        }
    }, [props?.actualOffer]);

    useEffect(() => {
        if (payRules == '0') {
            setMonthRulesClass("form-control input text-default error")
        } else {
            setMonthRulesClass("form-control input text-default")
        }
    }, [payRules])

    useEffect(() => {
        if (priceUnique == '0') {
            setPriceUniqueClass("form-control input text-default error")
            setPriceUniqueError('Saisissez un montant supérieur à zéro')
        } else {
            setPriceUniqueClass("form-control input text-default")
            setPriceUniqueError('')
        }
    }, [priceUnique])

    useEffect(() => {
        if (priceAbn === '0') {
            setPriceAbnClass("form-control input text-default error")
            setPriceAbnError('Saisissez un montant supérieur à zéro')
        } else {
            setPriceAbnClass("form-control input text-default")
            setPriceAbnError('')
        }
    }, [priceAbn])

    const [paymentEasy, setPaymentEasy] = useState<boolean>(false);
    const [easy, setEasy] = useState<boolean>(false);
    const [items, setItems] = useState([
        { index: 0, mp: undefined, due: undefined },
    ]);

    const ajouterArray = () => {
        setItems(prevArray => [...prevArray, { index: prevArray.length, mp: undefined, due: undefined }]);
    };

    const supprimerArray = async (index: number, price: any, period: any) => {
        setItems(prevArray => {
            const newArray = [...prevArray];
            newArray.splice(index, 1);
            return newArray;
        });

        if (price != undefined && period != undefined) {

            const index = offerTraining.pricings.findIndex((pricing: any) => pricing.period === "MONTH" && pricing.nbPeriod === period && pricing.price === price);
            if (index !== -1) {
                offerTraining.pricings.splice(index, 1);
                offerTraining.typePricing = "STATIC";
                offerTraining.stripeAccount = props?.paymentMethod?.accountId;

                await props.createOrUpdateOfferCourse(offerTraining)

                setSnackBarOpen(true);


            }
        }


    };


    const listAcess = [
        { label: "Illimité", value: "illimited", id: 1 },
        { label: "Période limitée", value: "limited", id: 2 },
    ]

    const listAcessAbn = [
        { label: "Semaine", value: "WEEK", id: 1 },
        { label: "Mois", value: "MONTH", id: 2 },
        { label: "Trimestre", value: "QUARTER", id: 3 },
        { label: "Semestre", value: "SEMESTER", id: 4 },
    ]

    const check = async (event: any) => {
        setTypeTarification(event.target.value)

        if (event.target.value === "Free") {
            let objRules;
            let checkRulesExist = false;
            let pricings = [{
                id: null,
                period: "ONESHOT",
                status: "ACTIVE",
                price: 0,
                currency: "EUR",
                startDate: new Date(),
                endDate: null
            }]
            if (!offerTraining)
                setOfferTraining(props?.courseTarificationForm ?? {})
            const index = offerTraining.rules.findIndex((rule: any) => rule.key === "nb_month_access");

            if (index !== -1) {
                objRules = { ...offerTraining.rules[index] };
                offerTraining.rules.splice(index, 1);
                checkRulesExist = true
            }



            if (checkRulesExist) {
                objRules.value = "-1"
                offerTraining.rules.push(objRules);

            } else {
                objRules =
                {
                    id: null,
                    key: "nb_month_access",
                    value: "-1",
                    valueType: "number",
                    code_product: "CRS"
                };
                offerTraining.rules.push(objRules);
            }


            offerTraining.pricings = pricings;
            offerTraining.typePricing = "STATIC";
            offerTraining.stripeAccount = props?.paymentMethod?.accountId;
            await props.createOrUpdateOfferCourse(offerTraining)

            setPriceUnique(undefined)
            setUnique(false)
            setDefaultValue("none")
            setDefaultValueAbn("none")
            setPriceAbn(undefined)
            setEasy(false)
            setPaymentEasy(false)
            setPayRules(undefined)
            setPaymentUnique(false)
            setItems([{ index: 0, mp: undefined, due: undefined }])
            setSnackBarOpen(true);




        }
        if (event.target.value == "Pay") {
            if (!offerTraining)
                setOfferTraining(props?.courseTarificationForm ?? {})
            offerTraining.pricings = [];
            offerTraining.typePricing = "STATIC";
            offerTraining.stripeAccount = props?.paymentMethod?.accountId;
            await props.createOrUpdateOfferCourse(offerTraining)

            setDefaultValueAbn("none")
            setPriceAbn(undefined)
            setSnackBarOpen(true);


        }
        if (event.target.value == "Abn") {
            if (!offerTraining)
                setOfferTraining(props?.courseTarificationForm ?? {})
            offerTraining.pricings = [];
            offerTraining.typePricing = "STATIC";
            offerTraining.stripeAccount = props?.paymentMethod?.accountId;
            await props.createOrUpdateOfferCourse(offerTraining)

            setDefaultValueAbn("none")
            setPriceAbn(undefined)
            setUnique(false)
            setDefaultValue("none")
            setEasy(false)
            setPaymentEasy(false)
            setPayRules(undefined)
            setPaymentUnique(false)
            setItems([{ index: 0, mp: undefined, due: undefined }])
            setSnackBarOpen(true);

        }

    }

    const addPricingUnique = async (price: any, uniqueChecked: any, easyCheck: any) => {

        setPriceUnique(price)


        if (price === 0) {
            return
        } else {
            let objPricing;
            let checkUniqueExist = false;
            if (uniqueChecked && price !== undefined) {
                if (offerTraining?.pricings.length > 0) {
                    const index = offerTraining.pricings.findIndex((pricing: any) => pricing.period === "ONESHOT" && pricing.nbPeriod === null && pricing.price !== 0);
                    if (index !== -1) {
                        objPricing = { ...offerTraining.pricings[index] };
                        objPricing.price = price;
                        checkUniqueExist = true;
                        offerTraining.pricings.splice(index, 1);
                    }
                }
                if (easyCheck === false) {
                    offerTraining.pricings = []
                }

                if (checkUniqueExist) {
                    offerTraining.typePricing = "STATIC";
                    offerTraining.pricings.push(objPricing);
                    offerTraining.stripeAccount = props?.paymentMethod?.accountId;
                    await props.createOrUpdateOfferCourse(offerTraining)

                    setSnackBarOpen(true);
                    setTypeTarification('Pay')
                    setPriceAbn(undefined)
                    setDefaultValueAbn("none")


                } else {
                    objPricing = {
                        id: null,
                        period: "ONESHOT",
                        status: "ACTIVE",
                        price: price,
                        currency: "EUR",
                        startDate: new Date(),
                        endDate: null
                    }
                    offerTraining.typePricing = "STATIC";
                    offerTraining.pricings.push(objPricing);
                    offerTraining.stripeAccount = props?.paymentMethod?.accountId;
                    await props.createOrUpdateOfferCourse(offerTraining)

                    setSnackBarOpen(true);

                    setTypeTarification('Pay')
                    setPriceAbn(undefined)
                    setDefaultValueAbn("none")


                }


            }
        }
    }

    const addPricingFacility = async (data: any, uniqueChecked: any) => {

        setItems(prevItems => {
            const index = prevItems.findIndex(item => item.index === data.index);
            if (index !== -1) {
                const updatedItem = { ...prevItems[index], mp: data.mp, due: data.due };
                return [...prevItems.slice(0, index), updatedItem, ...prevItems.slice(index + 1)];
            }
            return prevItems;
        });

        let objPricing: any[] = [];

        items?.forEach(item => {
            const matchingPricing = offerTraining?.pricings?.find((pricing: any) => {
                return pricing?.nbPeriod === item?.due && pricing?.price === item?.mp;
            });
            if (matchingPricing) {
                objPricing?.push(matchingPricing);
            } else {
                objPricing?.push({
                    id: null,
                    period: "MONTH",
                    status: "ACTIVE",
                    price: data.mp,
                    nbPeriod: data.due,
                    currency: "EUR",
                    startDate: new Date(),
                    endDate: null
                });
            }
        });


        const index = offerTraining.pricings.findIndex((pricing: any) => pricing.period === "ONESHOT" && pricing.nbPeriod == null);
        if (index !== -1) {
            objPricing.push(offerTraining.pricings[index]);
        }


        offerTraining.typePricing = "STATIC";
        offerTraining.pricings = objPricing;
        offerTraining.stripeAccount = props?.paymentMethod?.accountId;
        await props.createOrUpdateOfferCourse(offerTraining)

        setSnackBarOpen(true);




    }

    const addPricingAbn = async (period: any, price: any) => {

        setPriceAbn(price)
        let objPricing;
        let checkUniqueExist = false;
        let objRules;
        if (period !== "none" && price !== undefined) {
            if (price === 0) return


            if (offerTraining?.pricings.length > 0) {
                const index = offerTraining.pricings.findIndex((pricing: any) => pricing.period in ["MONTH", "WEEK", "QUARTER", "SEMESTER"] && pricing.nbPeriod === null && pricing.price !== 0);
                if (index !== -1) {
                    objPricing = { ...offerTraining.pricings[index] };
                    objPricing.price = price;
                    objPricing.period = period;
                    checkUniqueExist = true;
                }
            }

            const index = offerTraining.rules.findIndex((rule: any) => rule.key === "nb_month_access");

            if (index !== -1) {
                objRules = { ...offerTraining.rules[index] };
                objRules.value = "-1"
                offerTraining.rules.splice(index, 1);
                offerTraining.rules.push(objRules)
            }

            offerTraining.pricings = []


            if (checkUniqueExist) {
                offerTraining.typePricing = "STATIC";
                offerTraining.pricings.push(objPricing);
                offerTraining.stripeAccount = props?.paymentMethod?.accountId;
                await props.createOrUpdateOfferCourse(offerTraining)

                setSnackBarOpen(true);
                setPayRules(undefined);
                setUnique(false)
                setEasy(false)
                setPaymentEasy(false)
                setPaymentUnique(false)
                setItems([{ index: 0, mp: undefined, due: undefined }])
                setDefaultValue("none")
                setPriceUnique(null)



            } else {
                objPricing = {
                    id: null,
                    period: period,
                    status: "ACTIVE",
                    price: price,
                    currency: "EUR",
                    startDate: new Date(),
                    endDate: null
                }
                offerTraining.pricings = []
                offerTraining.typePricing = "STATIC";
                offerTraining.pricings.push(objPricing);
                offerTraining.stripeAccount = props?.paymentMethod?.accountId;
                await props.createOrUpdateOfferCourse(offerTraining)

                setSnackBarOpen(true);
                setPayRules(undefined);
                setUnique(false)
                setEasy(false)
                setPaymentEasy(false)
                setPaymentUnique(false)
                setItems([{ index: 0, mp: undefined, due: undefined }])
                setDefaultValue("none")
                setPriceUnique(null)




            }


        }
    }

    const handleClose = () => {
        setSnackBarOpen(false)
    }

    const addRulesAccess = async (period: any, periodMonth: any, type: any) => {

        let objRules;
        let checkRulesExist = false;
        if (type === "Pay" && period !== "none") {

            if (period == "limited" && periodMonth !== undefined) {
                setPayRules(periodMonth)
                if (periodMonth == 0) return
                if (offerTraining.rules) {

                    const index = offerTraining.rules.findIndex((rule: any) => rule.key === "nb_month_access");

                    if (index !== -1) {
                        objRules = { ...offerTraining.rules[index] };
                        offerTraining.rules.splice(index, 1);
                        checkRulesExist = true
                    }


                    if (checkRulesExist) {
                        objRules.value = periodMonth
                        offerTraining.typePricing = "STATIC";
                        offerTraining.rules.push(objRules);
                        offerTraining.stripeAccount = props?.paymentMethod?.accountId;
                        await props.createOrUpdateOfferCourse(offerTraining)

                        setSnackBarOpen(true);




                    } else {
                        objRules =
                        {
                            id: null,
                            key: "nb_month_access",
                            value: periodMonth,
                            valueType: "number",
                            code_product: "CRS"
                        };
                        offerTraining.typePricing = "STATIC";
                        offerTraining.rules.push(objRules);
                        offerTraining.stripeAccount = props?.paymentMethod?.accountId;
                        await props.createOrUpdateOfferCourse(offerTraining)

                        setSnackBarOpen(true);





                    }

                }

            } else if (period === "illimited") {
                if (offerTraining.rules) {
                    const index = offerTraining.rules.findIndex((rule: any) => rule.key === "nb_month_access");
                    if (index !== -1) {
                        objRules = { ...offerTraining.rules[index] };
                        offerTraining.rules.splice(index, 1);
                        checkRulesExist = true
                    }

                    if (checkRulesExist) {


                        objRules.value = "-1"
                        offerTraining.typePricing = "STATIC";
                        offerTraining.rules.push(objRules);
                        offerTraining.stripeAccount = props?.paymentMethod?.accountId;
                        await props.createOrUpdateOfferCourse(offerTraining)

                        setSnackBarOpen(true);




                    } else {
                        objRules =
                        {
                            id: null,
                            key: "nb_month_access",
                            value: "-1",
                            valueType: "number",
                            code_product: "CRS"
                        };
                        offerTraining.typePricing = "STATIC";
                        offerTraining.rules.push(objRules);
                        offerTraining.stripeAccount = props?.paymentMethod?.accountId;
                        await props.createOrUpdateOfferCourse(offerTraining)

                        setSnackBarOpen(true);





                    }

                }
            }

        }
    }

    const checkPaymentUnique = async (uniqueChecked: any) => {

        setUnique(uniqueChecked)
        if (!uniqueChecked) {
            if (offerTraining?.pricings?.length > 0) {
                const index = offerTraining.pricings.findIndex((pricing: any) => pricing.period === "ONESHOT" && pricing.nbPeriod === null && pricing.price === priceUnique);
                if (index !== -1) {
                    offerTraining.pricings.splice(index, 1);
                    offerTraining.typePricing = "STATIC";
                    offerTraining.stripeAccount = props?.paymentMethod?.accountId;
                    await props.createOrUpdateOfferCourse(offerTraining)

                    setPaymentUnique(false);
                    setPriceUnique(undefined)
                    setSnackBarOpen(true);



                }
            }
        }
    }

    const checkPaymenFacility = async (uniqueChecked: any) => {

        setEasy(uniqueChecked)
        if (!uniqueChecked) {
            if (offerTraining?.pricings.length > 0) {

                items.forEach(item => {
                    const matchingPricing = offerTraining.pricings.find((pricing: any) => {
                        return pricing.nbPeriod === item.due && pricing.price === item.mp;
                    });
                    if (matchingPricing) {
                        offerTraining.pricings.splice(matchingPricing, 1)
                    }
                });
                offerTraining.typePricing = "STATIC";
                offerTraining.stripeAccount = props?.paymentMethod?.accountId;
                await props.createOrUpdateOfferCourse(offerTraining)

                setPaymentEasy(false);
                let obj = { index: 0, mp: undefined, due: undefined }
                setItems([obj])
                setSnackBarOpen(true);



            }
        }
    }

    const redirectTo = () => {
        //history.push(`${returnStartPathUrl()}/finance/detail-course/` + offerTraining.id);

        props?.clearHistoryArray()
        props?.redirect(props?.courseForm?.title, `${returnStartPathUrl()}/finance/detail-course/` + offerTraining?.id)
        history.push(`${returnStartPathUrl()}/finance/detail-course/` + offerTraining?.id);
    }

    const upgrade = () => {
        history.push(`${returnStartPathUrl()}/list`);
    }

    const paymentMethod = () => {
        history.push(`${returnStartPathUrl()}/param`);
    }

    function getPaymentPeriodicValue(data: any) {
        for (let i = 0; i < data.length; i++) {
            if (data[i].key === 'payment_periodic') {
                return data[i].value === 'true';
            }
        }
        return false;
    }

    function getPaymentSubscriptionValue(data: any) {
        for (let i = 0; i < data.length; i++) {
            if (data[i].key === 'payment_subscription') {
                return data[i].value === 'true';
            }
        }
        return false;
    }

    useEffect(() => {
        setPaymentSubscription(getPaymentSubscriptionValue(props?.limitations))
        setPaymentPeriodic(getPaymentPeriodicValue(props?.limitations))
    }, [props?.limitations])

    useEffect(() => {
        if (typeTarification === 'Pay') setTypeTarification('Pay')
        if (props?.courseTarificationForm !== undefined) {
            setOfferTraining(props?.courseTarificationForm ?? {})
            let index = 0;
            let nexItems = []
            if (offerTraining && offerTraining.pricings && offerTraining.pricings.length > 0) {
                for (const pricing of offerTraining?.pricings) {
                    if (pricing.period === "ONESHOT" && pricing.nbPeriod === null && pricing.price !== 0) {
                        setTypeTarification('Pay')
                        setUnique(true)
                        setPaymentUnique(true)
                        setPriceUnique(pricing.price)
                    } if (pricing.period === "MONTH" && pricing.nbPeriod != null) {
                        setTypeTarification("Pay")
                        setEasy(true)
                        setPaymentEasy(true)

                        const newItem = { index, mp: pricing.price, due: pricing.nbPeriod };
                        index++;
                        nexItems.push(newItem)
                    }
                    if (pricing.price == 0 && pricing.period === "ONESHOT" && pricing.nbPeriod === null) {
                        setTypeTarification("Free")
                    }
                    if (["MONTH", "WEEK", "QUARTER", "SEMESTER"].includes(pricing.period) && pricing.nbPeriod == null) {
                        setDefaultValueAbn(pricing.period)
                        setPriceAbn(pricing.price)
                        setTypeTarification("Abn")
                    }
                }
                if (nexItems.length !== 0) {
                    setItems(nexItems)
                }
            }
            if (offerTraining?.rules?.length > 0) {
                for (const rules of offerTraining?.rules) {
                    if (rules.key === "nb_month_access") {
                        if (rules.value === "-1") {
                            setDefaultValue("illimited")

                        } else {
                            setPayRules(rules.value)
                            setDefaultValue("limited")
                        }
                    }
                }

            }

        }
    }, [props?.courseTarificationForm?.pricings, props?.courseTarificationForm?.rules])

    const debouncePriceUnique = useCallback(debounce(addPricingUnique, 1000), []);
    const debounceAccessPeriod = useCallback(debounce(addRulesAccess, 1000), []);
    const debouncePriceAbn = useCallback(debounce(addPricingAbn, 1000), []);

    useEffect(() => {
        if (id !== undefined && id !== null) {
            fetchOfferTraining()
        }
    }, [id])

    const fetchOfferTraining = async () => {
        await axiosInstance.get(process.env.REACT_APP_BASE_URL11 + `offers/offerId/` + id?.toString())
            .then((res) => {
                props?.getOfferPricingCourse(res.data)
            })
    }


    return (<>
        <div style={{ backgroundColor: '#FFFFFF' }} className="w-100 px-2">
            <HeaderTrainingFormComponent type={"COURSE"} title={t('tarificationTitle')} status={props?.courseForm?.status} trainingTitle={props?.courseForm?.title} creationDate={(props?.courseForm?.creationDate)?.substring(0, 10).split("-").reverse().join("/")} />
        </div>

        <Snackbar open={snackBarOpen} autoHideDuration={1000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <div style={{ marginTop: 68 }}>
                <Alert severity="success" onClose={handleClose}>
                    Modifications enregistrées !
                </Alert>
            </div>
        </Snackbar>

        <div className=" mt-4" >
            <div style={{ maxWidth: 1200 }} className="px-2 mx-auto d-flex flex-row py-4 justify-content-between">
                <div style={{
                    width: 798, backgroundColor: '#FFFFFF', minHeight: 147, paddingBottom: "23px",
                    pointerEvents: (props?.courseForm?.status === "PUBLISHED") ? 'none' : 'auto',

                }} className="d-flex flex-column">
                    <div className="d-flex align-items-center" style={{ marginLeft: "28px", marginTop: "30px" }}>
                        <div className="d-flex align-items-center me-4">
                            <input dir="ltr" className="input-style-radio" onClick={(e) => check(e)} readOnly type="radio" value={"Free"} name="Free" id="Free" checked={typeTarification === 'Free'} />
                            <label htmlFor="Free"></label>
                            <span onClick={() => check({ target: { value: 'Free' } })} style={{ cursor: 'pointer' }} className={`body-md black-800 ps-2  ${typeTarification === 'Free' ? 'underline-text' : ''}`}> Accès gratuit</span>
                        </div>
                        <div className="d-flex align-items-center me-4">
                            <input dir="ltr" className="input-style-radio" onClick={(e) => check(e)} readOnly type="radio" name="Pay" id="Pay" value="Pay" checked={typeTarification === 'Pay'} />
                            <label htmlFor="Pay"></label>
                            <span onClick={() => check({ target: { value: 'Pay' } })} style={{ cursor: 'pointer' }} className={`body-md black-800 ps-2  ${typeTarification === 'Pay' ? 'underline-text' : ''}`}> Accès payant</span>
                        </div>
                        <div className="d-flex align-items-center">
                            <input dir="ltr" className="input-style-radio" onClick={(e) => check(e)} readOnly type="radio" name="Abn" id="Abn" value="Abn" checked={typeTarification === 'Abn'} />
                            <label htmlFor="Abn"></label>

                            <span style={{ cursor: 'pointer' }} onClick={() => check({ target: { value: 'Abn' } })} className={`body-md black-800 ps-2  ${typeTarification === 'Abn' ? 'underline-text' : ''}`}> Accès abonnement</span>
                        </div>
                        {!paymentSubscription &&
                            <span style={{ cursor: "pointer", marginLeft: "6px" }} onClick={upgrade}>
                                <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_20661_107782)">
                                        <rect y="0.5" width="17" height="17" rx="8.5" fill="#F5B95F" />
                                        <path d="M11.3332 13.2502V14.6668H5.6665V13.2502H11.3332ZM7.7915 6.15975V11.8335H9.20817V6.15975H11.3332L8.49984 3.3335L5.6665 6.15975H7.7915Z" fill="white" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_20661_107782">
                                            <rect y="0.5" width="17" height="17" rx="8.5" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>

                            </span>
                        }
                    </div>

                    <hr style={{ backgroundColor: "#DADBDF", margin: "20px 26px 20px 26px", height: "2px" }} />
                    {typeTarification === "Free" &&
                        <div style={{ marginLeft: "30px" }}>
                            <span className="body-sm black-800">Offrez un plan gratuit à vos apprenants</span>
                        </div>}

                    {typeTarification === "Pay" &&
                        <>
                            {(props?.paymentSchoolInfo === null || props?.paymentSchoolInfo?.isActivated === false) && <>
                                <div className='d-flex flex-row  d-flex align-items-center notif-warning-card mx-auto' style={{ height: "42px", width: "93%", marginBottom: "20px" }}>
                                    <svg width="25" height="29" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.333008 25.9998H29.6663L14.9997 0.666504L0.333008 25.9998ZM16.333 21.9998H13.6663V19.3332H16.333V21.9998ZM16.333 16.6665H13.6663V11.3332H16.333V16.6665Z" fill="#F5B95F" />
                                    </svg>
                                    <div className='body-sm ' style={{ marginLeft: "17px", marginTop: "4px" }}>
                                        Connectez-vous à votre moteur de paiement pour pouvoir accepter les paiements.<span className="notif-link" onClick={paymentMethod} >   Gérer les paiements</span>
                                    </div>
                                </div>
                            </>}

                            <div style={{
                                marginLeft: "25px", marginRight: "25px", pointerEvents: (props?.paymentSchoolInfo === null || props?.paymentSchoolInfo?.isActivated === false) ? 'none' : 'auto',
                                opacity: (props?.paymentSchoolInfo === null || props?.paymentSchoolInfo?.isActivated === false) ? '0.3' : '1'
                            }}>
                                <div className="d-flex align-items-top flex-row" style={{ marginLeft: "30px" }}>
                                    <div className="d-flex align-items-top flex-column" style={{ maxWidth: '225px', minWidth: '225px' }}>
                                        <label className="form-edit" style={{ marginBottom: "8px" }}>Accessible</label>
                                        <SelectElement disabled={props?.courseForm?.status == "PUBLISHED"}
                                            taille={'lg'} defaultValue={defaultValue} options={listAcess} onChange={(e: any) => { setDefaultValue(e); addRulesAccess(e, payRules, "Pay"); }} placeholder={"Sélectionner"} />
                                    </div>
                                    {defaultValue === "limited" &&

                                        <div className="d-flex align-items-top flex-column" style={{ marginLeft: "30px" }}>
                                            <label className="form-edit" style={{ marginBottom: "8px" }}>Pendant</label>
                                            <div className="input-form-control">
                                                <input type="number" className={monthRulesClass} placeholder=""
                                                    value={payRules}
                                                    onChange={(e) => { setPayRules(e.target.value); debounceAccessPeriod("limited", e.target.value, "Pay"); }}
                                                    style={{
                                                        WebkitAppearance: "none",
                                                        MozAppearance: "textfield",
                                                        appearance: "none",
                                                        margin: 0,
                                                        paddingRight: "16px"
                                                    }}
                                                    disabled={props?.courseForm?.status === "PUBLISHED"}
                                                />


                                                <span style={{ fontWeight: 500 }}>
                                                    Mois
                                                </span>
                                            </div>



                                        </div>}
                                </div>
                                <hr style={{ backgroundColor: "#DADBDF", margin: "20px 0px 20px 0px", height: "2px" }} />

                                <div style={{
                                    marginTop: '24px'
                                }}>
                                    <div className="d-flex align-items-top flex-row">
                                        <input className="input-style-checkbox" type="checkbox" id="checkBtn" checked={unique} onClick={() => checkPaymentUnique(!unique)} disabled={props?.courseForm?.status === "PUBLISHED"}
                                        />
                                        <label style={{ marginTop: "3px" }} htmlFor="checkBtn" />
                                        <div className="d-flex flex-column">
                                            <span className="ps-2 body-md bold">Paiement unique</span>
                                            <span className="ps-2 body-sm ">Proposez aux apprenants des frais uniques pour accéder au contenu. </span>
                                        </div>
                                        {paymentUnique ?
                                            <span className="material-icons-outlined ms-auto pointer " onClick={() => setPaymentUnique(!paymentUnique)}>expand_less</span>
                                            :
                                            <span className="material-icons-outlined ms-auto pointer " onClick={() => { setPaymentUnique(!paymentUnique); setUnique(true); }}>expand_more</span>}
                                    </div>
                                    {paymentUnique &&
                                        <div className="d-flex align-items-top flex-row" style={{ marginTop: "20px", marginLeft: "30px" }}>
                                            <div className="d-flex align-items-top flex-column" style={{ marginRight: "30px" }}>
                                                <div className="d-flex align-items-top flex-column" style={{ marginRight: "30px" }}>

                                                    <label className="form-edit" style={{ marginBottom: "8px" }}>Frais d’inscription</label>
                                                    <div className="input-form-control">
                                                        <input type="number" className={priceUniqueClass} placeholder=""
                                                            value={priceUnique}
                                                            onChange={(e: any) => {
                                                                setPriceUnique(e.target.value);
                                                                debouncePriceUnique(e.target.value, unique ? true : false, easy ? true : false);
                                                            }}
                                                            disabled={props?.courseForm?.status === "PUBLISHED"}

                                                            style={{
                                                                WebkitAppearance: "none",
                                                                MozAppearance: "textfield",
                                                                appearance: "none",
                                                                margin: 0,
                                                                paddingRight: "16px"
                                                            }} />
                                                        <span style={{ fontWeight: 500 }}>
                                                            TTC
                                                        </span>
                                                    </div>


                                                </div>

                                                <span className="error-input2">
                                                    {priceUniqueEror}
                                                </span>
                                            </div>
                                        </div>}
                                </div>
                                <hr style={{ backgroundColor: "#DADBDF", margin: "20px 0px 20px 0px", height: "2px" }} />

                                <div style={{
                                    marginTop: '24px'
                                }}>
                                    <div className="d-flex align-items-top flex-row">
                                        <input className="input-style-checkbox" type="checkbox" id="checkBtn2" checked={easy} onClick={() => checkPaymenFacility(!easy)} />
                                        <label
                                            style={{
                                                marginTop: "3px", pointerEvents: (paymentPeriodic === false) ? 'none' : 'auto',
                                                opacity: (paymentPeriodic === false) ? '0.3' : '1'
                                            }}

                                            htmlFor="checkBtn2" />
                                        <div className="d-flex flex-column">
                                            <div className="d-flex flex-row">
                                                <span className="ps-2 body-md bold" style={{
                                                    pointerEvents: (paymentPeriodic === false) ? 'none' : 'auto',
                                                    opacity: (paymentPeriodic === false) ? '0.3' : '1'
                                                }}>Paiement par facilité</span>


                                                {!paymentPeriodic &&
                                                    <span style={{
                                                        cursor: "pointer", marginLeft: "6px",
                                                        pointerEvents: 'auto',
                                                        opacity: '1'

                                                    }} onClick={upgrade}>
                                                        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0_20661_107782)">
                                                                <rect y="0.5" width="17" height="17" rx="8.5" fill="#F5B95F" />
                                                                <path d="M11.3332 13.2502V14.6668H5.6665V13.2502H11.3332ZM7.7915 6.15975V11.8335H9.20817V6.15975H11.3332L8.49984 3.3335L5.6665 6.15975H7.7915Z" fill="white" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_20661_107782">
                                                                    <rect y="0.5" width="17" height="17" rx="8.5" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>

                                                    </span>
                                                }
                                            </div>
                                            <span className="ps-2 body-sm " style={{
                                                pointerEvents: (paymentPeriodic === false) ? 'none' : 'auto',
                                                opacity: (paymentPeriodic === false) ? '0.3' : '1'
                                            }}>Donnez la possibilité à vos apprenants de payer par tranche selon l'échéance <br />de votre choix. </span>
                                        </div>
                                        {paymentEasy ?
                                            <span className="material-icons-outlined ms-auto pointer " onClick={() => setPaymentEasy(!paymentEasy)} style={{
                                                pointerEvents: (paymentPeriodic === false) ? 'none' : 'auto',
                                                opacity: (paymentPeriodic === false) ? '0.3' : '1'
                                            }}>expand_less</span>
                                            :
                                            <span className="material-icons-outlined ms-auto pointer " onClick={() => { setEasy(true); setPaymentEasy(!paymentEasy); }} style={{
                                                pointerEvents: (paymentPeriodic === false) ? 'none' : 'auto',
                                                opacity: (paymentPeriodic === false) ? '0.3' : '1'
                                            }}>expand_more</span>}
                                    </div>
                                    {paymentEasy &&
                                        <>
                                            {items.map((ligne, index) => (
                                                <div key={index}>
                                                    {index === items.length - 1 ?
                                                        <TarificationPeriod ajouterLigne={ajouterArray} supprimerLigne={supprimerArray} showAddButton={items.length < 3} showDeleteButton={items.length != 1} addPrice={addPricingFacility} data={ligne} uniqueChecked={unique} disable={props?.courseForm?.status === "PUBLISHED"} />
                                                        :
                                                        <TarificationPeriod ajouterLigne={ajouterArray} supprimerLigne={supprimerArray} showAddButton={false} showDeleteButton={false} addPrice={addPricingFacility} data={ligne} uniqueChecked={unique} disable={props?.courseForm?.status === "PUBLISHED"} />}
                                                </div>
                                            ))}
                                        </>}
                                </div>
                            </div></>}


                    {typeTarification === "Abn" &&
                        <div style={{ marginLeft: "25px", marginRight: "25px" }}>

                            {(props?.paymentSchoolInfo === null || props?.paymentSchoolInfo?.isActivated === false) && <>
                                <div className='d-flex flex-row  d-flex align-items-center notif-warning-card' style={{ height: "42px" }}>
                                    <svg width="25" height="29" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.333008 25.9998H29.6663L14.9997 0.666504L0.333008 25.9998ZM16.333 21.9998H13.6663V19.3332H16.333V21.9998ZM16.333 16.6665H13.6663V11.3332H16.333V16.6665Z" fill="#F5B95F" />
                                    </svg>
                                    <div className='body-sm ' style={{ marginLeft: "17px", marginTop: "4px" }}>
                                        Connectez-vous à votre moteur de paiement pour pouvoir accepter les paiements.<span className="notif-link" onClick={paymentMethod}>   Gérer les paiements</span>
                                    </div>
                                </div>
                            </>}
                            <div style={{
                                marginTop: '24px', pointerEvents: (props?.paymentSchoolInfo === null || props?.paymentSchoolInfo?.isActivated === false) ? 'none' : 'auto'
                                , opacity: (props?.paymentSchoolInfo === null || props?.paymentSchoolInfo?.isActivated === false) ? '0.3' : '1'
                            }}>
                                <div className="d-flex align-items-top flex-row">

                                    <div className="d-flex flex-column">
                                        <span className="ps-2 body-sm ">Proposez aux apprenants des frais mensuels récurrents pour accéder au <br /> contenu du cours. </span>
                                    </div>
                                </div>

                                <div className="d-flex align-items-top flex-row ps-2" style={{ marginTop: "20px" }}>
                                    <div className="d-flex align-items-top flex-column" style={{ marginRight: "30px" }}>

                                        <label className="form-edit" style={{ marginBottom: "8px" }}>Frais d’abonnement</label>
                                        <div className="input-form-control">
                                            <input type="tel" className={priceAbnClass} placeholder=""
                                                style={{
                                                    WebkitAppearance: "none",
                                                    MozAppearance: "textfield",
                                                    appearance: "none",
                                                    margin: 0
                                                }}
                                                disabled={props?.courseForm?.status === "PUBLISHED"}
                                                value={priceAbn}
                                                onChange={(e: any) => {
                                                    setPriceAbn(e.target.value);
                                                    debouncePriceAbn(defaultValueAbn, e.target.value,)
                                                }}
                                            />
                                            <span style={{ fontWeight: 500 }} >
                                                TTC
                                            </span>
                                        </div>

                                        <span className="error-input2">
                                            {priceAbnEror}
                                        </span>

                                    </div>
                                    <div className="d-flex align-items-top flex-column" style={{ maxWidth: '225px', minWidth: '225px' }}>
                                        <label className="form-edit" style={{ marginBottom: "8px" }}>Par</label>
                                        <SelectElement disabled={props?.courseForm?.status === "PUBLISHED"}
                                            taille={'lg'} defaultValue={defaultValueAbn} options={listAcessAbn} onChange={(e: any) => { setDefaultValueAbn(e); addPricingAbn(e, priceAbn,) }} placeholder={"Sélectionner"} />
                                    </div>





                                </div>
                            </div>

                        </div>}

                </div>


                <div style={{ width: 276, backgroundColor: '#FFFFFF', height: " min-content", paddingBottom: "23px" }} className="d-flex flex-column">

                    <div className="d-flex flex-column " style={{ marginLeft: "24px", marginTop: "24px" }}>
                        <span className="body-xl bold" style={{ marginBottom: "12px" }}>Tarification de ce cours</span>


                        {typeTarification === "Free" &&

                            <><span className="body-md bold" style={{ marginBottom: "12px" }}>Accès gratuit</span>
                                <span className="body-md bold" style={{ marginBottom: "12px" }}>Détails</span>
                                <span className="body-md bold" style={{ marginBottom: "12px" }}>-</span></>
                        }
                        {typeTarification === "Pay" &&

                            unique === true &&
                            <><span className="body-md bold" style={{ marginBottom: "12px" }}>Paiement unique</span>
                                <span className="body-md bold" style={{ marginBottom: "12px" }}>Détails</span>
                                <span className="body-md  neutral-2 bold">Accessible : {defaultValue === "limited" ?
                                    <span className="body-md neutral-2">{payRules} mois</span> : <span className="body-md neutral-2"> Illimité</span>}</span>
                                <span className="body-md  neutral-2 bold">Frais d’inscription : <span className="body-md neutral-2">{priceUnique}  &euro;</span> </span>

                            </>


                        }
                        {typeTarification === "Pay" &&

                            (unique === true && easy === true) &&
                            <><span className="body-md bold" style={{ marginBottom: "12px", marginTop: "12px" }}>Et </span></>
                        }

                        {typeTarification === "Pay" &&

                            easy === true &&
                            <><span className="body-md bold" style={{ marginBottom: "12px" }}>Paiement par facilité</span>
                                <span className="body-md bold" style={{ marginBottom: "12px" }}>Détails</span>
                                <span className="body-md  neutral-2 bold">Accessible : {defaultValue === "limited" ?
                                    <span className="body-md neutral-2">{payRules} mois</span> : <span className="body-md neutral-2"> Illimité</span>}</span>
                                <span className="body-md  neutral-2 bold">Modalité : <br />


                                    {items.map((ligne, index) => (
                                        <><span className="body-md neutral-2">&nbsp;&nbsp;· &nbsp; {ligne.mp}  &euro; x {ligne.due} mois </span><br /></>
                                    ))}



                                </span></>


                        }

                        {typeTarification === "Abn" &&


                            <><span className="body-md bold" style={{ marginBottom: "12px" }}>Accès abonnement</span>
                                <span className="body-md bold" style={{ marginBottom: "12px" }}>Détails</span>
                                <span className="body-md  neutral-2 bold">Accessible : <span className="body-md neutral-2"> L’apprenant aura accès au cours aussi longtemps qu’il paiera son abonnement.</span></span>

                                {priceAbn &&
                                    <span className="body-md  neutral-2 bold">Frais d’abonnement  : <span className="body-md neutral-2">{priceAbn}  &euro; {defaultValueAbn === "MONTH" && "/ Mois"} {defaultValueAbn === "WEEK" && "/ Semaine"} {defaultValueAbn === "QUARTER" && "/ Trimestre"} {defaultValueAbn === "SEMESTER" && "/ Semestre"}</span> </span>

                                }
                            </>
                        }
                        {props?.courseForm?.status === "PUBLISHED" &&
                            <button style={{ marginTop: "25px" }} className="btn-Primary large" onClick={redirectTo}>MoDIFIER LA TARIFICATION</button>

                        }
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    createOrUpdateOfferCourse,
    clearRegistrationForm,
    getOfferCourse,
    getOfferPricingCourse,
    clearHistoryArray,
    redirect,

}, dispatch);
const mapStateToProps = (state: AppState) => ({
    paymentSchoolInfo: state.schoolInfoReducer.paymentSchoolInfo,
    courseForm: state.courseReducer.courseInfo,
    courseTarificationForm: state.courseReducer.courseTarificationForm,
    limitations: state.schoolInfoReducer.subscription.limitations,
    paymentMethod: state.schoolInfoReducer.paymentSchoolInfo,
    actualOffer: state.courseReducer.actualOffer,

});
export default connect(mapStateToProps, mapDispatchToProps)(TarificationTrainingPage);

