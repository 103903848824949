import { FC, useEffect, useState, useCallback, ComponentProps, useMemo, useRef } from 'react';
import "../../../../translations/i18n";
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../../global-state/store/root-reducers';
import { setStudentProgress, checkProgressCourse } from '../../../../global-state/actions/student-training-actions';
import ReactPlayer from 'react-player';
import { useParams } from 'react-router';
import { getDataFromURL } from '../../../../base/functions/Functions';
import { debounce } from 'lodash';
interface RouteParams {
    idCourse: any
}
const SectionCourseComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {

    const { idCourse } = useParams<RouteParams>();
    let listOfFiles = useMemo(() => { return props?.sectionContent?.files }, [props?.sectionContent?.files])
    let courseVideo = useMemo(() => { return props?.sectionContent?.video }, [props?.sectionContent?.video])
    let course = useMemo(() => { return props?.currentCourse }, [props?.currentCourse])
    const [completed, setCompleted] = useState<boolean>(false)
    const [visibles, setVisibles] = useState<any[]>([])
    const [courseProgress, setCourseProgress] = useState<any>(null);
    const reactPlayer: any = undefined;
    const [courseFollow, setcourseFollow] = useState<any>(undefined)
    const [studentSectionProgression, setStudentSectionProgression] = useState<any>(undefined)

    const [pipMode, setPipMode] = useState(false);
    const refVideo = useRef<any>();

    const getScroll = () => {
        const element: any = document?.getElementById('video-chapter');
        const scroll = Math.abs(refVideo.current.getBoundingClientRect().top - refVideo.current.offsetTop);
        if (scroll !== 0) {
            return (element && element.scrollHeight) ? (scroll >= (element.scrollHeight + 378)) : false;
        }
        else return false;

    }

    useEffect(() => {
        document.addEventListener('scroll', handleScroll, true);
        return () => {
            document.removeEventListener('scroll', handleScroll, true);
        }
    }, [])

    const handleScroll = async () => {
        if (getScroll() === true && pipMode === false) {
            setPipMode(true);
        }
        else {
            setPipMode(false);
        }
    }


    useEffect(() => {
        if (props?.studentSection?.id !== studentSectionProgression?.id) {
            setStudentSectionProgression(props?.studentSection)
        }
    }, [props?.studentSection])


    useEffect(() => {
        if (studentSectionProgression?.progression?.progress === "COMPLETED") {
            setCompleted(true)
        } else {
            setCompleted(false)
        }
    }, [studentSectionProgression])


    useEffect(() => {
        if (props?.currentTrainingProgress && props?.currentTrainingProgress !== null) {
            setCourseProgress(props?.currentTrainingProgress)
        } else {
            setCourseProgress(null)
        }
    }, [props?.currentTrainingProgress])

    const showDocs = (position: number) => {

        let docs = [...visibles]
        docs[position] = !visibles[position]
        setVisibles(docs)
    }

    useEffect(() => {
        if (course?.followUp !== courseFollow) {
            setcourseFollow(course?.followUp)
        }
    }, [course?.followUp])

    useEffect(() => {
        checkProgressCourseFunction()
    }, [courseFollow])


    const checkProgressCourseFunction = async () => {
        if (course?.followUp && course?.followUp?.id) {
            await props?.checkProgressCourse(course?.followUp, course?.id, props?.connectedUser?.idPerson, true, course);
        }
    }
    const chapterProgression = async (progressionState: any, duration: number, progress: string) => {
        let prog = {
            id: (progressionState?.progress !== null) ? progressionState?.progress?.id : null,
            chapterId: progressionState?.id,
            follow: { id: course?.followUp?.id },
            progress: progress,
            duration: duration,
            progressTrainingId: (course?.followUp?.progressTraining === null) ? null : course?.followUp?.progressTraining?.id,
            entityId: course?.id,
        }
        if ((progressionState?.progress === null || progressionState?.progress?.progress !== "COMPLETED") && course != null && course != undefined) {
            await props?.setStudentProgress(prog, course, courseProgress, props?.listFollowUps);
        }
    }
    const debouceChangeProgression = useCallback(debounce(chapterProgression, 1000), []);

    const changeChapterStatus = async (player: number) => {
        if (props?.sectionContent?.progress?.progress !== "COMPLETED" && player >= 0.8) {
            await setCompleted(true)
            await debouceChangeProgression(studentSectionProgression, 100, "COMPLETED")
        } else {
            setCompleted(false)
        }

    }

    return (
        <div style={{ backgroundColor: "#FFFFFF" }} ref={refVideo}>
            {(courseVideo !== "" && courseVideo !== " " && courseVideo?.length !== 0 && courseVideo !== null && courseVideo !== undefined) && (
                <div id="video-chapter" className="bg-full-black" style={props.isMobile && { width: "100%", height: "254px" }}>
                    {pipMode &&
                        <div style={{ width: "100%", height: "502px" }}>

                        </div>
                    }
                    <div className={props?.isMobile ? "position-relative" : pipMode ? '' : "player-wrapper"} style={props.isMobile ? { width: "100%", height: "254px" } : pipMode ? {
                        zIndex: 999,
                        width: courseVideo.includes("vimeo") ? "444px" : "444px",
                        height: courseVideo.includes("vimeo") ? "320px" : "320px",
                        position: "fixed",
                        right: 32,
                        bottom: courseVideo.includes("vimeo") ? 0 : 32,
                        transition: "all 0.5s ease-in-out",
                    } : {
                        transition: "all 0.5s ease-in-out",
                    }}>
                        <ReactPlayer
                            onSeek={() => reactPlayer.seekTo((studentSectionProgression?.progress?.duration / 100), 'fraction')}
                            ref={reactPlayer}
                            pip={true}
                            config={{
                                youtube: {
                                    embedOptions: {
                                        // height: props.isMobile ? '254px' : pipMode ? '320px' : '502px' ,
                                        // height: props.isMobile ? '254px' : '502px' ,
                                    },
                                },
                                vimeo: {
                                    playerOptions: { showPortrait: true, pip: true, offscreenBuffering: true, played: studentSectionProgression?.progression?.duration }
                                }
                            }}
                            onPlay={() => {
                                if (courseVideo.includes("vimeo")) {
                                    reactPlayer.vimeo.loaded = studentSectionProgression?.progress?.duration / 100
                                }
                            }}
                            onProgress={({ played }) => { changeChapterStatus(played) }}
                            controls={true}
                            stopOnUnmount={false}
                            width='100%'
                            height={props.isMobile ? '254px' : pipMode ? '320px' : '502px'}
                            className={props.isMobile ? "" : pipMode ? "" : "react-player"}
                            url={courseVideo}
                            disableDeferredLoading={true}

                        />
                    </div>
                </div>
            )}
            <div style={{ paddingTop: "24px", paddingLeft: "24px", paddingRight: "24px" }} id="course-content">
                <div className="" style={!props.isMobile ? { padding: "32px 149px", backgroundColor: "#FBFBFD", border: "1px solid #F2F2F5", borderRadius: 3, display: "flex", flexDirection: "column" } : {}}>
                    <div style={{ paddingTop: "24px" }} dangerouslySetInnerHTML={{ __html: props?.sectionContent?.text }}></div>
                </div>
            </div>

            <div className="" style={!props.isMobile ? { padding: "24px" } : {}}>
                {listOfFiles?.map((obj: any, i: number) => {
                    return (
                        (obj.name.indexOf(".pdf") > -1) ? (
                            <div key={i}>
                                <div className="file-upload-student d-flex align-items-center justify-content-between px-3">
                                    <div className="d-flex align-items-center pe-3">
                                        <svg style={{ minWidth: '38px' }} height="48" viewBox="0 0 38 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.40989 33V24.324H11.6019C12.2339 24.324 12.7999 24.434 13.2999 24.654C13.7999 24.87 14.1939 25.19 14.4819 25.614C14.7739 26.038 14.9199 26.556 14.9199 27.168C14.9199 28.032 14.6379 28.704 14.0739 29.184C13.5139 29.664 12.7659 29.904 11.8299 29.904H10.1739V33H8.40989ZM10.1559 28.548H11.7819C12.2179 28.548 12.5759 28.408 12.8559 28.128C13.1399 27.844 13.2819 27.494 13.2819 27.078C13.2819 26.646 13.1339 26.304 12.8379 26.052C12.5419 25.796 12.1819 25.668 11.7579 25.668H10.1559V28.548ZM16.388 24.324C16.432 24.324 16.554 24.324 16.754 24.324C16.958 24.324 17.196 24.324 17.468 24.324C17.744 24.324 18.018 24.326 18.29 24.33C18.566 24.33 18.796 24.332 18.98 24.336C20.032 24.36 20.888 24.548 21.548 24.9C22.212 25.252 22.7 25.744 23.012 26.376C23.324 27.008 23.48 27.756 23.48 28.62C23.48 29.52 23.316 30.298 22.988 30.954C22.66 31.606 22.158 32.11 21.482 32.466C20.81 32.822 19.956 33 18.92 33H16.388V24.324ZM18.152 25.74V31.584H18.956C19.452 31.584 19.902 31.488 20.306 31.296C20.714 31.104 21.04 30.79 21.284 30.354C21.528 29.918 21.65 29.336 21.65 28.608C21.65 27.596 21.41 26.866 20.93 26.418C20.454 25.966 19.804 25.74 18.98 25.74H18.152ZM25.1747 24.324H31.0787L31.0727 25.788H26.9387V27.966H30.5207V29.4H26.9387V33H25.1747V24.324Z" fill="#E06A6A" />
                                            <path d="M23.75 0H4.75C2.1375 0 0.0237505 2.16 0.0237505 4.8L0 43.2C0 45.84 2.11375 48 4.72625 48H33.25C35.8625 48 38 45.84 38 43.2V14.4L23.75 0ZM4.75 43.2V4.8H21.375V16.8H33.25V43.2H4.75Z" fill="#F2F2F5" />
                                        </svg>
                                        <p className="body-md bold ps-3 pt-3">{obj?.name}</p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <button type="button" className="btn-QuickActions me-3" onClick={() => { showDocs(i) }} >
                                            <span className="material-icons-outlined">remove_red_eye</span>
                                        </button>
                                        <button type="button" className="btn-QuickActions" onClick={() => { getDataFromURL(obj.link, obj.name) }} >
                                            <span className="material-icons-outlined"  >download</span>
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    {visibles[i] &&
                                        <div style={{ height: 900 }}>
                                            <iframe src={`${obj.link}`} frameBorder="0" scrolling="auto" height="100%" width="100%" ></iframe>
                                        </div>
                                    }
                                </div>
                            </div>
                        ) : (
                            (obj.name.indexOf(".pptx") > -1
                                || obj.name.indexOf(".ppt") > -1
                                || obj.name.indexOf(".docx") > -1
                                || obj.name.indexOf(".csv") > -1
                                || obj.name.indexOf(".dotx") > -1
                                || obj.name.indexOf(".xlsx") > -1
                                || obj.name.indexOf(".xls") > -1

                                || obj.name.indexOf(".doc") > -1
                                || obj.name.indexOf(".docm") > -1
                                || obj.name.indexOf(".dotm") > -1
                                || obj.name.indexOf(".dot") > -1
                                || obj.name.indexOf(".pot") > -1

                                || obj.name.indexOf(".potm") > -1
                                || obj.name.indexOf(".ppa") > -1
                                || obj.name.indexOf(".potx") > -1
                                || obj.name.indexOf(".pps") > -1
                                || obj.name.indexOf(".ods") > -1

                            ) ? (
                                <div key={i}>
                                    <div className="file-upload-student d-flex align-items-center justify-content-between px-3">
                                        <div className="d-flex align-items-center pe-3">
                                            <svg style={{ minWidth: '38px' }} height="48" viewBox="0 0 38 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.29661 33V24.324H11.4886C12.1206 24.324 12.6866 24.434 13.1866 24.654C13.6866 24.87 14.0806 25.19 14.3686 25.614C14.6606 26.038 14.8066 26.556 14.8066 27.168C14.8066 28.032 14.5246 28.704 13.9606 29.184C13.4006 29.664 12.6526 29.904 11.7166 29.904H10.0606V33H8.29661ZM10.0426 28.548H11.6686C12.1046 28.548 12.4626 28.408 12.7426 28.128C13.0266 27.844 13.1686 27.494 13.1686 27.078C13.1686 26.646 13.0206 26.304 12.7246 26.052C12.4286 25.796 12.0686 25.668 11.6446 25.668H10.0426V28.548ZM16.2747 33V24.324H19.4667C20.0987 24.324 20.6647 24.434 21.1647 24.654C21.6647 24.87 22.0587 25.19 22.3467 25.614C22.6387 26.038 22.7847 26.556 22.7847 27.168C22.7847 28.032 22.5027 28.704 21.9387 29.184C21.3787 29.664 20.6307 29.904 19.6947 29.904H18.0387V33H16.2747ZM18.0207 28.548H19.6467C20.0827 28.548 20.4407 28.408 20.7207 28.128C21.0047 27.844 21.1467 27.494 21.1467 27.078C21.1467 26.646 20.9987 26.304 20.7027 26.052C20.4067 25.796 20.0467 25.668 19.6227 25.668H18.0207V28.548ZM23.5089 25.884V24.324H30.4509V25.884H27.8889V33H26.1309V25.884H23.5089Z" fill="#F5B95F" />
                                                <path d="M23.75 0H4.75C2.1375 0 0.0237505 2.16 0.0237505 4.8L0 43.2C0 45.84 2.11375 48 4.72625 48H33.25C35.8625 48 38 45.84 38 43.2V14.4L23.75 0ZM4.75 43.2V4.8H21.375V16.8H33.25V43.2H4.75Z" fill="#F2F2F5" />
                                            </svg>
                                            <p className="body-md bold ps-3 pt-3">{obj?.name}</p>
                                        </div>
                                        <div className="d-flex align-items-center">

                                            <button type="button" className="btn-QuickActions" onClick={() => { getDataFromURL(obj.link, obj.name) }} >
                                                <span className="material-icons-outlined"  >download</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        {visibles[i] &&
                                            <div style={{ height: 900 }}>
                                                <iframe
                                                    src={`${obj.link}`}
                                                    frameBorder="0"
                                                    scrolling="auto"
                                                    height="100%"
                                                    width="100%"
                                                ></iframe>
                                            </div>
                                        }
                                    </div>
                                </div>
                            ) : (
                                <div key={i}>
                                    <div className="file-upload-student d-flex align-items-center justify-content-between px-3">
                                        <div className="d-flex align-items-center pe-3">
                                            <svg style={{ minWidth: '38px' }} height="48" viewBox="0 0 38 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.27136 25.884V24.324H14.2134V25.884H11.6514V33H9.89336V25.884H7.27136ZM23.0025 33H20.8185L18.8625 29.868L16.9425 33H15.0585L17.9445 28.596L15.1485 24.324H17.3145L19.0485 27.132L20.7645 24.324H22.6245L19.9485 28.368L23.0025 33ZM23.7901 25.884V24.324H30.7321V25.884H28.1701V33H26.4121V25.884H23.7901Z" fill="#5693EE" />
                                                <path d="M23.75 0H4.75C2.1375 0 0.0237505 2.16 0.0237505 4.8L0 43.2C0 45.84 2.11375 48 4.72625 48H33.25C35.8625 48 38 45.84 38 43.2V14.4L23.75 0ZM4.75 43.2V4.8H21.375V16.8H33.25V43.2H4.75Z" fill="#F2F2F5" />
                                            </svg>
                                            <p className="body-md bold ps-3 pt-3">{obj?.name}</p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <button type="button" className="btn-QuickActions me-3" onClick={() => { showDocs(i) }} >
                                                <span className="material-icons-outlined">remove_red_eye</span>
                                            </button>
                                            <button type="button" className="btn-QuickActions" onClick={() => { getDataFromURL(obj.link, obj.name) }} >
                                                <span className="material-icons-outlined"  >download</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        {visibles[i] &&
                                            <div style={{ height: 900 }}>
                                                <iframe
                                                    src={`${obj.link}`}
                                                    frameBorder="0"
                                                    scrolling="auto"
                                                    height="100%"
                                                    width="100%"
                                                ></iframe>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        )
                    )
                })}
            </div>
        </div>
    )
}


const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            setStudentProgress,
            checkProgressCourse
        },
        dispatch
    );
const mapStateToProps = (state: AppState) => ({
    sectionContent: state.studentTraining.sectionContentDetails.sectionContent,
    index: state.studentTraining.sectionContentDetails.index,
    currentCourse: state.studentTraining.currentCourse,
    connectedUser: state.authReducer.connectedUser,
    isCompleted: state.studentTraining.isCompleted,
    currentTrainingProgress: state.studentTraining.currentTrainingProgress,
    listFollowUps: state.studentTraining.listFollowUps
});

export default connect(mapStateToProps, mapDispatchToProps)(SectionCourseComponent);
