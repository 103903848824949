import { ComponentProps, FC, useEffect, useState } from "react"
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { AppState } from "../../global-state/store/root-reducers";
import { clearAllData, sendIpAddressCourse, getOfferCourse, getCourse, checkFollowStudentCourse, updateShow } from "../../global-state/actions/course-actions";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import HeaderSectionComponent from "../../components/preview-course-components/header-section-component";
import VideoImageSectionComponent from "../../components/preview-course-components/video-image-section-component";
import MoreInfoSectionComponent from "../../components/preview-course-components/more-info-section-component";
import SkillsSectionComponent from "../../components/preview-course-components/skills-section-component";
import ContentPathSectionComponent from "../../components/preview-course-components/content-path-section-component";

import SchoolProfSectionComponent from "../../components/preview-course-components/school-prof-section-component";
import HowToWorkSectionComponent from "../../components/preview-course-components/how-to-work-section-component";
import { clearFeedbacks, getAllFeedbacks } from '../../global-state/actions/feedback-actions';
import ListFeedbackSectionComponent from "../../components/preview-course-components/list-feedback-section-component";

interface Params {
    id: string
}
const PreviewSessionAfterInscriptionPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const [show, setShow] = useState()
    const [courseInfo, setCourseInfo] = useState<any>();
    const location = useLocation();
    const [price, setPrice] = useState<any>()
    const [ips, setIps] = useState('')
    const { id } = useParams<Params>();
    const key: any = process.env.REACT_APP_ABSTRACT_API_KEY;
    useEffect(() => {

        if (props?.courseInfo === undefined || props?.courseInfo === null || (props?.courseInfo && props?.courseInfo?.id !== Number(id))) {
            props?.getOfferCourse(Number(id));
            props?.getCourse(Number(id));
        } else {
            setCourseInfo(props?.courseInfo);
        }
        if (location.pathname.includes("preview")) {
            fetch("https://ipgeolocation.abstractapi.com/v1/?api_key=" + key).then(response => response.json())
                .then(dataResponse => setIps(dataResponse.ip_address));

        }
        const data = async () => {
            await props?.clearFeedbacks();
            await props?.getAllFeedbacks(Number(id), 0, 'refresh');
        }
        data();
        return () => {
            props?.clearFeedbacks();
        };
    }, []);

    useEffect(() => {
        if (ips !== '') {
            props?.sendIpAddressCourse({ address: ips, idCourse: props?.courseInfo?.id })
        }
    }, [ips])


    useEffect(() => {
        if (props?.connectedUser?.role?.includes("role_student")) {
            props?.checkFollowStudentCourse(id, props?.connectedUser?.idPerson, props?.courseInfo?.type)

        }

    }, []);
    useEffect(() => {
        setShow(props?.show)

    }, [props?.show]);
    useEffect(() => {
        if (props?.courseInfo) {
            props?.courseInfo?.orders?.sort((a: any, b: any) => {
                if (a.index > b.index) {
                    return 1;
                } else if (a.index < b.index) {
                    return -1
                } else {
                    return 0
                }
            })
            setCourseInfo(props?.courseInfo);
        }
    }, [props?.courseInfo])
    useEffect(() => {

        setPrice(props?.priceCourse);
    }, [props?.priceCourse])

    const convertTime = (minutes: number) => {
        if (minutes < 60) {
            return minutes + ' MN';
        } else {

            return Math.floor(minutes / 60) + 'H' + (((minutes % 60) < 10 ? '0' : '') + (minutes % 60));
        }
    }
    const avisClicked = () => {
        document.getElementById('clickLink')?.click();
    }
    return (
        <div className="mt-5">
            <a href="#listFeedbacks" id="clickLink" style={{ display: 'none' }}></a>
            <HeaderSectionComponent avisClicked={() => avisClicked()} disabled={false} courseForm={courseInfo} price={price} time={convertTime(courseInfo?.duration)} owner={courseInfo?.professor.firstName + " " + courseInfo?.professor.lastName} show={show} />
            <VideoImageSectionComponent video={courseInfo?.video} image={courseInfo?.linkImg} />
            <MoreInfoSectionComponent objectives={courseInfo?.objectives} courseInfo={courseInfo} prerequisite={courseInfo?.prerequisite} />
            <SkillsSectionComponent skills={courseInfo?.skills} />
            <ContentPathSectionComponent orders={courseInfo?.subjectsBySession} time={convertTime(courseInfo?.duration)} title={courseInfo?.title} courseInfo={courseInfo} />
            <SchoolProfSectionComponent profData={courseInfo?.professor} photoProf={courseInfo?.professor.photo} />
            <HowToWorkSectionComponent courseForm={courseInfo} price={price} courseInfo={courseInfo} show={show} />
            <div id="listFeedbacks">
                <ListFeedbackSectionComponent />
            </div>
        </div>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    history: ownProps?.history,
    priceCourse: state.courseReducer.priceCourse,
    courseInfo: state.courseReducer.training,
    show: state.courseReducer.show
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    clearAllData,
    sendIpAddressCourse,
    getOfferCourse,
    getCourse,
    checkFollowStudentCourse,
    clearFeedbacks,
    getAllFeedbacks,
    updateShow
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PreviewSessionAfterInscriptionPage);