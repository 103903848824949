import { ComponentProps, FC, useEffect, useRef, useState } from "react"
import { connect } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { AppState } from "../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import SkillsSectionComponent from "../../components/preview-course-components/skills-section-component";
import ContentChaptersSectionComponent from "../../components/preview-course-components/content-chapters-section-component";
import SchoolProfSectionComponent from "../../components/preview-course-components/school-prof-section-component";
import HowToWorkSectionComponent from "../../components/preview-course-components/how-to-work-section-component";
import { getCourse, checkFollowStudentCourse, getOfferPricingCourse, createOrUpdateOfferCourse, clearTarification } from '../../global-state/actions/course-actions';
import ListFeedbackSectionComponent from "../../components/preview-course-components/list-feedback-section-component";
import DescPreviewCourse from "../../components/preview-course-components/desc-preview-course";
import axiosInstance from "../../helpers/interceptors";
import { createFollow, getRegistrationForm, getFollowByFilter, getApplicationFile } from '../../global-state/actions/course-actions';
import { refreshToken } from '../../global-state/actions/auth-actions';
import { saveReturnPath, redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions';
import { returnStartPathUrl } from "../../helpers/domainCheck";
import { useTranslation } from 'react-i18next';
import { getCurrentCourse, getCurrentTraining } from "../../global-state/actions/student-training-actions";
import { getLegalPerson } from '../../global-state/actions/school-actions';
import { ModalIDS } from "../../components/modals/modal-ids";
import { openModal } from "../../global-state/actions/modal-actions";
import { fetchData } from "../../base/functions/Functions";
import globalAxios from 'axios';
import SchoolProfSectionComponentLoading from "../../components/preview-course-components/school-prof-section-component-loading";
import CourseContentLoading from "../../components/preview-course-components/course-content-loading";
import SkillsSectionComponentLoading from "../../components/preview-course-components/skills-section-component-loading";
import HowToWorkSectionComponentLoading from "../../components/preview-course-components/how-to-work-section-component-loading";
import ListFeedbackSectionComponentLoading from "../../components/preview-course-components/list-feedback-section-component-loading";
import ButtonFollowLoading from "../../components/preview-course-components/button-follow-loading";
import TrainingHeaderComponentLoading from "../../components/preview-course-components/training-header-component-loading";
interface Params {
    id: string
}
const levels = [
    { label: 'anyLevelCourse', value: 'ANYLEVEL' },
    { label: 'firstLevel', value: 'BEGINNER' },
    { label: 'secondLevel', value: 'INTERMEDIATE' },
    { label: 'thirdLevel', value: 'ADVANCED' }
]
const PreviewCoursePage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {

    const [courseInfo, setCourseInfo] = useState<any>();
    const location = useLocation();
    const [price, setPrice] = useState<any>()
    const [ips, setIps] = useState('')
    const { id } = useParams<Params>();
    const [show, setShow] = useState(false)
    const [trainingLevel, setTrainingLevel] = useState<any>(undefined)
    const [trainingOffer, setTrainingOffer] = useState<any>(undefined)
    const [contentWidth, setContentWidth] = useState<any>()
    const [clicked, setClicked] = useState<boolean>(false);
    const [owner, setOwner] = useState<any>("")
    const [connectedUser, setConnectedUser] = useState<any>(undefined)
    const [enableOfferCreation, setenableOfferCreation] = useState<boolean>(true)
    const [courseTarificationForm, setCourseTarificationForm] = useState(undefined)
    const [idTraining, setIdTraining] = useState<number>(0)
    const { t } = useTranslation();
    const history = useHistory();
    const [disableFollowTrainingBtn, setDisableFollowTrainingBtn] = useState<boolean>(true)
    const [isConnected, setIsConnected] = useState<boolean>(false)
    const [logInFromModal, setLogInFromModal] = useState<boolean>(false)
    const [isMobile, setIsMobile] = useState<boolean>(true)
    const [feedBacks, setFeedBacks] = useState([])
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const key: any = process.env.REACT_APP_ABSTRACT_API_KEY;


    useEffect(() => {

        if (props?.from?.path.includes('sign-in')) {
            setIsConnected(true)
        } else {
            setIsConnected(false)
        }
    }, [props?.from])

    useEffect(() => {
        if (!!props?.connectedUser && props?.connectedUser?.role?.includes("role_student")) {
            setIsConnected(true)
        }
    }, [props?.connectedUser])




    useEffect(() => {
        if (windowSize != undefined && windowSize.current[0] < 600) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [windowSize])



    useEffect(() => {
        if (props?.courseTarificationForm !== undefined) {
            setCourseTarificationForm(props?.courseTarificationForm)
        }
    }, [props?.courseTarificationForm])

    useEffect(() => {
        if (props?.connectedUser !== undefined && props?.connectedUser != null) {
            setConnectedUser(props?.connectedUser)
        }
    }, [props?.connectedUser])

    useEffect(() => {
        if (connectedUser !== undefined && connectedUser?.role === "role_student" && courseInfo) {
            fetchData("GET", `${process.env.REACT_APP_BASE_URL3}follow/history/${id}/${connectedUser?.idPerson}/${courseInfo?.type}`, {}).then((response: any) => {
                response.data ? setShow(true) : setShow(false)
            }).catch(err => { setShow(true) })

            let object = {
                studentId: props?.connectedUser?.idPerson,

                courseId: courseInfo?.id,
                trainingType: "COURSE",
                entityType: "SCHOOL",
                entityId: props?.schoolInfo?.id
            }
            props?.getFollowByFilter(object)
        }
    }, [connectedUser])

    useEffect(() => {
        fetchCourse()
        if (location.pathname.includes("preview")) {
            fetch("https://ipgeolocation.abstractapi.com/v1/?api_key=" + key).then(response => response.json())
                .then(data => setIps(data.ip_address));
        }

        return () => {
            props?.clearTarification();
        };

    }, [])

    const fetchCourse = async () => {
        if (!props?.courseInfo) {
            const params = window.location.href.split("//")[1].split("/");

            await props?.getCourse(Number(params[params.length - 1]));
        }
    }

    useEffect(() => {
        if (ips !== '') {
            fetchData('POST', `${process.env.REACT_APP_BASE_URL2}visit/info`, { address: ips, idCourse: props?.courseInfo?.id })
        }
    }, [ips])

    useEffect(() => {
        if (!props?.courseInfo) return
        if (props?.courseInfo !== courseInfo) {
            props?.courseInfo?.orders?.sort((a: any, b: any) => {
                if (a.index > b.index) {
                    return 1;
                } else if (a.index < b.index) {
                    return -1
                } else {
                    return 0
                }
            })
            setCourseInfo(props?.courseInfo);
        }
        props?.clearHistoryArray()
        props?.redirect(t('Catalogue'), `${returnStartPathUrl()}/catalog`)
        props?.redirect(props?.courseInfo?.title, `${returnStartPathUrl()}/catalog/preview/course/${props?.courseInfo?.id}`)
    }, [props?.courseInfo])


    useEffect(() => {
        fetchFeedbacks();
        props?.clearHistoryArray()
        props?.redirect(t('Catalogue'), `${returnStartPathUrl()}/catalog`)
        props?.redirect(props?.courseInfo?.title, `${returnStartPathUrl()}/catalog/preview/course/${props?.courseInfo?.id}`)
    }, []);

    useEffect(() => {
        if (!props?.courseInfo) return
        const levelFouned = levels.find(obj => obj.value === props?.courseInfo?.level);
        setTrainingLevel((levelFouned) ? levelFouned.label : '...');
        if (
            props &&
            props.courseInfo &&
            props.courseInfo.prerequisite !== undefined &&
            props.courseInfo.prerequisite !== null &&
            props.courseInfo.skills !== undefined &&
            props.courseInfo.skills !== null &&
            props.courseInfo.skills.length > 0 &&
            props.courseInfo.objectives !== undefined &&
            props.courseInfo.objectives !== null
        ) {
            setContentWidth("100%");
        } else {
            setContentWidth("70%");
        }


    }, [courseInfo]);

    const convertTime = (minutes: number) => {
        if (minutes < 60) {
            return minutes + ' MN';
        } else {

            return Math.floor(minutes / 60) + 'H' + (((minutes % 60) < 10 ? '0' : '') + (minutes % 60));
        }
    }

    const avisClicked = () => {
        document.getElementById('clickLink')?.click();
    }

    const followTraining = async (trainingType: string) => {

        if (props?.connectedUser && props?.connectedUser?.role === "role_student") {
            if ((props?.courseInfo?.idRegistrationForm === 0 || props?.courseInfo?.idRegistrationForm === null || props?.courseInfo?.idRegistrationForm === undefined) && (props?.tarification?.pricings[0]?.price === 0)) {
                let contractToCreate = {
                    contractIdentifier: {
                        contractId: null,
                        contractReference: null
                    },
                    contractDetails: {
                        offerId: props?.tarification?.pricings[0]?.offer_id,
                        contractDuration: {
                            startAt: new Date(Date.now()),
                            endAt: null
                        },
                        pricingId: props?.tarification?.pricings[0]?.id,
                        paymentMethod: "CARD",
                        paymentEngine: "STRIPE"
                    },
                    contractRoles: [
                        {
                            personId: props?.connectedUser?.idPerson,
                            roleType: "STUDENT"
                        }
                    ],
                    status: [
                        {
                            statusType: "ONGOING"
                        }
                    ]
                }

                let follow = {
                    studentId: props?.connectedUser?.idPerson,
                    trainingId: props?.courseInfo.id,
                    trainingType: props?.courseInfo.type,
                    entityId: props?.courseInfo.entityId,
                    entityType: props?.courseInfo.entityType,
                    statusList: [{
                        status: "INSCRIBED"
                    }],
                }

                await props?.createFollow(follow, contractToCreate);
                await props?.refreshToken(props?.connectedUser?.idSchool, props?.connectedUser?.idPerson);
                setClicked(true);
            } else if ((courseInfo?.idRegistrationForm === 0 || courseInfo?.idRegistrationForm === null || courseInfo?.idRegistrationForm === undefined) && (price?.price !== 0)) {
                props?.clearHistoryArray()
                props?.redirect(courseInfo?.title, "")
                props?.redirect("Paiement", "")
                await props?.refreshToken(props?.connectedUser?.idSchool, props?.connectedUser?.idPerson).then(async (resp: any) => {
                    history.push(`${returnStartPathUrl()}/courses/payement/${trainingType}/${props?.courseInfo?.id}`)
                });


            }
            else if ((courseInfo?.idRegistrationForm !== 0 && courseInfo?.idRegistrationForm !== null && courseInfo?.idRegistrationForm !== undefined) ||
                ((courseInfo?.idRegistrationForm !== 0 && courseInfo?.idRegistrationForm !== null && courseInfo?.idRegistrationForm !== undefined) && (price.price !== 0))) {
                await props?.getApplicationFile(props?.connectedUser?.idPerson, courseInfo?.idRegistrationForm);
                await props?.getRegistrationForm(courseInfo?.idRegistrationForm);
                props?.redirect(t('Subscription'), `${returnStartPathUrl()}/catalog/inscription/${courseInfo?.type?.toLowerCase()}/${courseInfo?.idRegistrationForm}`)
                await props?.refreshToken(props?.connectedUser?.idSchool, props?.connectedUser?.idPerson).then(async (resp: any) => {
                    history.push(`${returnStartPathUrl()}/catalog/inscription/${props?.courseInfo?.type?.toLowerCase()}/${props?.courseInfo?.idRegistrationForm}`);
                });

            }

        }


    }

    useEffect(() => {
        if (!!props?.followByTraining && props?.followByTraining?.length > 0) {
            setDisableFollowTrainingBtn(true)
        } else {
            setDisableFollowTrainingBtn(false)
        }
    }, [props?.followByTraining])


    const followTrainingCheck = async () => {
        setDisableFollowTrainingBtn(true)
        if (!!props?.followByTraining && props?.followByTraining?.length > 0) {
            history.push(`${returnStartPathUrl()}${(props?.courseInfo?.type === 'COURSE') ? '/passCourse/course' : (props?.courseInfo?.type === 'PATH') ? '/passPath/progressPath' : '/passSession/progressSession'}/${props?.followByTraining[0]?.id}`);
        } else if (isMobile === false && isConnected === false) {
            await setLogInFromModal(true)
            await props?.openModal({ idModal: ModalIDS.SIGN_UP_MODAL, size: "sm", data: {} });
            await setDisableFollowTrainingBtn(false)

        } else if (isMobile === false && isConnected === true) {
            await props?.saveReturnPath({ path: `${returnStartPathUrl()}/catalog/preview/course/${props?.courseInfo?.id}`, tabPage: '' });
            await followTraining(props?.courseInfo.type)

        } else if (isMobile === true && isConnected === false) {
            await props?.saveReturnPath({ path: `${returnStartPathUrl()}/catalog/preview/course/${props?.courseInfo?.id}`, tabPage: '' });
            history.push(`${returnStartPathUrl()}/auth/sign-up`);

        } else if (isMobile === true && isConnected === true) {
            await followTraining(props?.courseInfo.type)
        }

    }

    useEffect(() => {
        gotoTrainingToStart()
    }, [props?.follow, clicked]);

    const gotoTrainingToStart = async () => {
        if (props?.follow && props?.follow[0] && props?.follow[0]?.id && clicked) {
            if (props.courseForm?.type !== 'COURSE') {
                await props.getCurrentTraining(props?.follow[0]?.id);

            } else {
                if (!!props?.follow[0]?.id) {
                    await props.getCurrentCourse(props?.follow[0]?.id);
                }
            }
            await history.push(`${returnStartPathUrl()}${(courseInfo?.type === 'COURSE') ? '/passCourse/course' : (courseInfo?.type === 'PATH') ? '/passPath/progressPath' : '/passSession/progressSession'}/${props?.follow[0]?.id}`);
        }
    }

    const getCourseOfferFn = async (idTraining: any) => {
        let createOfferObject = {
            id: null,
            idEntity: props.schoolInfo?.id,
            label: courseInfo?.title,
            status: "VALIDE",
            typePricing: "STATIC",

            rules: [
                {
                    id: null,
                    key: "id_course",
                    value: idTraining,
                    valueType: "number",
                    code_product: "CRS"
                },
                {
                    id: null,
                    key: "nb_month_access",
                    value: "-1",
                    valueType: "number",
                    code_product: "CRS"
                }
            ],
            paymentEngine: "STRIPE",
            pricings: [{
                id: null,
                period: "ONESHOT",
                status: "ACTIVE",
                price: 0,
                currency: "EUR",
                startDate: new Date(),
                endDate: null
            }],
            stripeAccount: props?.paymentMethod?.accountId
        }

        const baseUrl3 = process.env.REACT_APP_BASE_URL11
        await axiosInstance.get(baseUrl3 + `offers/offerId/` + idTraining?.toString()).then(async res => {

            if (res.data) {
                await props?.getOfferPricingCourse(res.data)
            } else {
                if (props?.courseInfo?.status === "PENDING" && courseTarificationForm === undefined && enableOfferCreation && idTraining !== undefined) {

                    setenableOfferCreation(false)
                    setTimeout(() => {
                        props.createOrUpdateOfferCourse(createOfferObject).then(() => {
                            getTaridicationTraining(idTraining)
                        });
                    }, 500)

                }
            }
        }).catch(err => console.error(err));

    }

    const getTaridicationTraining = async (courseId: number) => {
        const baseUrl3 = process.env.REACT_APP_BASE_URL11
        await axiosInstance.get(baseUrl3 + `offers/offerId/` + courseId).then(async res => {
            await props?.getOfferPricingCourse(res.data)
        }).catch(err => console.error(err));
    }

    useEffect(() => {
        if (idTraining !== 0) {
            getCourseOfferFn(idTraining)
        }
    }, [idTraining])

    useEffect(() => {
        if (props?.courseInfo?.id != undefined && props?.courseInfo?.id !== null) {
            setIdTraining(props?.courseInfo?.id)
        }

    }, [props?.courseInfo])

    useEffect(() => {
        if (owner?.length === 0) {
            if (!!courseInfo && courseInfo?.professor === "") {
                props?.getLegalPerson(courseInfo?.origin).then((resp: any) => {
                    setOwner(resp?.data?.name)
                })
            } else {
                setOwner(courseInfo?.professor?.firstName + " " + courseInfo?.professor?.lastName)
            }
        }
    }, [courseInfo])


    const fetchFeedbacks = async () => {
        let feedbacks: any = await fetchData("POST", process.env.REACT_APP_BASE_URL8 + "feedback/all/" + id, {}).then(res => {
            return res
        })
            .catch(err => { return { feedbacks: [] } })
        let listPersonId: any = feedbacks?.feedbacks.filter((filter: any) => filter.userStatus === 'USER').map((obj: any) => { return obj.idPerson })

        const listPerson = await fetchData('POST', process.env.REACT_APP_BASE_URL2 + "person/student", listPersonId).then(response => { return response })

        feedbacks['feedbacks'] = feedbacks.feedbacks.map((obj: any) => {
            let idPer = obj.idPerson;
            const person = listPerson.find((personItem: any) => personItem.id === Number(idPer));

            if (person) {

                obj.idPerson = person;
            }
            return obj;
        })
        setFeedBacks(feedbacks)
    }
    return (

        (courseInfo?.id !== 0 && props?.tarification) ?
            <>
                <div className="">
                    <a href="#listFeedbacks" id="clickLink" style={{ display: 'none' }}></a>
                    <DescPreviewCourse
                        avisClicked={avisClicked}
                        courseForm={courseInfo}
                        owner={owner}
                        price={price}
                        type={courseInfo?.type}
                        time={convertTime(courseInfo?.duration)}
                        resume={courseInfo?.resume}
                        objectives={courseInfo?.objectives}
                        prerequisite={courseInfo?.prerequisite}
                        trainingLevel={trainingLevel}
                        title={courseInfo?.title}
                        media={courseInfo?.linkImg}
                        skills={courseInfo?.skills}
                        video={courseInfo?.video}
                        trainingOffer={trainingOffer}
                    />

                    {(courseInfo?.skills != 0) &&
                        <SkillsSectionComponent
                            skills={courseInfo?.skills}
                            objectives={courseInfo?.objectives}
                            prerequisite={courseInfo?.prerequisite}
                        />}
                    <ContentChaptersSectionComponent orders={courseInfo?.orders} exams={courseInfo?.exams} time={convertTime(courseInfo?.duration)} title={courseInfo?.title} width={contentWidth} />
                    <SchoolProfSectionComponent profData={courseInfo?.professor} photoProf={courseInfo?.professor?.photo} />
                    <HowToWorkSectionComponent courseForm={courseInfo} price={price} courseInfo={courseInfo} type={courseInfo?.type} show={show} />
                    <div id="listFeedbacks">
                        <ListFeedbackSectionComponent feedBacks={feedBacks} trainingId={id} />
                    </div>
                </div>
                <div className='suivre' style={{ textAlign: 'center', paddingBottom: "25px", paddingTop: "25px", backgroundColor: '#fff' }}>
                    <button
                        className="btn-Primary large"
                        onClick={() => followTrainingCheck()}
                        disabled={(props?.connectedUser && (props?.connectedUser?.role === "role_director" || props?.connectedUser?.role === "role_prof")) || disableFollowTrainingBtn}
                    > suivre ce {courseInfo?.type === "COURSE" ? "COURS" : courseInfo?.type}</button>
                </div>
            </>
            :
            <>
                <TrainingHeaderComponentLoading />
                <SkillsSectionComponentLoading />
                <CourseContentLoading />
                <SchoolProfSectionComponentLoading />
                <HowToWorkSectionComponentLoading />
                <ListFeedbackSectionComponentLoading />
                <ButtonFollowLoading />
            </>

    )
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    history: ownProps?.history,
    priceCourse: state.courseReducer.priceCourse,
    courseInfo: state.courseReducer.courseInfo,
    show: state.courseReducer.show,
    follow: state.courseReducer.follow,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    paymentMethod: state.schoolInfoReducer.paymentSchoolInfo,
    followByTraining: state.courseReducer.followsByTraining,
    courseTarificationForm: state.courseReducer.courseTarificationForm,
    tarification: state.courseReducer.courseTarificationForm,

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    checkFollowStudentCourse,
    createFollow,
    refreshToken,
    saveReturnPath,
    getCurrentTraining,
    getCurrentCourse,
    getOfferPricingCourse,
    createOrUpdateOfferCourse,
    getRegistrationForm,
    getFollowByFilter,
    getLegalPerson,
    getCourse,
    clearTarification,
    redirect, clearHistoryArray,
    openModal,
    getApplicationFile
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PreviewCoursePage);