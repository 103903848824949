import { JSXElementConstructor, Key, ReactElement, ReactNodeArray, ReactPortal, useEffect, useState } from 'react';
import { trainingPreview } from '../../data-structures/interfaces/trainings.interfaces'
import ReactPlayer from 'react-player'
import SkillsSectionComponent from './skills-section-component'
import { useTranslation } from 'react-i18next';
import inscription_file from '../../styles/imgs/inscription_file.svg';
import acces from '../../styles/imgs/acces.svg';
import TagDiploma from '../tag-diploma/TagDiploma';
import Rating from '@material-ui/lab/Rating';
import StarOutline from '@material-ui/icons/StarOutline';
import { displayTagSubscription } from '../../helpers/training/displayTagSubscription';
import { timeConvert } from '../../base/functions/Functions';
import { useLocation, useParams } from 'react-router-dom';
import { fetchOverallRatingPerCourseApi } from '../../services/training.service';

interface PathParams {
    id: string;
}


function TrainingHeaderComponent(props: any) {

    const [trainingDetails, setTrainingDetails] = useState<trainingPreview>({
        id: 0,
        resume: '',
        title: '',
        skills: [],
        description: '',
        thematics: [],
        level: '',
        duration: 0,
        withregistrationFiles: 0,
        objectif: '',
        prerequis: '',
        trainingVideo: '',
        trainingImg: '',
        trainingType: '',
        orders: [],
        subjectsByPath: [],
        subjectsBySession: [],
        exams: [],
        withDiploma: false,

        professorId: 0,
        professorName: '',
        professorBio: '',
        professorImage: '',

        followed: false,
        followUpId: 0,

        offerId: 0,
        trainingOffer: undefined,
        nbMonthAccess: 0,

        rating: 0,
        feedback: undefined,


    })
    const [videoHeight, setVideoHeight] = useState<any>('')
    const { t } = useTranslation();
    const [rate, setRate] = useState<number>(0);
    const [priceAbn, setPriceAbn] = useState<any>()
    const [priceUnique, setPriceUnique] = useState<any>()
    const [priceFacilyty, setPriceFacilyty] = useState<any>([])
    const [abn, setAbn] = useState<Boolean>(false)
    const [unique, setUnique] = useState<Boolean>(false)
    const [facilyty, setFacilyty] = useState<Boolean>(false)
    const [per, setPer] = useState<any>()
    const [loader, setLoader] = useState<boolean>(false)
    const { id } = useParams<PathParams>();
    const location = useLocation();
    const [isMobile, setIsMobile] = useState<boolean>(false)

    useEffect(() => {
        console.log("rate", rate);

    }, [rate])
    const handleResize = () => {
        if (window.innerWidth !== undefined && window.innerWidth < 1200) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }

    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        if (props?.trainingDetails !== trainingDetails) {
            setTrainingDetails(props?.trainingDetails)
        }
    }, [props.trainingDetails])

    useEffect(() => {
        setPriceFacilyty([])
        setFacilyty(false)
        setAbn(false)
        setUnique(false)
        if (trainingDetails?.trainingOffer?.pricings?.length !== 0) {
            trainingDetails?.trainingOffer?.pricings?.map((pricing: any) => {
                if (pricing.period === "ONESHOT" && pricing.nbPeriod === null && pricing.price !== 0) {
                    setPriceUnique(pricing.price);
                    setUnique(true)
                    setAbn(false)
                }
                if (pricing.period === "MONTH" && pricing.nbPeriod != null) {
                    const newObject = { price: pricing.price, period: pricing.nbPeriod };
                    setPriceFacilyty((prevState: any) => [...prevState, newObject]);
                    setFacilyty(true)
                    setAbn(false)
                }
                if (pricing.price === 0 && pricing.period === "ONESHOT" && pricing.nbPeriod === null) {
                    setFacilyty(false)
                    setAbn(false)
                    setUnique(false)
                }
                if (["MONTH", "WEEK", "QUARTER", "SEMESTER"].includes(pricing.period) && pricing.nbPeriod == null) {
                    setPriceAbn(pricing.price)
                    setPer(getPeriod(pricing?.period))
                    setAbn(true)
                    setFacilyty(false)
                    setUnique(false)
                }
            });
        }

    }, [trainingDetails]);

    const fetchOverallRatingperCourse = async (idSubject: any) => {
        try {
            const result = await fetchOverallRatingPerCourseApi([idSubject]);
            result && result[0] && setRate(result[0].rating);
        } catch (error) {
            console.error('Error fetching rating:', error);
            setRate(0);
        }
    };
    useEffect(() => {
        fetchOverallRatingperCourse(id);
    }, [])

    useEffect(() => {
        if (trainingDetails.trainingVideo && (trainingDetails.trainingVideo.includes('dai.ly') || trainingDetails.trainingVideo.includes('dailymotion.com'))) {
            setVideoHeight('500px')
        }
        else {
            setVideoHeight('100%')
        }
    }, [trainingDetails.trainingVideo])

    const getAccess = () => {
        return trainingDetails?.trainingOffer?.rules[1]?.value === "-1" ? "Accès illimité" : `Accès ${trainingDetails?.trainingOffer?.rules[1]?.value} mois`
    }

    const getType = () => {
        switch (trainingDetails.trainingType) {
            case "COURSE":
                return "Cours"
            case "PATH":
                return "Parcours "
            case "SESSION":
                return "Session "
            default:
                break;
        }
    }

    const getPeriod = (period: any) => {
        switch (period) {
            case "MONTH":
                return "Mois"
            case "WEEK":
                return "Semaine "
            case "QUARTER":
                return "Trimestre "
            case "SEMESTER":
                return "Semestre "
            default:
                break;
        }
    }

    const getTypeFollow = () => {
        switch (trainingDetails.trainingType) {
            case "COURSE":
                return "ce Cours"
            case "PATH":
                return "ce Parcours "
            case "SESSION":
                return "cette Session "
            default:
                break;
        }
    }

    const getTypeIcon: any = () => {
        switch (trainingDetails.trainingType) {
            case "COURSE": return <>
                <div className="d-flex align-items-center ">
                    <svg width="16" height="16" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0)">
                            <path d="M14.2222 0.500061H1.77778C0.8 0.500061 0 1.30006 0 2.27784V14.7223C0 15.7001 0.8 16.5001 1.77778 16.5001H14.2222C15.2 16.5001 16 15.7001 16 14.7223V2.27784C16 1.30006 15.2 0.500061 14.2222 0.500061ZM14.2222 14.7223H1.77778V2.27784H14.2222V14.7223Z" fill="#5693EE" />
                        </g>
                        <line x1="5.38184" y1="10.2764" x2="10.6187" y2="10.2764" stroke="#5693EE" strokeWidth="1.5" strokeLinecap="round" />
                        <line x1="10.6182" y1="6.72375" x2="5.38132" y2="6.72375" stroke="#5693EE" strokeWidth="1.5" strokeLinecap="round" />
                        <defs>
                            <clipPath id="clip0">
                                <rect width="16" height="16" fill="white" transform="translate(0 0.500061)" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>

            </>
            case "PATH": return <>
                <div className="d-flex align-items-center ">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.45455 2.90909H0V14.5455C0 15.3455 0.654545 16 1.45455 16H13.0909V14.5455H1.45455V2.90909ZM14.5455 0H4.36364C3.56364 0 2.90909 0.654545 2.90909 1.45455V11.6364C2.90909 12.4364 3.56364 13.0909
                     4.36364 13.0909H14.5455C15.3455 13.0909 16 12.4364 16 11.6364V1.45455C16 0.654545 15.3455 0 14.5455 0ZM14.5455 11.6364H4.36364V1.45455H14.5455V11.6364Z" fill="#FA83EE" />
                    </svg>
                </div>

            </>
            case "SESSION": return <>
                <div className="d-flex align-items-center ">
                    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0)"><path d="M1.45455 5.22727H0V16.1364C0 16.8864 0.654545 17.5 1.45455 17.5H13.0909V16.1364H1.45455V5.22727ZM14.5455 2.5H4.36364C3.56364 2.5 2.90909 3.11364 2.90909 3.86364V13.4091C2.90909 14.1591 3.56364 14.7727 4.36364 14.7727H14.5455C15.3455 14.7727 16 14.1591 16 13.4091V3.86364C16 3.11364 15.3455 2.5 14.5455 2.5ZM14.5455 13.4091H4.36364V3.86364H14.5455V13.4091Z" fill="#8A57CC"></path></g><line x1="4" y1="7.35" x2="15" y2="7.35" stroke="#8A57CC" strokeWidth="1.3"></line><line x1="13.35" y1="3" x2="13.35" stroke="#8A57CC" strokeWidth="1.3"></line><line x1="5.35" y1="3" x2="5.35" stroke="#8A57CC" strokeWidth="1.3"></line><defs><clipPath id="clip0"><rect width="16" height="16" fill="white" transform="translate(0 1.5)"></rect></clipPath></defs></svg>
                </div>

            </>
            default: return <></>
        }
    }



    return (
        <div className='container-desc-preview-course mx-auto' style={{ position: 'relative' }}>

            {isMobile && <style>
                {`
          .MuiRating-iconEmpty {
            color: white;
                `}
            </style>}

            <div className={`relations-theory ${location.pathname.includes('/catalog/previewtraining') ? 'padding-description mt-40' : 'padding-description'}`} style={{ backgroundColor: props?.schoolInfo?.color ? props?.schoolInfo?.color : "#212529", color: '#fff' }}>

                {(trainingDetails?.trainingVideo !== "") ?
                    <div className='mx-auto img-more-info custom-video-youtube d-xl-none mb-4 d-flex justify-content-center max-width course-img-padding-top'>
                        <ReactPlayer
                            controls={true}
                            config={{
                                vimeo: {
                                    playerOptions: {
                                        responsive: true,
                                    },
                                },
                                youtube: {
                                    embedOptions: {
                                        height: '500px',
                                    }
                                },
                                dailymotion: {}
                            }}
                            width='100%'
                            height={videoHeight}
                            stopOnUnmount={false}
                            url={trainingDetails.trainingVideo} />
                    </div> :
                    <div className='img-more-info d-xl-none mb-4 d-flex justify-content-center course-img-padding-top'>
                        <img src={trainingDetails.trainingImg} alt="" className='ratio ratio-1x1 max-width' />
                    </div>
                }

                <div className='mx-auto d-none d-xl-block' style={{ maxWidth: "1200px" }}>
                    <div className="title H2-Headline" style={{ color: '#fff', maxWidth: '687px' }}>{trainingDetails.title}</div>
                    <div className="description" style={{ maxWidth: '687px', margin: '15px 0' }} dangerouslySetInnerHTML={{ __html: trainingDetails.resume }} />
                    {trainingDetails?.thematics?.length > 0 && <SkillsSectionComponent type={"theme"} skills={trainingDetails?.thematics} />}
                    <div className="d-flex align-items-center justify-content-start">
                        <div className="d-flex align-items-center me-3">
                            <span className="material-icons-outlined neutral-3 me-1">signal_cellular_alt</span>
                            <span className="body-md bold black-800" style={{ color: '#fff', marginTop: '5px' }}>{t(trainingDetails?.level).toLocaleUpperCase()}</span>
                        </div>
                        <div className="d-flex align-items-center me-3">
                            <span className="material-icons-outlined neutral-3 me-1">timer</span>
                            <span className="body-md bold black-800" style={{ color: '#fff', marginTop: '5px' }}>{t('durationChapter')} {(props?.time === '0 MN') ? '...' : trainingDetails?.trainingType === "COURSE" ? timeConvert(trainingDetails?.duration) : timeConvert(trainingDetails?.duration)}</span>
                            <span></span>
                        </div>

                        {trainingDetails.withregistrationFiles != null && <div className="d-flex align-items-center me-3">
                            <span className=" me-1"><img src={inscription_file} alt="" /></span>
                            <span className="body-md bold black-800" style={{ color: '#fff', marginTop: '5px' }}>{t('withInscription')}</span>
                            <span></span>
                        </div>}
                        <div className="d-flex align-items-center me-3">
                            <span className="neutral-3 me-1"><img src={acces} alt="" /></span>
                            <span className="body-md bold black-800" style={{ color: '#fff', marginTop: '5px' }}>{getAccess()}</span>
                            <span></span>
                        </div>
                    </div>
                </div>

                <div className='mx-auto d-block d-xl-none max-width px-3 px-sm-0'>
                    <div className={props.isMobile ? "H2-Headline mb-0" : "H3-Headline "} style={{ color: '#fff' }}>{trainingDetails.title}</div>
                    <div className="description max-width mt-2" dangerouslySetInnerHTML={{ __html: trainingDetails.resume }} />
                    <div className="d-sm-flex align-items-center justify-content-between d-xl-none mb-3">
                        <div>
                            <div className="d-flex align-items-center me-3 mt-3 mt-xl-0">
                                <span className="material-icons-outlined neutral-3 me-1 mr-1 mr-lg-0" >signal_cellular_alt</span>
                                <span className="body-md bold black-800 mt-1 ml-1 ml-lg-0" style={{ color: '#fff' }}>{t(trainingDetails.level)}</span>
                            </div>
                            <div className="d-flex align-items-center me-3 mt-2 mt-xl-0" >
                                <span className="material-icons-outlined neutral-3 me-1">timer</span>
                                <span className="body-md bold black-800 mt-1 ml-1 ml-lg-0" style={{ color: '#fff' }}>{t('durationChapter')} {(props?.time === '0 MN') ? '...' : trainingDetails.trainingType === "COURSE" ? timeConvert(trainingDetails.duration) : timeConvert(trainingDetails.duration)}</span>
                                <span></span>
                            </div>
                        </div>

                        <div>
                            {trainingDetails.withregistrationFiles != null && <div className="d-flex align-items-center me-3 mt-2 mt-xl-0">
                                <span className=" me-1"><img src={inscription_file} alt="" /></span>
                                <span className="body-md bold black-800 mt-1 ml-1 ml-lg-0" style={{ color: '#fff' }}>{t('withInscription')}</span>
                                <span></span>
                            </div>}
                            <div className="d-flex align-items-center mt-3 mt-md-0">
                                <span className="neutral-3 me-1"><img src={acces} alt="" /></span>
                                <span className="body-md bold black-800 mt-1 ml-1 ml-md-0" style={{ color: '#fff' }}>{getAccess()}</span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex align-items-center d-xl-none mt-2 mt-xl-0'>
                            <div className='flex flex-row ' style={{ marginRight: '5px' }}>
                                {getTypeIcon()}
                            </div>
                            <div className='flex flex-row body-sm bold ml-1 ml-lg-0'>{getType()} donné par {trainingDetails.professorName}</div>
                        </div>
                        {trainingDetails.withDiploma && <div className="d-xl-none" ><TagDiploma type={trainingDetails.trainingType} /></div>}
                    </div>

                    <div className='rating d-xl-none mt-2' style={{ display: 'flex', height: '30px', alignItems: 'center' }}>
                        <Rating
                            readOnly
                            precision={0.1}
                            name="customized-empty"
                            value={rate}
                            style={{ color: "#F5B95F", height: '100%' }}
                        />
                        <button style={{ backgroundColor: "transparent", border: "0", borderBottom: "1px solid white", fontWeight: "500", color: "white" }} onClick={props.scrollToFeedback}>Avis ({trainingDetails.feedback ? trainingDetails.feedback?.totalItems : 0})</button>
                    </div>
                    <div className='d-flex justify-content-between mt-4'>
                        <div>
                            <div className="" style={{ marginBottom: 12 }}>
                                {displayTagSubscription(trainingDetails?.trainingOffer).map((el: any) =>
                                    <span className={`badge-catalog-payment ${el.className}`}>{el.title}</span>)}
                            </div>
                            {unique && <div className='H3-Headline training-price'>{priceUnique}<span className='neutral-3 training-price'>€</span></div>}
                        </div>
                        {facilyty && <><hr /><div className='d-flex flex-column  mb-2'>

                            {unique && <div className='flex body-sm bold mb-2'>Ou payez en plusieurs fois</div>}
                            <div className='d-flex flex-row'>
                                {priceFacilyty?.map((item: { price: string | number | boolean | {} | ReactElement<any, string | JSXElementConstructor<any>> | ReactNodeArray | ReactPortal | null | undefined; period: string | number | boolean | {} | ReactElement<any, string | JSXElementConstructor<any>> | ReactNodeArray | ReactPortal | null | undefined; }, index: Key | null | undefined) => (
                                    <div className='flex flex-row body-md bold me-3 training-price'>{item.price}<span className='neutral-3 training-price'>€ X{item.period}</span></div>
                                ))}
                            </div>
                        </div>
                        </>
                        }

                        {abn && <div className='H3-Headline training-price'>{priceAbn}<span className='neutral-3 training-price'>€ /{per}</span></div>}
                    </div>
                    <div className='' style={{ textAlign: 'center', paddingTop: "24px" }}>
                        {!loader ?
                            <button
                                className={!isMobile ? "btn-Primary large" : "btn-Secondary large"}
                                style={{ width: '100%' }}
                                onClick={async () => await props.followAction()}
                                disabled={props.followBtn}
                            > suivre {getTypeFollow()}</button>
                            :
                            <button className="btn-Primary loaddata large" style={{ width: "100%" }}>
                                <div className="threedots-large-Animation">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </button>
                        }
                    </div>
                </div>
            </div>
            {trainingDetails.objectif && <div className="objective padding-description px-3 px-xl-0 mt-4 mt-xl-0" style={{ backgroundColor: '#fff' }}>
                <div className='mx-auto max-width'>
                    <div className='width'>
                        <div className="title H3-Headline">{t('objectives')}</div>
                        <div className="desc" style={{ maxWidth: '687px', margin: '15px 0', wordWrap: "break-word" }}>
                            {trainingDetails.objectif}
                        </div>
                    </div>
                </div>
            </div>}
            {trainingDetails.prerequis && <div className='prerequisites padding-description px-3 px-xl-0 mt-4 mt-xl-0' style={{ backgroundColor: '#FBFBFD' }}>
                <div className='mx-auto max-width'>
                    <div className="title H3-Headline">Prérequis pour cette formation</div>
                    <div className="des" style={{ maxWidth: '687px', margin: '15px 0', wordWrap: "break-word" }}>
                        {trainingDetails.prerequis}
                    </div>
                </div>
            </div>}
            <div
                className="more-info d-none d-xl-block"
                style={{ position: 'absolute', top: '50px', right: '86px', backgroundColor: '#fff', boxShadow: '0px 8px 12px rgba(159, 172, 173, 0.25)' }}>
                {(trainingDetails.trainingVideo !== "") ?
                    <div className='img-more-info custom-video-youtube' style={{ width: "370px", height: "254px" }}>
                        <ReactPlayer
                            controls={true}
                            config={{
                                vimeo: {
                                    playerOptions: {
                                        responsive: true,
                                    },
                                },
                                youtube: {
                                    embedOptions: {
                                        height: '500px',
                                    }
                                },
                                dailymotion: {}
                            }}
                            width='100%'
                            height={videoHeight}
                            stopOnUnmount={false}
                            url={trainingDetails.trainingVideo} />
                    </div> :
                    <div className='img-more-info'>
                        <img src={trainingDetails.trainingImg} alt="" style={{ width: "370px", height: "254px" }} />
                    </div>
                }
                <div className="content-more-info" style={{ position: 'relative', padding: '20px 25px 0px 25px' }}>
                    <div style={{ marginBottom: 12 }}>{displayTagSubscription(trainingDetails.trainingOffer).map((el: any) => <span className={`badge-catalog-payment ${el.className}`}>{el.title}</span>)}</div>
                    {abn && <> <div className='H3-Headline training-price'>{priceAbn}<span className='neutral-3 training-price'>€ /{per}</span></div>
                        <div className="d-flex justify-content-start">  <span className="body-md bold black-800" style={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400", letterSpacing: "0.15px" }}> {(priceAbn * (5 / 7)).toFixed(2)} &nbsp;</span>
                            <span style={{
                                fontSize: "14px", lineHeight: "20px", color: "#5A5A5A", fontWeight: "400"
                            }}>{props?.paymentMethod?.currency === "TND" ? "TND HT" : "€ HT"}</span></div>
                    </>}
                    {unique && <> <div className='H3-Headline training-price'>{priceUnique}<span className='neutral-3 training-price'>€</span></div>
                        <div className="d-flex justify-content-start">  <span className="body-md bold black-800" style={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400", letterSpacing: "0.15px" }}> {(priceUnique * (5 / 7)).toFixed(2)} &nbsp;</span>
                            <span style={{
                                fontSize: "14px", lineHeight: "20px", color: "#5A5A5A", fontWeight: "400"
                            }}>{props?.paymentMethod?.currency === "TND" ? "TND HT" : "€ HT"}</span></div></>}
                    {facilyty && <><hr /><div className='d-flex flex-column  mb-2'>
                        {unique && <div className='flex  body-sm bold mb-2'>Ou payez en plusieurs fois</div>}
                        <div className='d-flex flex-row'>
                            {priceFacilyty.map((item: { price: string | number | boolean | {} | ReactElement<any, string | JSXElementConstructor<any>> | ReactNodeArray | ReactPortal | null | undefined; period: string | number | boolean | {} | ReactElement<any, string | JSXElementConstructor<any>> | ReactNodeArray | ReactPortal | null | undefined; }, index: Key | null | undefined) => (
                                <div className='flex flex-row body-md bold me-3 training-price'>{item.price}<span className='neutral-3 training-price'>€ X{item.period}</span></div>
                            ))}
                        </div>
                    </div></>}

                    <hr />
                    <div className='d-flex  align-items-center mb-2' >
                        <div className='flex flex-row ' style={{ marginRight: '5px' }}>
                            {getTypeIcon()}
                        </div>
                        <div className='flex flex-row body-sm bold' >{getType()} donné par {trainingDetails.professorName}</div>
                    </div>
                    {trainingDetails.withDiploma && <TagDiploma type={trainingDetails.trainingType} />}
                    <div className='rating' style={{ display: 'flex', height: '30px', alignItems: 'center' }}>
                        <Rating
                            readOnly
                            precision={0.1}
                            name="customized-empty"
                            value={rate}
                            emptyIcon={<StarOutline fontSize="inherit" htmlColor="#DADBDF" />}
                            style={{ color: "#F5B95F", height: '100%' }} />
                        <button className="btn-text outlined " onClick={() => props?.scrollToFeedback()}>Avis ({trainingDetails.feedback ? trainingDetails.feedback?.totalItems : 0})</button>
                    </div>
                    <hr />
                    <div className='' style={{ textAlign: 'center', paddingBottom: "24px" }}>
                        {!loader ? <button
                            className="btn-Primary large"
                            style={{ width: '90%' }}
                            onClick={() => props.followAction()}
                            disabled={props.followBtn}
                        > suivre {getTypeFollow()}</button>
                            :
                            <button className="btn-Primary loaddata large" style={{ width: "90%" }}>
                                <div className="threedots-large-Animation">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div >

    )
}

export default TrainingHeaderComponent