import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import StarOutline from '@material-ui/icons/StarOutline';
import { getDuration } from '../../../base/functions/Functions';
import { AppState } from "../../../global-state/store/root-reducers";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import DefaultTitle from '../../../helpers/default-title'
interface Props {
  course: any,
  follow?: any
  label?: string,
  color?: string,
  onClick: any,
  t: any
}
const levels = [
  { value: "BEGINNER", label: "selectLevelCourse_1" },
  { value: "INTERMEDIATE", label: "selectLevelCourse_2" },
  { value: "ADVANCED", label: "selectLevelCourse_3" },
  { value: "ANYLEVEL", label: "anyLevelCourse" },
]
const CardCatalogHorizontalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & Props> = (props) => {
  const { t } = useTranslation();
  const course = useMemo(() => { return props?.course }, [props?.course]);
  const dateFormat = require('dateformat');

  const [priceAbn, setPriceAbn] = useState<any>()
  const [priceUnique, setPriceUnique] = useState<any>()
  const [priceFacilyty, setPriceFacilyty] = useState<any>([])
  const [abn, setAbn] = useState<Boolean>(false)
  const [unique, setUnique] = useState<Boolean>(false)
  const [facilyty, setFacilyty] = useState<Boolean>(false)
  const [per, setPer] = useState<any>()

  useEffect(() => {
    if (typeof course != undefined) {
      findCoursePaymentType(course)
    }
  }, [course])

  const findCoursePaymentType = (training: any) => {

    setPriceFacilyty([])
    setFacilyty(false)
    setAbn(false)
    setUnique(false)
    training?.trainingOffer?.pricings?.map((pricing: any) => {
      if (pricing.period === "ONESHOT" && pricing.nbPeriod === null && pricing.price != 0) {
        setPriceUnique(pricing.price);
        setUnique(true)
        setAbn(false)
      }
      if (pricing.period === "MONTH" && pricing.nbPeriod != null) {
        const newObject = { price: pricing.price, period: pricing.nbPeriod };
        setPriceFacilyty((prevState: any) => [...prevState, newObject]);
        setFacilyty(true)
        setAbn(false)
      }
      if (pricing.price == 0 && pricing.period === "ONESHOT" && pricing.nbPeriod === null) {
        setFacilyty(false)
        setAbn(false)
        setUnique(false)
      }
      if (["MONTH", "WEEK", "QUARTER", "SEMESTER"].includes(pricing.period) && pricing.nbPeriod == null) {
        setPriceAbn(pricing.price)
        setPer(getPeriod(pricing.period))
        setAbn(true)
        setFacilyty(false)
        setUnique(false)
      }
    });
  }

  const getPeriod = (period: any) => {
    switch (period) {
      case "MONTH":
        return "Mois"
      case "WEEK":
        return "Semaine "
      case "QUARTER":
        return "Trimestre "
      case "SEMESTER":
        return "Semestre "
      default:
        break;
    }
  }


  const getFacilitePrice = (training: any) => {
    let priceToDisplay = training?.trainingOffer?.pricings[0].price
    if (training?.trainingOffer?.pricings?.filter((price: any) => { return (price?.nbPeriod != null && price?.status === "ACTIVE") }).length === 1) {
      return training?.trainingOffer?.pricings?.filter((price: any) => { return (price?.nbPeriod != null && price?.status === "ACTIVE") })[0]?.price
    } else {

      training?.trainingOffer?.pricings.map((pricing: any) => {
        if (pricing?.price < priceToDisplay) {
          priceToDisplay = pricing?.price
        }
      })
    }
    return priceToDisplay

  }

  const getPrice = (training: any, type: any) => {
    switch (type) {
      case "unique":
        return training?.trainingOffer?.pricings?.filter((price: any) => { return ((price?.period === "ONESHOT" && price?.price != 0) && price?.status === "ACTIVE" && price?.nbPeriod === null) })[0]?.price

      case "subscription":
        return training?.trainingOffer?.pricings?.filter((price: any) => { return (price?.nbPeriod === null && price?.status === "ACTIVE" && price?.period != "ONESHOT") })[0]?.price
      case "facilite":
        return getFacilitePrice(training)
      case "multiple":
        return training?.trainingOffer?.pricings?.filter((price: any) => { return ((price?.period === "ONESHOT" && price?.price != 0) && price?.status === "ACTIVE" && price?.nbPeriod === null) })[0]?.price
      default:
        return 0;
    }
  }


  const addStylesToHtmlContent = (htmlContent: any) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlContent;
    const elements = tempElement.querySelectorAll('*');
    elements.forEach((el: any) => {
      el.style.fontFamily = 'Public Sans, sans-serif';
      el.style.fontSize = '16px';
    });

    // Return the modified HTML as a string
    return tempElement.innerHTML;
  };


  return (<>
    <div className="d-flex justify-content-center mx-auto" style={{ maxWidth: "1200px", maxHeight: "242px" }} onClick={props?.onClick}>
      <div className="card flex-row w-100 vignette-hover" >
        <img className="rounded-start img-card-horiz" src={(course?.linkImg !== '' && course?.linkImg !== null) ? course?.linkImg : `${process.env.PUBLIC_URL}/imgs/no-image.png`} alt="Card cap webinar" />
        <div className="card-body p-4 bg-with-border d-flex flex-column">
          <div className="d-flex flex-column mb-3">
            <div className="H3-Headline catalogue-card horizontal title">
              <DefaultTitle title={course?.title} type={course?.type} />
            </div>


            <div className="d-flex flex-row align-item-center  " style={{ gap: "28px" }}>
              {(course?.type === "SESSION") &&
                <>
                  <div className="d-flex flex-row align-items-center" style={{ height: 24 }}>
                    <div className="d-flex me-2">
                      <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0)">
                          <path d="M1.45455 5.22727H0V16.1364C0 16.8864 0.654545 17.5 1.45455 17.5H13.0909V16.1364H1.45455V5.22727ZM14.5455 2.5H4.36364C3.56364 2.5 2.90909 3.11364 2.90909 3.86364V13.4091C2.90909 14.1591 3.56364 14.7727 4.36364 14.7727H14.5455C15.3455 14.7727 16 14.1591 16 13.4091V3.86364C16 3.11364 15.3455 2.5 14.5455 2.5ZM14.5455 13.4091H4.36364V3.86364H14.5455V13.4091Z" fill="#8A57CC" />
                        </g>
                        <line x1="4" y1="7.35" x2="15" y2="7.35" stroke="#8A57CC" strokeWidth="1.3" />
                        <line x1="13.35" y1="3" x2="13.35" stroke="#8A57CC" strokeWidth="1.3" />
                        <line x1="5.35" y1="3" x2="5.35" stroke="#8A57CC" strokeWidth="1.3" />
                        <defs>
                          <clipPath id="clip0">
                            <rect width="16" height="16" fill="white" transform="translate(0 1.5)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className="card-text">
                      <div className="body-sm bold">
                        <span className="card-text neutral-2"> {t('sessionTitle')} du {dateFormat(course?.startDay, "dd/mm/yyyy")} au {dateFormat(course.closeDay, "dd/mm/yyyy")}</span>
                      </div>
                    </div>
                  </div>
                </>
              }

              {(course?.type === "PATH") &&
                <>
                  <div className="d-flex flex-row align-items-center" style={{ height: 24 }}>
                    <div className="d-flex me-2">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.45455 2.90909H0V14.5455C0 15.3455 0.654545 16 1.45455 16H13.0909V14.5455H1.45455V2.90909ZM14.5455 0H4.36364C3.56364 0 2.90909 0.654545 2.90909 1.45455V11.6364C2.90909
                        12.4364 3.56364 13.0909 4.36364 13.0909H14.5455C15.3455 13.0909 16 12.4364 16 11.6364V1.45455C16 0.654545 15.3455 0 14.5455 0ZM14.5455 11.6364H4.36364V1.45455H14.5455V11.6364Z"
                          fill="#FA83EE" />
                      </svg>
                    </div>
                    <div className="card-text">
                      <div className="body-sm bold">
                        <span className="card-text neutral-2">  {t('PathGivenBy')} {course?.personName}</span>
                      </div>
                    </div>
                  </div>
                </>
              }
              {(course?.type === "COURSE") &&
                <>
                  <div className="d-flex flex-row align-items-center pt-2">
                    <div className="d-flex align-items-center me-2 my-1">
                      <svg width="16" height="16" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0)">
                          <path d="M14.2222 0.500061H1.77778C0.8 0.500061 0 1.30006 0 2.27784V14.7223C0 15.7001 0.8 16.5001 1.77778 16.5001H14.2222C15.2 16.5001 16 15.7001 16 14.7223V2.27784C16 1.30006 15.2 0.500061 14.2222 0.500061ZM14.2222 14.7223H1.77778V2.27784H14.2222V14.7223Z" fill="#5693EE" />
                        </g>
                        <line x1="5.38184" y1="10.2764" x2="10.6187" y2="10.2764" stroke="#5693EE" strokeWidth="1.5" strokeLinecap="round" />
                        <line x1="10.6182" y1="6.72375" x2="5.38132" y2="6.72375" stroke="#5693EE" strokeWidth="1.5" strokeLinecap="round" />
                        <defs>
                          <clipPath id="clip0">
                            <rect width="16" height="16" fill="white" transform="translate(0 0.500061)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>


                    <div className="card-text">
                      <div className="body-sm bold">
                        <span className="card-text neutral-2"> {t('courseBy')} {course?.personName}</span>
                      </div>
                    </div>
                  </div></>
              }

              <div className="d-flex flex-row rating " style={{ alignItems: 'center' }}>
                <Box component="fieldset" borderColor="transparent" height="24px">
                  <Rating
                    readOnly
                    name="customized-empty"
                    value={course?.rating}
                    emptyIcon={<StarOutline fontSize="inherit" htmlColor="#DADBDF" />}
                    style={{ color: "#F5B95F" }}
                  />
                </Box>
              </div>
            </div>
          </div>





          <div className="d-flex flex-column mb-3">
            <div className="d-flex flex-row flex-wrap mb-3 align-item-center"  >

              <div className='' style={{ height: 24 }} >

                {
                  (unique === true && abn === false && facilyty === false) && <>

                    <div className="d-flex justify-content-between" style={{ alignItems: "center" }}>
                      <span className="badge-catalog-payment oneshot" style={{ margin: 0 }}>Paiement unique</span>
                      <span className="body-sm bold neutral-3" style={{ textTransform: 'capitalize', fontSize: "24px", lineHeight: "32px" }}>
                        <span className="body-md bold black-800" style={{ fontSize: "24px", lineHeight: "32px", paddingLeft: "25px" }}> {getPrice(course, "unique")}</span> {props?.paymentMethod?.currency === "TND" ? "TND" : "€"}  </span>
                    </div>
                    <div className="d-flex justify-content-end" style={{ position: "relative", top: "-25px", right: "-90px" }}>  <span className="body-md bold black-800" style={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400", letterSpacing: "0.15px" }}> {(getPrice(course, "unique") * (5 / 7)).toFixed(2)} &nbsp;</span>
                      <span style={{
                        fontSize: "14px", lineHeight: "20px", color: "#5A5A5A", fontWeight: "400"
                      }}>{props?.paymentMethod?.currency === "TND" ? "TND HT" : "€ HT"}</span></div>

                  </>

                }
                {
                  (unique === false && abn === false && facilyty === false) &&
                  <div className="d-flex justify-content-between" style={{ alignItems: "center" }}>
                    <span className="badge-catalog-payment free" style={{ margin: 0 }}>Gratuit</span>

                  </div>
                }

                {
                  (unique === false && abn === true && facilyty === false) && <>
                    <div className="d-flex justify-content-between" style={{ alignItems: "center" }}>
                      <span className="badge-catalog-payment subscription" style={{ margin: 0 }}>Abonnement</span>
                      <span className="body-sm bold neutral-3" style={{ textTransform: 'capitalize' }}>
                        <span className="body-md bold black-800" style={{ fontSize: "24px", lineHeight: "32px", paddingLeft: "25px" }}> {getPrice(course, "subscription")}</span> <span style={{ fontSize: "24px", lineHeight: "32px" }}>{props?.paymentMethod?.currency === "TND" ? "TND" : "€"}</span> /{t(course?.trainingOffer?.pricings[0]?.period)}  </span>

                    </div>
                    <div className="d-flex justify-content-end" style={{ position: "relative", top: "-25px", right: "-90px" }}>  <span className="body-md bold black-800" style={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400", letterSpacing: "0.15px" }}> {(getPrice(course, "subscription") * (5 / 7)).toFixed(2)} &nbsp;</span>
                      <span style={{
                        fontSize: "14px", lineHeight: "20px", color: "#5A5A5A", fontWeight: "400"
                      }}>{props?.paymentMethod?.currency === "TND" ? "TND HT" : "€ HT"}</span></div>

                  </>
                }

                {
                  (unique === false && abn === false && facilyty === true) && <>
                    <div className="d-flex justify-content-between" style={{ alignItems: "center" }}>
                      <span className="badge-catalog-payment facilitated" style={{ margin: 0 }}>Paiement par facilité</span>
                      <span className="body-sm bold neutral-3" style={{ textTransform: 'capitalize' }}>
                        <span className="body-md bold black-800" style={{ fontSize: "24px", lineHeight: "32px", paddingLeft: "25px" }}> {getPrice(course, "facilite")}</span> <span style={{ fontSize: "24px", lineHeight: "32px" }}>{props?.paymentMethod?.currency === "TND" ? "TND" : "€"}</span>
                      </span>
                    </div>
                    <div className="d-flex justify-content-end" style={{ position: "relative", top: "-25px", right: "-90px" }}>  <span className="body-md bold black-800" style={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400", letterSpacing: "0.15px" }}> {(getPrice(course, "facilite") * (5 / 7)).toFixed(2)} &nbsp;</span>
                      <span style={{
                        fontSize: "14px", lineHeight: "20px", color: "#5A5A5A", fontWeight: "400"
                      }}>{props?.paymentMethod?.currency === "TND" ? "TND HT" : "€ HT"}</span></div>

                  </>
                }

                {
                  (!((unique === false && abn === false && facilyty === false)
                    || (unique === true && abn === false && facilyty === false)
                    || (unique === false && abn === true && facilyty === false)
                    || (unique === false && abn === false && facilyty === true))) && <>
                    <div className="d-flex justify-content-between" style={{ alignItems: "center" }}>
                      <span className="badge-catalog-payment mutiple" style={{ margin: 0 }}>Plusieurs types de paiement</span>
                      <span className="body-sm bold neutral-3" style={{ textTransform: 'capitalize' }}>
                        <span className="body-md bold black-800" style={{ fontSize: "24px", lineHeight: "32px", paddingLeft: "25px" }}> {getPrice(course, "unique")}</span> <span style={{ fontSize: "24px", lineHeight: "32px" }}>{props?.paymentMethod?.currency === "TND" ? "TND" : "€"}</span></span>
                    </div>
                    <div className="d-flex justify-content-end" style={{ position: "relative", top: "-25px", right: "-90px" }}>  <span className="body-md bold black-800" style={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400", letterSpacing: "0.15px" }}> {(getPrice(course, "unique") * (5 / 7)).toFixed(2)} &nbsp;</span>
                      <span style={{
                        fontSize: "14px", lineHeight: "20px", color: "#5A5A5A", fontWeight: "400"
                      }}>{props?.paymentMethod?.currency === "TND" ? "TND HT" : "€ HT"}</span></div>

                  </>
                }


                {
                  (course?.price === undefined) &&
                  <span className="body-sm bold neutral-3" style={{ textTransform: 'capitalize' }}>{t('inscriptionTitle')}
                    <span className="body-md bold black-800"> ...</span>


                  </span>
                }
              </div>

            </div>
            {/* <span className="body-md card-text content catalogue-card horizontal content-description">
              {course?.resume}
            </span> */}
            <span className="body-md card-text content catalogue-card horizontal content-description" style={{ maxHeight: "48px" }} dangerouslySetInnerHTML={{ __html: addStylesToHtmlContent(course?.resume.replace(/<br\s*\/?>/gi, '')) }} />
          </div>


          <div className="d-flex justify-content-start flex-wrap">
            <div className="d-flex me-3">
              <span className="material-icons-outlined icon-grey pe-1">
                signal_cellular_alt
              </span>
              {levels.find(level => level.value === course?.level)?.value === "ANYLEVEL" ? <span className="body-md bold black-800" style={{ textTransform: 'capitalize' }}>
                {t('' + levels.find(level => level.value === course?.level)?.label)}
              </span> : <span className="body-md bold black-800">
                {t('selectLevelCourse')} {t('' + levels.find(level => level.value === course?.level)?.label)}
              </span>}
            </div>
            <div className="d-flex">
              <span className="material-icons-outlined icon-grey pe-1">
                timer
              </span>
              <span className="body-md bold details black-800">
                {getDuration(course?.duration)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

  </>
  );
}

const mapStateToProps = (state: AppState) => ({
  paymentMethod: state.schoolInfoReducer.paymentSchoolInfo

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {


    },
    dispatch
  );


export default connect(mapStateToProps, mapDispatchToProps)(CardCatalogHorizontalComponent);

