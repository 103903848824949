import { FC, useEffect, useState } from 'react';
import { connect } from "react-redux";
import { AppState } from "../../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { fetchData, getDate, getTime } from '../../../base/functions/Functions';
import { useHistory } from "react-router-dom";
import { redirect, clearHistoryArray } from '../../../global-state/actions/breadcrumb-actions';
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";
import Webinar from '../webinar-model/Webinar'

interface Props {
    webinar: any,
    selectedView: any,
    idWebinar: number
}


const labels = [

    { label: "Attente de validation", status: "WAITING" },
    { label: "Prochainement", status: "SOON" },
    { label: "C'est parti", status: "ONGOING" },
    { label: "À revoir", status: "ENDED" },
    { label: "Réfusé", status: "REFUSED" },

]
const CardWebinarHorizontal: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & Props> = (props) => {
    const [label, setLabel] = useState<any>(null)
    const [webinar, setWebinar] = useState<any>(props?.webinar)
    const { t } = useTranslation();


    useEffect(() => {

        setWebinar(props?.webinar)

    }, [props?.webinar])

    // const watch = async (webinarPr: any) => {
    //     props?.clearHistoryArray()
    //     props?.redirect(t('Webinar'), `${returnStartPathUrl()}/webinars`)
    //     props?.redirect(webinarPr?.title, "")
    //     await history.push(`${returnStartPathUrl()}/webinars/record/${webinarPr.id}`)
    // }

    const getWebinarById = async (idWebinar: any) => {
        const baseUrl = process.env.REACT_APP_BASE_URL9;
        let webinar = await fetchData("GET", baseUrl + `webinars/` + idWebinar, {}).then(res => { return res }).catch(err => console.error(err));
        setWebinar(webinar)
    }

    // const addToAgenda = async (idWebinar: any) => {
    //     const baseUrl = process.env.REACT_APP_BASE_URL9;
    //     let calendar = await fetchData("GET", baseUrl + `webinars/generate-calendar/` + idWebinar, {}).then(res => { return res }).catch(err => console.error(err));
    //     const urlLink = window.URL.createObjectURL(new Blob([calendar]));
    //     const linkk = document.createElement('a');
    //     linkk.href = urlLink;
    //     linkk.setAttribute('download', "invite.ics");
    //     document.body.appendChild(linkk);
    //     linkk.click();
    //     await getWebinarById(idWebinar)
    // }

    const start = () => {
        window.open(webinar.link, "_blank")
    }

    const changeStatusFunction = async (status: string, idWebinar: any) => {
        const baseUrl = process.env.REACT_APP_BASE_URL9;
        await fetchData("PUT", baseUrl + `webinars/webinar/` + idWebinar + `/` + status, {}).then(res => { return res }).catch(err => console.error(err));

    }
    useEffect(() => {
        if (Array.isArray(webinar?.webinarStatusHistories)) {
            let _label = labels.find(e => e?.status == webinar?.webinarStatusHistories.sort((a: any, b: any) => (a.creationDate < b.creationDate) ? 1 : -1)[0]?.status)
            if (_label?.status === "SOON" && new Date(webinar?.startDate).valueOf() - (15 * 60000) < new Date().valueOf()) {
                setLabel(labels[2])


            } else {
                setLabel(_label)
            }

        }


    }, [webinar])





    return (<>
        <div className="d-flex justify-content-center mx-auto" style={{ maxWidth: 1200 }}>
            <div className="card flex-row w-100 vignette-hover" style={{ marginBottom: 15 }}>
                {(webinar?.img !== "") &&
                    <img className="rounded-start img-card-horiz" src={webinar?.img} alt="Card image cap" style={{ width: 360, height: 238 }}></img>
                }
                {(webinar?.img === "") &&
                    <>
                        <img className="rounded-start img-card-horiz" alt="" style={{ backgroundColor: "#F2F2F5", width: 360, height: 238 }}>{ }</img>


                        <span style={{
                            position: "absolute", height: 64, fontFamily: "NoirStd-Medium",
                            fontSize: 24,
                            lineHeight: 32,
                            width: 229,
                            display: "flex",
                            alignItems: "center",
                            textAlign: "center", marginTop: 90, marginLeft: 85, color: "#272727"
                        }}>{webinar?.organiserName}</span>
                    </>
                }
                {(label?.status === "ONGOING") &&
                    <span className="badge badge-info ">{label?.label}</span>}
                {(label?.status === "ENDED" && webinar.recordingLink !== "") &&
                    <span className="badge badge-light">{label?.label}</span>}
                {(label?.status === "ENDED" && webinar.recordingLink === "") &&
                    <span className="badge badge-success">Terminé</span>}
                {(label?.status === "WAITING") &&
                    <span className="badge badge-warning">{label?.label}</span>}
                {(label?.status === "SOON") &&
                    <span className="badge badge-dark">{label?.label}</span>}
                {(label?.status === "REFUSED") &&
                    <span className="badge badge-danger ">{label?.label}</span>}
                <div className="card-body horiz p-4 w-100 bg-with-border">
                    <div className="card-text">
                        <div className="H3-Headline pb-1 webinar-card horizontal title">
                            {webinar?.title}
                        </div>
                        <div className="body-sm bold pt-2 webinar-card horizontal subtitle">
                            <span className="badge-text secondary">{t('ForStudentsOf')} </span>
                            <span className="name"> {webinar?.courseTitle}</span>
                        </div>
                        <div className="body-sm bold pt-2">
                            <span className="badge-text secondary">{t('WebinarGivenBy')} </span>
                            <span className="name">{webinar?.organiserName}</span>
                        </div>
                        <div className="body-md pt-3" style={{ maxWidth: 750 }}>
                            <span className="card-text content webinar-card horizontal content-description">
                                {webinar?.description}
                            </span>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="d-flex pt-3">



                            {(label?.status === "ONGOING" && (props?.user?.role === "role_director" || props?.user?.role === "role_student")) &&
                                <button className="btn-Primary large info icon d-flex me-2" onClick={() => { start() }}>
                                    <span className="material-icons-outlined pe-2">
                                        add
                                    </span>

                                    <div className="m-auto Button-md">
                                        {t('ENTERTHEWebinar')}
                                    </div>
                                </button>}
                            {// we should display start button for organizer what ever his role
                            }
                            {/* {(label?.status === "ONGOING" && props?.user?.role === "role_prof") &&
                                <button className="btn-Primary large info icon d-flex me-2" onClick={() => { start(webinar.link, webinar.id) }}>
                                    <span className="material-icons-outlined pe-2">
                                        play_arrow
                                    </span>

                                    <div className="m-auto Button-md" >
                                        {t('StartTHEWebinar')}
                                    </div>
                                </button>} */}
                            {/* {(label?.status === "SOON" && !webinar.agenda && (props?.user?.role === "role_director" || props?.user?.role === "role_student")) &&
                                <button className="btn-Primary large icon d-flex me-2" onClick={() => { addToAgenda(webinar.id) }}>
                                    <span className="material-icons-outlined pe-2">
                                        calendar_today
                                    </span>

                                    <div className="m-auto Button-md">
                                        {t('AddToMyAgenda')}
                                    </div>
                                </button>} */}



                            {(label?.status === "SOON" && props?.user?.role === "role_prof") &&
                                <button disabled className="btn-Primary large icon  d-flex me-2">
                                    <span className="material-icons-outlined pe-2">
                                        play_arrow
                                    </span>

                                    <div className="m-auto Button-md">
                                        {t('StartTHEWebinar')}
                                    </div>
                                </button>}

                            {(label?.status === "WAITING" && props?.user?.role === "role_prof") &&
                                <button disabled className="btn-Primary large icon  d-flex me-2">
                                    <span className="material-icons-outlined pe-2">
                                        play_arrow
                                    </span>

                                    <div className="m-auto Button-md">
                                        {t('StartTHEWebinar')}
                                    </div>
                                </button>}

                            {(label?.status === "WAITING" && props?.user?.role === "role_director") &&
                                <div className=" d-flex align-items-center">


                                    <button className="btn-Primary large success d-flex me-2" onClick={() => { changeStatusFunction("SOON", webinar.id) }}>


                                        <div className="m-auto Button-md">
                                            {t('validate')}
                                        </div>
                                    </button>
                                    <button className="btn-Primary large danger d-flex me-2" onClick={() => { changeStatusFunction("REFUSED", webinar.id) }}>


                                        <div className="m-auto Button-md">
                                            {t('Refuse')}
                                        </div>
                                    </button>
                                </div>
                            }




                            {(label?.status == "SOON" && webinar.agenda && (props?.user?.role === "role_director" || props?.user?.role === "role_student")) &&
                                <button className="btn-Primary large icon agenda-webi d-flex me-2" >
                                    <span className="material-icons-outlined pe-2">
                                        check
                                    </span>

                                    <div className="m-auto Button-md">
                                        {t('inMyAgenda')}
                                    </div>
                                </button>}

                            <Webinar status={label?.status} webinar={webinar} idWebinar={webinar.id} />


                            {/* {(label?.status === "ENDED" && webinar.recordingLink !== "") &&

                                <button className="btn-Primary large icon d-flex me-2" onClick={() => watch(webinar)}>


                                    <span className="material-icons-outlined pe-2">
                                        play_arrow
                                    </span>

                                    <div className="m-auto Button-md">
                                        {t('REVIEWTHEwebinar')}
                                    </div>
                                </button>} */}




                            {(label?.status === "ENDED" && webinar.recordingLink === "") &&

                                <button className="btn-Primary large icon agenda-webi d-flex me-2" >
                                    <span className="material-icons-outlined pe-2">
                                        check
                                    </span>

                                    <div className="m-auto Button-md">
                                        {t('webinarEnded')}
                                    </div>
                                </button>}

                            {(label?.status === "SOON" || label?.status === "ONGOING") &&
                                <button className="btn-QuickActions ms-1 me-4">
                                    <span className="material-icons-outlined">
                                        share
                                    </span>
                                </button>
                            }



                            <div className="d-flex justify-content-end  my-auto">
                                <div className="d-flex pe-3">
                                    <span className="material-icons-outlined icon-grey pe-1">
                                        calendar_today
                                    </span>
                                    <span className="body-md bold details">
                                        {getDate(webinar?.startDate)}
                                    </span>
                                </div>
                                {(label?.status !== "ENDED") && <div className="d-flex pe-3">
                                    <span className="material-icons-outlined icon-grey pe-1">
                                        schedule
                                    </span>
                                    <span className="body-md bold details">
                                        {getTime(webinar?.startDate)}-{getTime(webinar?.startDate, webinar?.duration)}
                                    </span>
                                </div>}
                                {(label?.status === "SOON" || label?.status === "ONGOING") && <div className="d-flex">
                                    <span className="material-icons-outlined icon-grey pe-1">
                                        account_circle
                                    </span>
                                    <span className="body-md bold details">
                                        {webinar?.attendee}
                                    </span>
                                </div>}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    courses: state.catalogReducer.courses,
    user: state.authReducer.connectedUser,
    school: state.schoolInfoReducer.schoolInfo,
    connected: state.authReducer.connectedUserInfo,

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            redirect, clearHistoryArray
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CardWebinarHorizontal);
