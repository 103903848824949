import { ComponentProps, FC, useEffect, useMemo, useState } from "react"
import { connect } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { AppState } from "../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import SkillsSectionComponent from "../../components/preview-course-components/skills-section-component";
import ContentChaptersSectionComponent from "../../components/preview-course-components/content-chapters-section-component";
import SchoolProfSectionComponent from "../../components/preview-course-components/school-prof-section-component";
import HowToWorkSectionComponent from "../../components/preview-course-components/how-to-work-section-component";
import { sendIpAddressCourse, getOfferCourse, getCourse, checkFollowStudentCourse, updateShow, clearAllData, createOrUpdateOfferCourse, getOfferPricingCourse } from '../../global-state/actions/course-actions';
import { clearFeedbacks, getAllFeedbacks } from '../../global-state/actions/feedback-actions';
import ListFeedbackSectionComponent from "../../components/preview-course-components/list-feedback-section-component";
import DescPreviewCourse from "../../components/preview-course-components/desc-preview-course";
import axiosInstance from "../../helpers/interceptors";
import { createFollow } from '../../global-state/actions/course-actions';
import { refreshToken } from '../../global-state/actions/auth-actions';
import { returnStartPathUrl } from "../../helpers/domainCheck";
import { getCurrentCourse, getCurrentTraining } from "../../global-state/actions/student-training-actions";
import { getLegalPerson } from "../../global-state/actions/school-actions";

interface Params {
    idTraining: string
}
const levels = [
    { label: 'anyLevelCourse', value: 'ANYLEVEL' },
    { label: 'firstLevel', value: 'BEGINNER' },
    { label: 'secondLevel', value: 'INTERMEDIATE' },
    { label: 'thirdLevel', value: 'ADVANCED' }
]
const PreviewCoursePageFromProf: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {

    const [courseInfo, setCourseInfo] = useState<any>();
    const location = useLocation();
    const [price, setPrice] = useState<any>()
    const id = useMemo(() => props?.courseInfo?.id, [props?.courseInfo])
    const [show, setShow] = useState(false)
    const [trainingLevel, setTrainingLevel] = useState<any>()
    const [trainingOffer, setTrainingOffer] = useState<any>()
    const [contentWidth, setContentWidth] = useState<any>()
    const [offerCreation, setofferCreation] = useState<boolean>(true)
    const [teacherName, setTeacherName] = useState<string>("")
    const { idTraining } = useParams<Params>();
    const key: any = process.env.REACT_APP_ABSTRACT_API_KEY;

    const history = useHistory();

    useEffect(() => {
        if (props?.connectedUser?.role?.includes("role_student") && typeof parseInt(idTraining) === "number") {
            props?.checkFollowStudentCourse(parseInt(idTraining), props?.connectedUser?.idPerson, props?.courseInfo?.type)
        }
    }, []);

    useEffect(() => {
        if (
            courseInfo !== undefined
            && courseInfo?.professor?.firstName !== undefined
            && courseInfo?.professor?.lastName !== undefined
            && courseInfo?.professor?.lastName !== ""
            && courseInfo?.professor?.firstName !== ""
        ) {
            setTeacherName(courseInfo?.professor?.firstName + " " + courseInfo?.professor?.lastName)
        } else {
            setTeacherName(courseInfo?.personName)
        }

    }, [courseInfo])

    useEffect(() => {

        if (!props?.courseInfo) return
        if (props?.courseInfo === undefined || props?.courseInfo === null || (props?.courseInfo && props?.courseInfo?.id !== Number(id))) {
            props?.getCourse(Number(id));

        } else {
            setCourseInfo(props?.courseInfo);
        }
        if (location.pathname.includes("preview")) {
            fetch("https://ipgeolocation.abstractapi.com/v1/?api_key=" + key)
        }

        fetchTrainingFeedBack();

        return () => {
            props?.clearFeedbacks();
        };
    }, [props?.courseInfo]);
    useEffect(() => {
        if (!props?.courseInfo) return
        if (props?.courseInfo) {
            props?.courseInfo?.orders?.sort((a: any, b: any) => {
                if (a.index > b.index) {
                    return 1;
                } else if (a.index < b.index) {
                    return -1
                } else {
                    return 0
                }
            })
            setCourseInfo(props?.courseInfo);
        }
    }, [props?.courseInfo])

    useEffect(() => {
        if (!props?.courseInfo) return
        const levelFouned = levels.find(obj => obj.value === props?.courseInfo?.level);
        setTrainingLevel((levelFouned) ? levelFouned.label : '...');
        if (
            props &&
            props?.courseInfo &&
            props?.courseInfo?.prerequisite !== undefined &&
            props?.courseInfo?.prerequisite !== null &&
            props?.courseInfo?.skills !== undefined &&
            props?.courseInfo?.skills !== null &&
            props?.courseInfo?.skills?.length > 0 &&
            props?.courseInfo?.objectives !== undefined &&
            props?.courseInfo?.objectives !== null
        ) {
            setContentWidth("100%");
        } else {
            setContentWidth("70%");
        }


    }, [props?.courseForm]);


    useEffect(() => {
        setShow(props?.show)
    }, [props?.show]);

    const convertTime = (minutes: number) => {
        if (minutes < 60) {
            return minutes + ' MN';
        } else {

            return Math.floor(minutes / 60) + 'H' + (((minutes % 60) < 10 ? '0' : '') + (minutes % 60));
        }
    }
    const avisClicked = () => {
        document.getElementById('clickLink')?.click();
    }

    useEffect(() => {
        if (parseInt(idTraining) !== null && parseInt(idTraining) !== undefined && courseInfo !== undefined && courseInfo === props?.courseInfo) {
            getCourseOfferFn(parseInt(idTraining))
        }
    }, [courseInfo])

    const getCourseOfferFn = async (id: number) => {
        await axiosInstance.get(process.env.REACT_APP_BASE_URL11 + `offers/offerId/` + id).then(async res => {
            if (res.data) {
                await props?.getOfferPricingCourse(res.data)
            } else if (offerCreation) {
                setTimeout(() => {
                    setofferCreation(false)
                    createTrainingOffer(props?.schoolInfo?.id, courseInfo?.title, courseInfo?.id, props?.paymentMethod?.accountId)
                }, 500)
            }
        }).catch(err => console.error(err));

    }

    const createTrainingOffer = async (schoolId: number, trainingName: string, trainingId: number, stripeId: number) => {
        let createOfferObject = {
            id: null,
            idEntity: schoolId,
            label: trainingName,
            status: "VALIDE",
            typePricing: "STATIC",

            rules: [
                {
                    id: null,
                    key: "id_course",
                    value: trainingId,
                    valueType: "number",
                    code_product: "CRS"
                },
                {
                    id: null,
                    key: "nb_month_access",
                    value: "-1",
                    valueType: "number",
                    code_product: "CRS"
                }
            ],
            paymentEngine: "STRIPE",
            pricings: [{
                id: null,
                period: "ONESHOT",
                status: "ACTIVE",
                price: 0,
                currency: "EUR",
                startDate: new Date(),
                endDate: null
            }],
            stripeAccount: stripeId
        }


        if (props?.courseInfo?.status !== "DRAFT") {
            await props.createOrUpdateOfferCourse(createOfferObject)
            await getCourseOfferFn(trainingId)
        }
    }
    const fetchTrainingFeedBack = async () => {
        await props?.clearFeedbacks();
        // await props?.getAllFeedbacks(Number(id), 0, 'refresh');
    }

    return (<>
        <div className="">
            <a href="#listFeedbacks" id="clickLink" style={{ display: 'none' }}></a>
            <DescPreviewCourse
                avisClicked={avisClicked}
                courseForm={props.courseInfoFromProf}
                owner={teacherName}
                price={price}
                type={props.courseInfoFromProf?.type}
                time={convertTime(props.courseInfoFromProf?.duration)}
                resume={props.courseInfoFromProf?.resume}
                objectives={props.courseInfoFromProf?.objectives}
                prerequisite={props.courseInfoFromProf?.prerequisite}
                trainingLevel={trainingLevel}
                title={props.courseInfoFromProf?.title}
                media={props.courseInfoFromProf?.linkImg}
                skills={props.courseInfoFromProf?.skills}
                video={props.courseInfoFromProf?.video}
                trainingOffer={trainingOffer}
                fromProf

            />



            {(props.courseInfoFromProf?.skills !== 0) && <SkillsSectionComponent
                skills={props.courseInfoFromProf?.skills}
                objectives={props.courseInfoFromProf?.objectives}
                prerequisite={props.courseInfoFromProf?.prerequisite}
            />}
            <ContentChaptersSectionComponent orders={props.courseInfoFromProf?.orders} exams={props.courseInfoFromProf?.exams} time={convertTime(props.courseInfoFromProf?.duration)} title={props.courseInfoFromProf?.title} width={contentWidth} />
            <SchoolProfSectionComponent profData={props.courseInfoFromProf?.professor} photoProf={props.courseInfoFromProf?.professor?.photo} />
            <HowToWorkSectionComponent courseForm={props.courseInfoFromProf} price={price} courseInfo={props.courseInfoFromProf} type={props.courseInfoFromProf?.type} show={show} />
            <div id="listFeedbacks">
                {courseInfo !== undefined && <ListFeedbackSectionComponent id={courseInfo.id} />}
            </div>
        </div>
        <div className='suivre' style={{ textAlign: 'center', paddingBottom: "25px", paddingTop: "25px", backgroundColor: '#fff' }}>
            <button
                className="btn-Primary large"
                disabled
            > suivre ce {props.courseInfoFromProf?.type === "COURSE" ? "COURS" : props.courseInfoFromProf?.type}</button>
        </div>
    </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    history: ownProps?.history,
    priceCourse: state.courseReducer.priceCourse,
    courseInfo: state.courseReducer.courseForm,
    courseInfoFromProf: ownProps.courseInfoFromProf,
    show: state.courseReducer.show,
    follow: state.courseReducer.follow,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    clearAllData,
    sendIpAddressCourse,
    getOfferCourse,
    getCourse,
    checkFollowStudentCourse,
    clearFeedbacks,
    getAllFeedbacks,
    updateShow,
    createFollow,
    refreshToken,
    getCurrentTraining,
    getCurrentCourse,
    getOfferPricingCourse,
    createOrUpdateOfferCourse,
    getLegalPerson

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PreviewCoursePageFromProf);