import { AppState } from '../../../global-state/store/root-reducers';
import { ComponentProps, FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import SectionCourseComponent from './course-content/section-course-component';
import SectionExamComponent from './course-content/section-exam-component';
import { useTranslation } from 'react-i18next';
import "../../../translations/i18n";
import { getDuration } from '../../../base/functions/Functions';
import SectionDiplomaComponent from './course-content/section-diploma-component';
import CommunityProgressComponent from '../../progress/community-progress/community-progress-component';


const PathCourseContentComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const [leftLoader, setLeftLoader] = useState<boolean>(false)
    const [rightLoader, setRightLoader] = useState<boolean>(false)
    let studentSectionProgress = useMemo(() => {return props?.sectionContentDetails}, [props?.sectionContentDetails])
    const [contentType, setContentType] = useState<any>(undefined)
    let sectionsNumber = useMemo(() => {
        if(props?.courseSections?.length!==0){
            return props?.courseSections?.length;
        }else{
            return 0;
        }
    }, [props?.courseSections])

    useEffect(() => {
      if(studentSectionProgress?.contentType!==contentType){
        setContentType(studentSectionProgress?.contentType)
      }
    }, [studentSectionProgress])
    
    const goNextPrevious = async (index: number, side: string) => {
        if (side === "right") {
            setRightLoader(true)
        }
        else {
            setLeftLoader(true)
        }
        await props?.getTrainingContent(index)
        await setLeftLoader(false)
        await setRightLoader(false)
    }

    useEffect(() => {
      if ((contentType === "KNOWLEDGETEST" || contentType === "FINALEXAM") && studentSectionProgress.index!==undefined ){
        props && props.getTrainingContent && props.getTrainingContent(studentSectionProgress?.index)
      }
    }, [contentType])
    



    return (
        <div className="w-100" style={{ height:'100vh' , overflow:'scroll' ,  scrollbarWidth:'none' , msOverflowStyle:'none'}} >
            {!props.isMobile &&
                <> <div style={{ backgroundColor: "#FFFFFF" }}>
                    <div className="d-flex flex-row justify-content-between px-4" style={{ padding: "40px 24px" }}>
                        <div>
                            {!leftLoader ?
                                <button disabled={studentSectionProgress?.index === 0} onClick={() => goNextPrevious(studentSectionProgress?.index - 1, "left")} type="button" className="btn-QuickActions">
                                    <span className="material-icons-outlined">keyboard_arrow_left</span>
                                </button>
                                :
                                <button className="btn-QuickActions" style={{ padding: 0 }}>
                                    <svg className="spinner" viewBox="0 0 50 50">
                                        <circle className="path" cx="25" cy="25" r="14" fill="none" strokeWidth="2"></circle>
                                    </svg>
                                </button>
                            }

                        </div>
                        {studentSectionProgress?.contentType === "CHAPTER" && (
                            <div>
                                <span className="H3-Headline">{studentSectionProgress?.sectionContent?.title} </span><span className="H3-Headline" style={{ color: "#CACBCE" }} >{getDuration(studentSectionProgress?.sectionContent?.duration)}</span>
                            </div>
                        )}
                        {studentSectionProgress?.contentType === "FINALEXAM" && (
                            <div className="first-letter-capitalize">
                                <span className="H3-Headline">{t('EXAMEN_FINAL')}.</span><span className="H3-Headline" style={{ color: "#CACBCE" }} >{getDuration(studentSectionProgress?.sectionContent?.duration)}</span>
                            </div>
                        )}
                        {studentSectionProgress?.contentType === "KNOWLEDGETEST" && (
                            <div>
                                <span className="H3-Headline">{t('textConnaissance')}.</span><span className="H3-Headline" style={{ color: "#CACBCE" }} >{getDuration(studentSectionProgress?.sectionContent?.duration)}</span>
                            </div>
                        )}
                        {studentSectionProgress?.contentType === "diploma" && (
                            <div>
                                <span className="H3-Headline">{t('diplomeButton')}</span>
                            </div>
                        )}
                        <div>
                            {!rightLoader ? <button disabled={(studentSectionProgress?.index === sectionsNumber - 1)} onClick={() => goNextPrevious(studentSectionProgress?.index + 1, "right")} type="button" className="btn-QuickActions">
                                <span className="material-icons-outlined">keyboard_arrow_right</span>
                            </button>
                                :
                                <button className="btn-QuickActions" style={{ padding: 0 }}>
                                    <svg className="spinner" viewBox="0 0 50 50">
                                        <circle className="path" cx="25" cy="25" r="14" fill="none" strokeWidth="2"></circle>
                                    </svg>
                                </button>
                            }
                        </div>
                    </div>
                </div>
                </>
            }
            {(contentType === "CHAPTER") && (
                <>
                    <div style={{ backgroundColor: "#FFFFFF" }}>

                        <SectionCourseComponent isMobile={props.isMobile} studentSection={studentSectionProgress?.sectionContent} />
                    </div>
                    <div className="mx-3">
                        <CommunityProgressComponent chapter={studentSectionProgress?.sectionContent} isMobile={props.isMobile} />
                    </div>
                </>
            )}

            {((studentSectionProgress?.contentType === "KNOWLEDGETEST") || (studentSectionProgress?.contentType === "FINALEXAM")) && (
                <>
                    <SectionExamComponent isMobile={props.isMobile} studentSection={studentSectionProgress} />
                </>

            )}

            {(studentSectionProgress?.contentType === "diploma") && (
                <div style={{ backgroundColor: "#FFFFFF", minHeight: 'calc(100vh - 272px)' }}>
                    <SectionDiplomaComponent isMobile={props.isMobile} />
                </div>
            )}

        </div>
    )
}


const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
        },
        dispatch
    );
const mapStateToProps = (state: AppState, ownProps: any) => ({
    sectionContentDetails: state.studentTraining.sectionContentDetails,
    course: state.studentTraining.currentCourse,
    isCompleted: state.studentTraining.isCompleted,
    checkIndexProgress: state.studentTraining.first,
    trainingSections: state.studentTraining.sections

});

export default connect(mapStateToProps, mapDispatchToProps)(PathCourseContentComponent);

