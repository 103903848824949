import { FC, useEffect, useState } from 'react';
import { connect } from "react-redux";
import { AppState } from "../../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { fetchData, getDate, getTime } from '../../../base/functions/Functions';
import { redirect, clearHistoryArray } from '../../../global-state/actions/breadcrumb-actions';
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";
import Webinar from '../webinar-model/Webinar'
import axiosInstance from '../../../helpers/interceptors';

interface Props {
    webinar: any,
    selectedView: any,
    idWebinar: number
}


const labels = [

    { label: "Attente de validation", status: "WAITING" },
    { label: "Prochainement", status: "SOON" },
    { label: "C'est parti", status: "ONGOING" },
    { label: "À revoir", status: "ENDED" },
    { label: "Réfusé", status: "REFUSED" },

]
const CardWebinarVertical: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & Props> = (props) => {
    const [label, setLabel] = useState<any>(null)
    const [webinar, setWebinar] = useState<any>(props?.webinar)
    const { t } = useTranslation();


    useEffect(() => {
        setWebinar(props?.webinar)
    }, [props?.webinar])

    // const watch = async (webinarPr: any) => {
    //     props?.clearHistoryArray()
    //     props?.redirect(t('Webinar'), `${returnStartPathUrl()}/webinars`)
    //     props?.redirect(webinarPr?.title, "")
    //     history.push(`${returnStartPathUrl()}/webinars/record/${webinarPr.id}`)

    // }

    // const getWebinarById = async (idWebinar: any)=>{
    //     const baseUrl = process.env.REACT_APP_BASE_URL9;
    //     let webinar = await fetchData("GET",baseUrl + `webinars/` + idWebinar,{}).then(res => { return res }).catch(err => console.error(err));
    //     setWebinar(webinar)
    // }
    
    // const addToAgenda = async (idWebinar: any) => {
    //     const baseUrl = process.env.REACT_APP_BASE_URL9;
    //     let calendar = await fetchData("GET",baseUrl + `webinars/generate-calendar/` + idWebinar,'').then(res => { return res }).catch(err => console.error(err));
    //     const urlLink = window.URL.createObjectURL(new Blob([calendar]));
    //     const linkk = document.createElement('a');
    //     linkk.href = urlLink;
    //     linkk.setAttribute('download', "invite.ics");
    //     document.body.appendChild(linkk);
    //     linkk.click();
    //     await getWebinarById(idWebinar)


    // }
    const start = () => {

        window.open(webinar.link, "_blank")
    }

    const changeStatusFunction = async (status: string, idWebinar: any) => {
        console.log("status ", status);
        console.log("idWebinar ", idWebinar);
        const baseUrl = process.env.REACT_APP_BASE_URL9;
        await axiosInstance.put(baseUrl + `webinars/webinar/` + idWebinar + `/` + status)
            .then(res => { return res.data })
            .catch(err => console.error(err));

    }
    useEffect(() => {
        if (Array.isArray(webinar?.webinarStatusHistories)) {
            let _label = labels.find(e => e?.status === webinar?.webinarStatusHistories.sort((a: any, b: any) => (a.creationDate < b.creationDate) ? 1 : -1)[0]?.status)
            if (_label?.status === "SOON" && new Date(webinar?.startDate).valueOf() - (15 * 60000) < new Date().valueOf()) {
                setLabel(labels[2])


            } else {
                setLabel(_label)
            }

        }


    }, [webinar])





    return (<>

        <div>
            <div className="card vignette-hover" style={{ minHeight: 684 }}>
                {(webinar?.img !== "") &&
                    <img className="card-img-top" src={webinar?.img} alt="Card image cap" style={{ position: 'relative', height: 220, objectFit: 'cover' }}></img>
                }
                {(webinar?.img === "") &&
                    <>
                        <img className="card-img-top" alt="" style={{ position: 'relative', backgroundColor: "#F2F2F5", height: 220, objectFit: 'cover' }}>{ }</img>


                        <span style={{
                            position: "absolute", height: 64, fontFamily: "NoirStd-Medium",
                            fontSize: 24,
                            lineHeight: 32,
                            width: 229,
                            display: "flex",
                            alignItems: "center",
                            textAlign: "center", marginTop: 90, marginLeft: 85, color: "#272727"
                        }}>{webinar?.organiserName}</span>
                    </>
                }

                {(label?.status === "ONGOING") &&
                    <span className="badge badge-info">{label?.label}</span>}
                {(label?.status === "ENDED" && webinar?.recordingLink !== "") &&
                    <span className="badge badge-light">{label?.label}</span>}
                {(label?.status === "ENDED" && webinar?.recordingLink === "") &&
                    <span className="badge badge-success">{t('ENDED')}</span>}
                {(label?.status === "WAITING") &&
                    <span className="badge badge-warning">{label?.label}</span>}
                {(label?.status === "SOON") &&
                    <span className="badge badge-dark">{label?.label}</span>}
                {(label?.status === "REFUSED") &&
                    <span className="badge badge-danger">{label?.label}</span>}


                <div className="card-body p-4 d-flex flex-column" style={{}}>
                    <div className="H3-Headline pb-1 webinar-card vertical title" >

                        {webinar?.title}
                    </div>
                    <div className="card-text ">
                        <div className="body-sm bold pt-2 " >
                            <span className="badge-text secondary" >{t('ForStudentsOf')} </span><br></br>
                            <span className="name webinar-card vertical subtitle" >
                                {webinar?.courseTitle}</span>
                        </div>
                        <div className=" body-sm bold pt-2"  >
                            <span className="badge-text secondary">{t('WebinarGivenBy')}</span><br></br>
                            <span className="name">{webinar?.organiserName}</span>

                        </div>

                        <div className="body-md pt-3">
                            <span className="card-text content webinar-card vertical content-description">
                                {webinar?.description}
                            </span>
                        </div>

                    </div>
                    <div className="d-flex justify-content-start py-4 mt-auto ">
                        <div className="d-flex pe-3">
                            <span className="material-icons-outlined icon-grey pe-1">
                                calendar_today
                            </span>
                            <span className="body-md bold details">
                                {getDate(webinar?.startDate)}
                            </span>
                        </div>
                        {(label?.status !== "ENDED") &&
                            <div className="d-flex pe-3">
                                <span className="material-icons-outlined icon-grey pe-1">
                                    schedule
                                </span>
                                <span className="body-md bold details">
                                    {getTime(webinar?.startDate)}-{getTime(webinar?.startDate, webinar?.duration)}
                                </span>
                            </div>}
                        {/* {(label?.status === "SOON" || label?.status === "ONGOING") &&
                            <div className="d-flex">
                                <span className="material-icons-outlined icon-grey pe-1">
                                    account_circle
                                </span>
                                <span className="body-md bold details">
                                    {webinar?.attendee}
                                </span>
                            </div>} */}
                    </div>
                    <div className="d-flex">
                        {(label?.status === "ONGOING" && (props?.user?.role === "role_director" || props?.user?.role === "role_student")) &&
                            <button className="btn-Primary large info icon d-flex me-2" onClick={() => { start() }}>
                                <span className="material-icons-outlined pe-2">
                                    add
                                </span>

                                <div className="m-auto Button-md">
                                    {t('ENTERTHEWebinar')}
                                </div>
                            </button>}
                        {// we should display start button for organizer what ever his role
                        }
                        {(label?.status === "ONGOING" && props?.user?.role === "role_prof") &&
                            <button className="btn-Primary large info icon d-flex me-2" onClick={() => { start() }}>
                                <span className="material-icons-outlined pe-2">
                                    play_arrow
                                </span>

                                <div className="m-auto Button-md" >
                                    {t('StartTHEWebinar')}
                                </div>
                            </button>}
                        {/* {(label?.status === "SOON" && !webinar.agenda && (props?.user?.role === "role_director" || props?.user?.role === "role_student")) &&
                            <button className="btn-Primary large icon d-flex me-2" onClick={() => { addToAgenda(webinar.id) }}>
                                <span className="material-icons-outlined pe-2">
                                    calendar_today
                                </span>

                                <div className="m-auto Button-md">
                                    {t('AddToMyAgenda')}
                                </div>
                            </button>} */}



                        {(label?.status === "SOON" && props?.user?.role === "role_prof") &&
                            <button disabled className="btn-Primary large icon  d-flex me-2">
                                <span className="material-icons-outlined pe-2">
                                    play_arrow
                                </span>

                                <div className="m-auto Button-md">
                                    {t('StartTHEWebinar')}
                                </div>
                            </button>}

                        {(label?.status === "WAITING" && props?.user?.role === "role_prof") &&
                            <button disabled className="btn-Primary large icon  d-flex me-2">
                                <span className="material-icons-outlined pe-2">
                                    play_arrow
                                </span>

                                <div className="m-auto Button-md">
                                    {t('StartTHEWebinar')}
                                </div>
                            </button>}

                        {(label?.status === "WAITING" && (props?.user?.role === "role_director" || props?.user?.role === "role_adminstrator")) &&
                            <div className=" d-flex align-items-center">


                                <button className="btn-Primary large success d-flex me-2" onClick={() => { changeStatusFunction("SOON", webinar?.id) }}>
                                        {t('validate')} 
                                </button>
                                <button className="btn-Primary large danger d-flex me-2" onClick={() => { changeStatusFunction("REFUSED", webinar?.id) }}>


                                    <div className="m-auto Button-md">
                                        {t('Refuse')}
                                    </div>
                                </button>
                            </div>
                        }




                        {/* {(label?.status == "SOON" && webinar.agenda && (props?.user?.role === "role_director" || props?.user?.role === "role_student")) &&
                            <button className="btn-Primary large icon agenda-webi d-flex me-2"  >
                                <span className="material-icons-outlined pe-2">
                                    check
                                </span>

                                <div className="m-auto Button-md">
                                    {t('inMyAgenda')}
                                </div>
                            </button>} */}

                        <Webinar name="update" status={label?.status} webinar={webinar} idWebinar={webinar?.id} />

                        {/* {(label?.status === "ENDED" && webinar?.recordingLink?.length!=0) &&

                            <button className="btn-Primary large icon d-flex me-2" onClick={() => watch(webinar)}>


                                <span className="material-icons-outlined pe-2">
                                    play_arrow
                                </span>

                                <div className="m-auto Button-md">
                                    {t('REVIEWTHEwebinar')}
                                </div>
                            </button>} */}


                        {(label?.status === "ENDED" && webinar?.recordingLink?.length===0) &&

                            <button className="btn-Primary large icon agenda-webi d-flex me-2" >
                                <span className="material-icons-outlined pe-2">
                                    check
                                </span>

                                <div className="m-auto Button-md">
                                {t('webinarEnded')}
                                </div>
                            </button>}

                        {/* {(label?.status === "SOON" || label?.status === "ONGOING") &&
                            <button className="btn-QuickActions ms-1">
                                <span className="material-icons-outlined">
                                    share
                                </span>
                            </button>
                        } */}
                    </div>
                </div>
            </div>

        </div>

    </>
    );
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    courses: state.catalogReducer.courses,
    user: state.authReducer.connectedUser,
    school: state.schoolInfoReducer.schoolInfo,
    connected: state.authReducer.connectedUserInfo,

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            redirect, clearHistoryArray
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CardWebinarVertical);
