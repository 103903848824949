/* eslint react/no-did-mount-set-state: 0 */
import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { createCourse, clearChapter, setTypeFormContentCourse, getCreateOrUpdate, setIndexContent, clearFormTest, updateCourse, getChapter, getExam, checkFinalExam, createChapter, getCourseChapters } from '../../global-state/actions/course-actions';
import { clearFormVideo } from '../../global-state/actions/upload-video-course-actions';
import { clearHistoryArray, redirect } from '../../global-state/actions/breadcrumb-actions';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import withScrolling from 'react-dnd-scrollzone';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { openModal } from '../../global-state/actions/modal-actions';
import { ModalIDS } from '../modals/modal-ids';
import CardExamComponent from './card-list-content-course/card-exam-component';
import ListCardsTestChapterComponent from './list-cards-test-chapter-component';
import { returnStartPathUrl } from '../../helpers/domainCheck';

const ScrollingComponent = withScrolling('div');
const ContentCourseFormComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [courseForm, setCourseForm] = useState<any>();
    const [final, setFinal] = useState<any[]>([]);
    const [allListe, setAllListe] = useState<any[]>([]);
    const [tests, setTests] = useState<any>([]);
    const [chapterForm, setChapterForm] = useState<any>({
        id: null,
        status: "ACTIVE",
        title: t('chapterTitleBegin'),
        duration: 0,
        text: "",
        index: null,
        files: [],
        video: ''
    });
    const [chapterCreationClicked, setChapterCreationClicked] = useState(false);

    useEffect(() => {
      if(courseForm!==undefined && courseForm?.duration !== calculateDuration()){
        let copy = courseForm;
        copy['duration']=calculateDuration()        
      }
    }, [courseForm])

    const calculateDuration=()=>{
        let duration = 0
        courseForm.orders.forEach((e: any) => {
            duration += e.chapter?.duration
        })

        tests.forEach((e: any) => {
            duration += e.duration
        })
        return duration;
    }
    


    const orderCourseContent = (chapters: any, exams: any) => {
        let compteurChapter = 1;
        let compteurTest = 1;
        return chapters?.concat(exams?.filter((test: any) => test.qnaType === "KNOWLEDGETEST"))?.sort((a: any, b: any) => {
                if (a.index > b.index) { return 1; } else if (a.index < b.index) { return -1 } else { return 0 }
            })?.map((obj: any, index: number) => {
                if (obj?.chapter) {
                    obj['compteur'] = compteurChapter;
                    compteurChapter++;
                } else {
                    obj['compteur'] = compteurTest;
                    compteurTest++;
                }
                return obj;
            })
    }
    useEffect(() => {
        setTests(props?.listTest);
        setFinal(props?.listTest?.filter((test: any) => test.qnaType === "FINALEXAM"))
        if (props?.courseForm?.orders !== undefined && props?.listTest !== undefined) {
            setAllListe(props?.courseForm && props?.courseForm.orders && props.listTest ? orderCourseContent(props?.courseForm?.orders, props?.listTest) : []);
        }
        setCourseForm(props?.courseForm);
    }, [props]);

    useEffect(() => {
        setTests(props?.listTest);
        setFinal(props?.listTest?.filter((test: any) => test.qnaType === "FINALEXAM"))
        if (props?.courseForm?.orders !== undefined && props?.listTest !== undefined) {
            setAllListe(props?.courseForm && props?.courseForm.orders && props.listTest ? orderCourseContent(props?.courseForm?.orders, props?.listTest) : []);
        }
        setCourseForm(props?.courseForm);
    }, []);

    useEffect(() => {
        setTests(props?.listTest);
        setFinal(props?.listTest?.filter((test: any) => test.qnaType === "FINALEXAM"))
        if (props?.courseForm?.orders !== undefined && props?.listTest !== undefined) {
            setAllListe(props?.courseForm && props?.courseForm.orders && props.listTest ? orderCourseContent(props?.courseForm?.orders, props?.listTest) : []);
        }
    }, [props?.listTest, props?.courseForm])

    const goToCreateChapter = async () => {
        if (chapterCreationClicked === false) {
            setChapterCreationClicked(true)
        }
        else {
            return
        }

        const numberChapter = (courseForm?.orders?.length > 0) ? courseForm?.orders?.length + 1 : 1;
        props?.clearHistoryArray()
        props?.redirect(t('courseTable'), `${returnStartPathUrl()}/courses/my-courses`)
        props?.redirect(courseForm?.title || t('titleCourseForm'), `${returnStartPathUrl()}/courses/create-formation/course/content`)
        props?.redirect(t('breadCrumbChapter') + ' ' + numberChapter, `${returnStartPathUrl()}/courses/create-formation/course/chapter-test`)
        await props?.clearFormVideo();
        await props?.clearChapter();
        await props?.getCreateOrUpdate('create');
        await props?.setTypeFormContentCourse('CHAPTER');
        await props?.setIndexContent(allListe?.length + 1);
        chapterForm.index = allListe?.length + 1;
        await props?.createChapter(chapterForm, props?.courseForm?.id);

        history.push(`${returnStartPathUrl()}/courses/create-formation/course/chapter-test`);
    }

    const convertTimeTotal = () => {
        let duration = 0
        courseForm && courseForm.orders && courseForm.orders.forEach((e: any) => {
            duration += e.chapter?.duration
        })

        tests.forEach((e: any) => {
            duration += e.duration
        })

        if (duration < 60) {
            return duration + ' MN';
        } else {

            return Math.floor(duration / 60) + 'H' + (((duration % 60) < 10 ? '0' : '') + (duration % 60));
        }
    }

    const getChapter = async (chapter: any) => {
        const findTitleChapter = props?.courseForm?.orders?.find((el: any) => el.id === chapter.id).chapter.title

        await props?.clearFormVideo();
        const numberChapter = courseForm && courseForm.orders && courseForm.orders.findIndex((obj: any) => obj.chapter.id === chapter.chapter.id);
        if (numberChapter !== -1) {
            await props?.setTypeFormContentCourse('CHAPTER');
            await props?.setIndexContent(numberChapter);
            await props?.getChapter(chapter.chapter, numberChapter);
            props?.clearHistoryArray()
            await props?.getCreateOrUpdate('update');
            props?.redirect(t('courseTable'), `${returnStartPathUrl()}/courses/my-courses`)
            props?.redirect(courseForm?.title || t('titleCourseForm'), `${returnStartPathUrl()}/courses/create-formation/course/content`)
            props?.redirect(findTitleChapter, `${returnStartPathUrl()}/courses/create-formation/course/chapter-test`)
            history.push(`${returnStartPathUrl()}/courses/create-formation/course/chapter-test`);
        }
    }

    useEffect(() => {
        if (props?.courseForm !== undefined && props?.courseForm?.id !== undefined) {
            props?.getCourseChapters(props?.courseForm?.id, props?.courseForm)
        }
    }, [])


    const getExam = async (exam: any) => {
        const numberExam = tests.sort((a: any, b: any) => { if (a.index > b.index) { return 1; } else if (a.index < b.index) { return -1 } else { return 0 } }).findIndex((obj: any) => obj.id === exam.id);
        if (numberExam !== -1) {
            await props?.setTypeFormContentCourse(exam.qnaType);
            await props?.setIndexContent(numberExam);
            await props?.getExam(exam, numberExam);
            props?.clearHistoryArray()
            await props?.getCreateOrUpdate('update');
            props?.redirect(t('courseTable'), `${returnStartPathUrl()}/courses/my-courses`)
            props?.redirect(courseForm?.title || t('titleCourseForm'), `${returnStartPathUrl()}/courses/create-formation/course/content`)
            props?.redirect(exam.title, `${returnStartPathUrl()}/courses/create-formation/course/chapter-test`)
            history.push(`${returnStartPathUrl()}/courses/create-formation/course/chapter-test`);
        }
    }

    const openModalChoice = () => {
        props?.openModal({ idModal: ModalIDS.CHOICE_TYPE_TEST, size: "md", data: { index: allListe.length } });
    }
    return (
        <>
            {!(allListe?.length === 0 && final?.length === 0) ? (
                <>
                    <div className="justify-content-center mt-4 py-2 px-2">
                        <div className="mx-auto" style={{ maxWidth: 1200 }}>
                            <div className="p-2 d-flex w-100 align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                    <button className="btn-Primary large icon-left me-3" onClick={(e) => { goToCreateChapter() }}>
                                        <span className="material-icons">add</span>
                                        <div className="m-auto ps-2">{t('newChapter')}</div>
                                    </button>
                                    <button className="btn-Primary large icon-left" style={{ display: 'flex', alignItems: 'center' }} onClick={() => { openModalChoice() }}>
                                        <span className="material-icons">add</span>
                                        <div className="m-auto ps-2">{t('newTest')}</div>
                                    </button>
                                </div>
                                <div className="d-flex align-items-center me-2">
                                    <span className="material-icons-outlined me-2" style={{ color: '#CACBCE' }}>timer</span>
                                    <p className="body-md bold black-800 m-auto">{convertTimeTotal()}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-2" style={{ overflowY: 'scroll', maxHeight: '50vh' }}>
                        {(courseForm && courseForm.orders && courseForm.orders.length === 0 && tests.length === 0) && (
                            <>
                                <div style={{ marginBottom: "15%" }}></div>
                                <p style={{ textAlign: "center", fontSize: "28px", color: "black" }}>{t('listChaptersContent')}</p>
                                <div style={{ marginBottom: "10%" }}></div>
                            </>
                        )}

                        {((courseForm && courseForm.orders && courseForm.orders.length > 0) || (tests && tests.length > 0)) && (
                            <>
                                <div style={{ maxWidth: 1200, margin: 'auto' }}>
                                    <DndProvider backend={HTML5Backend}>
                                        <ScrollingComponent style={{ position: 'relative' }}>
                                            <ListCardsTestChapterComponent t={t} getExamForm={getExam} getChapterForm={getChapter} examFinal={final} listeChapters={allListe} />
                                        </ScrollingComponent>
                                    </DndProvider>
                                </div>
                                {final.length === 1 && (
                                    <div className="mt-3" style={{ maxWidth: 1200, margin: 'auto' }}>
                                        <CardExamComponent getExam={(value: any) => getExam(value)} exam={final[0]} index={allListe?.length + 1} />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </>
            ) : (
                <div className="d-flex flex-column align-items-center justify-content-center px-2" style={{ height: 'calc(100vh - 490px)' }}>
                    <h3 className="H3-Headline mb-2">{t('createContentText')}</h3>
                    <div className="mt-4 d-flex flex-row">
                        <button onClick={(e) => { goToCreateChapter() }} className="btn-Primary large icon-left me-3">
                            <span className="material-icons-outlined">add</span>
                            <div className="m-auto ps-2">{t('newChapter')}</div>
                        </button>
                        <button onClick={() => { openModalChoice() }} className="btn-Primary large icon-left">
                            <span className="material-icons-outlined">add</span>
                            <div className="m-auto ps-2">{t('Nouveau_test')}</div>
                        </button>
                    </div>
                </div>
            )}
        </>

    );
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            createCourse,
            updateCourse,
            getChapter,
            clearFormVideo,
            getExam,
            clearFormTest,
            checkFinalExam,
            openModal,
            createChapter,
            clearChapter,
            setTypeFormContentCourse,
            setIndexContent,
            getCreateOrUpdate,
            getCourseChapters,
            clearHistoryArray,
            redirect
        },
        dispatch
    );
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    loggedIn: state.authReducer.loggedIn,
    courseForm: state.courseReducer.courseForm,
    listeHistory: state.historyReducer.listHistoryBreadCrumb,
    history: ownProps?.history,
    t: ownProps?.t,
    indexContent: state.courseReducer.indexContent,
    listTest: state.courseReducer.listTest,
    nbrFinalExam: state.courseReducer.nbrFinalExam,
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentCourseFormComponent);
