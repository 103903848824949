import { ComponentProps, FC, useMemo } from 'react'
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { connect } from "react-redux";
import "../../translations/i18n";
import { useTranslation } from 'react-i18next';
import AddAndUpdateZoomAccountCredentialComponent from '../profile-components/add-and-update-zoom-account-credential-component';
import StripeÎntegrationComponent from './stripe-întegration-component';
import ConnectToStripeWebhookComponent from './connect-to-stripe-webhook-component'

const IntegrationModulesComponent : FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    let role = useMemo(() => props?.userRole, [props?.userRole])

    return (
        <div>
            {/* <div className='row mb-4 mt-4 ' style={{ maxWidth: '1200px' }}>
                <span className="H3-Headline black-800" style={{ fontSize: "24px", lineHeight: "32px" }}>Webinaires</span>
            </div>
            <div>
                <AddAndUpdateZoomAccountCredentialComponent />
            </div> */}
            {(role === "role_director") &&
                <div style={{ paddingTop: "52px" , paddingBottom:"50px" }}>
                    <span className="H3-Headline">{t('paymentTitlePlural')}</span>
                    <div style={{ marginBottom: 40 }}></div>
                    <div className="d-flex align-items-center mb-3">
                        <span className="H4-Subtitle">{t('paymentMethodCourses')}</span>
                    </div>
                    {/* <PaymentDetailsComponent /> */}
                    <StripeÎntegrationComponent />
                    <ConnectToStripeWebhookComponent />
                </div>}
        </div>
    )
}


const mapStateToProps = (state: AppState, ownProps: any) => ({
    userRole:state.authReducer.connectedUser.role
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationModulesComponent );

