import { ComponentProps, FC, useState, useEffect, useRef } from "react"
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useHistory, useParams } from "react-router-dom";
import { getTrainingPreviewDetails, updateVisit } from "../../services/training.service";
import { trainingPreview } from "../../data-structures/interfaces/trainings.interfaces";
import SkillsSectionComponent from "../../components/preview-course-components/skills-section-component";
import ContentChaptersSectionComponent from "../../components/preview-course-components/content-chapters-section-component";
import HowToWorkSectionComponent from "../../components/preview-course-components/how-to-work-section-component";
import ListFeedbackSectionComponent from "../../components/preview-course-components/list-feedback-section-component";
import { timeConvert } from "../../base/functions/Functions";
import { useTranslation } from 'react-i18next';
import { createFollow, getRegistrationForm, getApplicationFile } from '../../global-state/actions/course-actions';
import { refreshToken } from '../../global-state/actions/auth-actions';
import { redirect, clearHistoryArray, saveReturnPath, clearSaveReturnPath } from '../../global-state/actions/breadcrumb-actions';
import { ModalIDS } from "../../components/modals/modal-ids";
import { clearModal, closeModal, openModal } from "../../global-state/actions/modal-actions";
import TrainingHeaderComponent from "../../components/preview-course-components/training-header-component";
import PathSessionContentSection from "../../components/preview-course-components/path-session-content-section";
import TrainingProfessorSchoolSectionComponent from "../../components/preview-course-components/training-professor-school-section-component";
import TrainingHeaderComponentLoading from "../../components/preview-course-components/training-header-component-loading";
import SkillsSectionComponentLoading from "../../components/preview-course-components/skills-section-component-loading";
import CourseContentLoading from "../../components/preview-course-components/course-content-loading";
import SchoolProfSectionComponentLoading from "../../components/preview-course-components/school-prof-section-component-loading";
import HowToWorkSectionComponentLoading from "../../components/preview-course-components/how-to-work-section-component-loading";
import ListFeedbackSectionComponentLoading from "../../components/preview-course-components/list-feedback-section-component-loading";
import ButtonFollowLoading from "../../components/preview-course-components/button-follow-loading";
import SchoolProfSectionComponent from "../../components/preview-course-components/school-prof-section-component";

interface PathParams {
    type: string;
    id: string;
}
const TrainingPreviewPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { type } = useParams<PathParams>();
    const { id } = useParams<PathParams>();
    const feedbackRef = useRef<any>(null);
    const [trainingDetails, setTrainingDetails] = useState<trainingPreview>({
        id: 0,
        resume: '',
        title: '',
        skills: [],
        description: '',
        thematics: [],
        level: '',
        duration: 0,
        withregistrationFiles: 0,
        objectif: '',
        prerequis: '',
        trainingVideo: '',
        trainingImg: '',
        trainingType: '',
        orders: [],
        subjectsByPath: [],
        subjectsBySession: [],
        exams: [],
        withDiploma: false,
        professorId: 0,
        professorName: '',
        professorBio: '',
        professorImage: '',

        followed: false,
        followUpId: 0,
        offerId: 0,
        trainingOffer: undefined,
        nbMonthAccess: 0,
        rating: 0,
        feedback: undefined,
    })
    const [trainingPrice, setTrainingPrice] = useState<number>(0)
    const history = useHistory();
    const { t } = useTranslation();
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [isMobile, setIsMobile] = useState<boolean>(false)
    const [followBtn, setFollowBtn] = useState<boolean>(false)
    const [trainingPreviewLoader, setTrainingPreviewLoader] = useState<boolean>(true)

    useEffect(() => {
        onInit()
        //updateVisit(Number(id));
        return () => {
            setTrainingPreviewLoader(true)
            props?.closeModal()
        }
    }, [])


    useEffect(() => {
        (windowSize !== undefined && windowSize.current[0] < 600) ? setIsMobile(true) : setIsMobile(false)
    }, [windowSize])



    useEffect(() => {
        setFollowBtn(trainingDetails.followed)
    }, [trainingDetails])

    useEffect(() => {
        if (props.follow !== undefined && props?.follow[0]?.id !== undefined && followBtn) {
            history.push(`${props?.envUrl}${(trainingDetails.trainingType === 'COURSE') ? '/passCourse/course' : (trainingDetails.trainingType === 'PATH') ? '/passPath/progressPath' : '/passSession/progressSession'}/${props?.follow[0]?.id}`);
        }

    }, [props.follow])

    useEffect(() => {
        if (props && props.modalAction && props.modalAction.length !== 0 && props?.modalAction === 'connected') {
            followTrainingCheck()
        }
        (!props.modalAction || props.modalAction === "connected") && props?.clearSaveReturnPath()
    }, [props.modalAction])

    useEffect(() => {
        if ((props?.from?.path.includes('sign-in') || props?.from?.path.includes('sign-up')) && (props?.connectedUser && props?.connectedUser?.role?.includes("role_student")) && isMobile && trainingDetails.id !== 0) {
            props.clearSaveReturnPath()
            followTrainingCheck()
        }
    }, [props?.from, isMobile, trainingDetails])


    const onInit = async () => {
        let filter = {
            trainingId: Number(id),
            trainingType: type.toUpperCase(),
            connected: (props?.connectedUser && props?.connectedUser?.role?.includes("role_student")) ? true : false,
            studentId: (props?.connectedUser && props?.connectedUser?.role?.includes("role_student")) ? props.connectedUser.idPerson : null,
            entityId: (props?.connectedUser && props?.connectedUser?.role?.includes("role_student")) ? props.connectedUser.idSchool : null
        }
        const training = await getTrainingPreviewDetails(filter)

        if (training && training.trainingOffer?.pricings.length > 0) {
            setTrainingPrice(training.trainingOffer?.pricings[0]?.price)
        }
        setTrainingDetails(training)
        props?.clearHistoryArray()
        props?.redirect(t('Catalogue'), `${props?.envUrl}/catalog`)
        props?.redirect(training.title, `${props?.envUrl}/catalog/previewtraining/${training.trainingType.toLowerCase()}/${id}`)
        updateVisit(Number(id));
        setTrainingPreviewLoader(false)
    }

    const followTrainingCheck = async () => {
        setFollowBtn(true)
        if (trainingDetails.followed) {
            history.push(`${props?.envUrl}${(trainingDetails.trainingType === 'COURSE') ? '/passCourse/course' : (trainingDetails.trainingType === 'PATH') ? '/passPath/progressPath' : '/passSession/progressSession'}/${trainingDetails.followUpId}`);
        } else if (isMobile === false && (props?.connectedUser && props?.connectedUser?.role?.includes("role_student")) === undefined) {
            props?.openModal({ idModal: ModalIDS.SIGN_UP_MODAL, size: "sm", followTraining: { followTrainingCheck } });
            setFollowBtn(false)

        } else if (isMobile === false && (props?.connectedUser && props?.connectedUser?.role?.includes("role_student")) !== undefined || props?.modalAction.length !== 0) {
            await followTraining()
            props?.saveReturnPath({ path: `${props?.envUrl}/catalog/previewtraining/${trainingDetails.trainingType.toLocaleLowerCase()}/${id}`, tabPage: '' });

        } else if (isMobile === true && (props?.connectedUser && props?.connectedUser?.role?.includes("role_student")) === undefined) {
            props?.saveReturnPath({ path: `${props?.envUrl}/catalog/previewtraining/${trainingDetails.trainingType.toLocaleLowerCase()}/${id}`, tabPage: '' });
            history.push(`${props?.envUrl}/auth/sign-up`);

        } else if (isMobile === true && (props?.connectedUser && props?.connectedUser?.role?.includes("role_student")) !== undefined) {
            await followTraining()
        }
    }

    const followTraining = async () => {
        let path = ''
        if (trainingDetails.withregistrationFiles === 0 || trainingDetails.withregistrationFiles === null) {
            if (trainingPrice === 0) {
                let contractToCreate = {
                    contractIdentifier: {
                        contractId: null,
                        contractReference: null
                    },
                    contractDetails: {
                        offerId: trainingDetails && trainingDetails.trainingOffer && trainingDetails.trainingOffer.id,
                        contractDuration: {
                            startAt: new Date(Date.now()),
                            endAt: null
                        },
                        pricingId: trainingDetails.trainingOffer?.pricings[0]?.id,
                        paymentMethod: "CARD",
                        paymentEngine: "STRIPE"
                    },
                    contractRoles: [
                        {
                            personId: props?.connectedUser?.idPerson,
                            roleType: "STUDENT"
                        }
                    ],
                    status: [
                        {
                            statusType: "ONGOING"
                        }
                    ]
                }

                let follow = {
                    studentId: props.connectedUser.idPerson,
                    trainingId: trainingDetails.id,
                    trainingType: trainingDetails.trainingType,
                    entityId: props.connectedUser.idSchool,
                    entityType: "SCHOOL",
                    statusList: [{
                        status: "INSCRIBED"
                    }],
                }
                await props?.createFollow(follow, contractToCreate);
                setFollowBtn(true)

            } else {
                props?.redirect("Paiement", "")
                path = `${props?.envUrl}/courses/payement/${trainingDetails.trainingType}/${trainingDetails?.id}`
            }
        } else {
            await props?.getApplicationFile(props?.connectedUser?.idPerson, trainingDetails.withregistrationFiles);
            await props?.getRegistrationForm(trainingDetails.withregistrationFiles);
            props?.redirect(t('Subscription'), `${props?.envUrl}/catalog/inscription/${trainingDetails.trainingType?.toLowerCase()}/${trainingDetails.withregistrationFiles}`)
            path = `${props?.envUrl}/catalog/inscription/${trainingDetails.trainingType?.toLowerCase()}/${trainingDetails.withregistrationFiles}`
        }
        await props?.refreshToken(props?.connectedUser?.idSchool, props?.connectedUser?.idPerson);
        if (props?.modalAction.length !== 0 && props?.modalAction === 'connected') {
            props.clearModal()
        }
        if (path !== '') {
            history.push(path)
        }
    }

    const scrollToFeedback = () => {
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    };


    return (
        <div>
            {
                !trainingPreviewLoader ?
                    <>
                        <TrainingHeaderComponent
                            trainingDetails={trainingDetails}
                            followAction={followTrainingCheck}
                            followBtn={followBtn}
                            scrollToFeedback={scrollToFeedback}
                        />
                        {trainingDetails?.skills?.length !== 0 &&
                            <SkillsSectionComponent
                                skills={trainingDetails?.skills}
                                objectives={trainingDetails?.objectif}
                                prerequisite={trainingDetails?.prerequis}
                                isMobile={isMobile}
                            />
                        }
                        {trainingDetails.trainingType === "COURSE" &&
                            <ContentChaptersSectionComponent
                                type={trainingDetails.trainingType}
                                orders={trainingDetails.orders}
                                exams={trainingDetails.exams}
                                time={timeConvert(trainingDetails.duration)}
                                title={trainingDetails.title}
                                width={"100%"} />
                        }
                        {(trainingDetails.trainingType === "PATH") &&
                            <PathSessionContentSection
                                orders={trainingDetails.subjectsByPath}
                                exam={trainingDetails.exams}
                                time={timeConvert(trainingDetails.duration)}
                                title={trainingDetails.title}
                                width={"100%"}
                                courseInfo={trainingDetails}
                            />
                        }
                        {(trainingDetails.trainingType === "SESSION") &&
                            <PathSessionContentSection
                                orders={trainingDetails.subjectsBySession}
                                exam={trainingDetails.exams}
                                time={timeConvert(trainingDetails.duration)}
                                title={trainingDetails.title}
                                width={"100%"}
                                courseInfo={trainingDetails}

                            />
                        }

                        <SchoolProfSectionComponent profData={trainingDetails.professorBio} photoProf={trainingDetails.professorImage} profId={trainingDetails?.professorId}/>
                        <HowToWorkSectionComponent courseForm={trainingDetails} price={0} courseInfo={trainingDetails} type={trainingDetails.trainingType} show={false} />

                        <ListFeedbackSectionComponent ref={feedbackRef} trainingId={trainingDetails.id} />

                        <div className='suivre' style={{ textAlign: 'center', paddingBottom: "25px", paddingTop: "25px", backgroundColor: '#fff' }}>
                            <button
                                className="btn-Primary large"
                                onClick={async () => await followTrainingCheck()}
                                disabled={followBtn}
                            > suivre ce{trainingDetails.trainingType === "COURSE" ? " COURS" : trainingDetails.trainingType === "PATH" ? " PARCOURS" : "TTE SESSION"}</button>
                        </div>
                    </>
                    :
                    <>
                        <TrainingHeaderComponentLoading />
                        <SkillsSectionComponentLoading />
                        <CourseContentLoading />
                        <SchoolProfSectionComponentLoading />
                        <HowToWorkSectionComponentLoading />
                        <ListFeedbackSectionComponentLoading />
                        <ButtonFollowLoading />
                    </>
            }
        </div >

    )
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    follow: state.courseReducer.follow,
    modalAction: state.modalReducer.modalAction,
    from: state.historyReducer.returnPathPlateform,
    envUrl: state.historyReducer.envUrl
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    createFollow,
    refreshToken,
    clearHistoryArray,
    redirect,
    getApplicationFile,
    getRegistrationForm,
    saveReturnPath,
    clearSaveReturnPath,
    openModal,
    clearModal,
    closeModal,


}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TrainingPreviewPage);