import { useState, useCallback, FC, useEffect, useMemo } from 'react';
import update from 'immutability-helper';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { createTraining } from '../../global-state/actions/training-actions';
import SessionCardComponent from './session-card-component';
import { getDuration } from '../../base/functions/Functions';
import { useTranslation } from 'react-i18next';


const levels = [
    { value: 'BEGINNER', label: 'selectLevelCourse_1' },
    { value: 'INTERMEDIATE', label: 'selectLevelCourse_2' },
    { value: 'ADVANCED', label: 'selectLevelCourse_3' },
    { value: 'ANYLEVEL', label: 'anyLevelCourse' },
]
const FormationSessionCardContainer: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>> = (props: any) => {
    const { t } = useTranslation();
    const sortByIndex = (a: any, b: any) => {
        return a.index > b.index ? 1 : -1
    }
    const [cards, setCards] = useState<any[]>([]);
    const [exams, setExams] = useState<any[]>([]);

    useEffect(() => {

        setCards(props?.session?.subjectsBySession?.sort(sortByIndex).map((subject: any) => {
                
                
            let mandatories = subject?.mandatories.map((e: number) => e + 1)
            let data;
            if (subject?.entityType === "COURSE") {
                data =
                {

                    id: subject?.id,
                    entityId: subject?.entityId,
                    entityType: subject?.entityType,
                    startDay: subject?.startDay,
                    title: subject?.course?.title,
                    mandatories: mandatories,
                    duration: getDuration(subject?.course?.duration),
                    type: 'Cours',
                    icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.2222 0H1.77778C0.8 0 0 0.8 0 1.77778V14.2222C0 15.2 0.8 16 1.77778 16H14.2222C15.2 16 16 15.2 16 14.2222V1.77778C16 0.8 15.2 0 14.2222 0ZM14.2222 14.2222H1.77778V1.77778H14.2222V14.2222Z" fill="#5693EE" />
                        <line x1="5.38184" y1="9.77632" x2="10.6187" y2="9.77632" stroke="#5693EE" strokeWidth="1.5" strokeLinecap="round" />
                        <line x1="10.6182" y1="6.22368" x2="5.38132" y2="6.22368" stroke="#5693EE" strokeWidth="1.5" strokeLinecap="round" />
                    </svg>,
                    other: <><span className="material-icons-outlined neutral-3 me-2 pe-1">
                        signal_cellular_alt
                    </span>
                        <span className="body-md bold black-800" style={{ width: 'max-content' }}>
                            {(subject?.course?.level === 'ANYLEVEL') ? t(levels.find((level: any) => level?.value === subject?.course?.level)?.label + '') : t('selectLevelCourse') + " " + t(levels.find((level: any) => level?.value === subject?.course?.level)?.label + '')}

                        </span></>,
                    classNames: "ms-2 d-flex flex-row detail-liste-responsive-other flex-wrap"
                }
            }
            else if (subject?.entityType === "PATH") {
                data =
                {
                    id: subject?.id,
                    entityId: subject?.entityId,
                    entityType: subject?.entityType,
                    startDay: subject?.startDay,
                    title: subject?.path?.title,
                    mandatories: mandatories,
                    duration: getDuration(subject?.path?.duration),
                    type: 'Parcours',
                    icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.45455 2.90909H0V14.5455C0 15.3455 0.654545 16 1.45455 16H13.0909V14.5455H1.45455V2.90909ZM14.5455 0H4.36364C3.56364 0 2.90909 0.654545 2.90909 1.45455V11.6364C2.90909 12.4364 3.56364 13.0909
                 4.36364 13.0909H14.5455C15.3455 13.0909 16 12.4364 16 11.6364V1.45455C16 0.654545 15.3455 0 14.5455 0ZM14.5455 11.6364H4.36364V1.45455H14.5455V11.6364Z" fill="#FA83EE" />
                    </svg>,
                    other: <><span className="material-icons-outlined neutral-3 me-2 pe-1">
                        signal_cellular_alt
                    </span>
                        <span className="body-md bold black-800" style={{ width: 'max-content' }}>
                            {(subject?.path?.level === 'ANYLEVEL') ? t(levels.find((level: any) => level?.value === subject?.path?.level)?.label + '') : t('selectLevelCourse') + " " + t(levels.find((level: any) => level?.value === subject?.path?.level)?.label + '')}

                        </span></>,
                    classNames: "ms-2 d-flex flex-row detail-liste-responsive-other flex-wrap"
                }
            }
            return data;
            
        }))

        setExams(props?.session?.exams.map((data: any) => {
            return {
                id: data?.id,
                title: data?.title,
                type: 'Examen final',
                duration: getDuration(data?.duration),
                icon: <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.3469 3.94322V1.41788C13.3469 0.633432 12.6588 0 11.7905 0H1.57023C0.706601 0 0 0.633432 0 1.41788V12.4169C0 13.2056 0.706601 13.8432 1.57023 13.8432H9.23662V15.4121C9.23662 15.6261 9.37055
                15.819 9.57376 15.9239C9.77696 16.0288 10.031 16.0246 10.2296 15.9155L12.4879 14.678L14.7463 15.9155C14.8479 15.97 14.9633 15.9994 15.0834 15.9994C15.1942 15.9994 15.3143 15.9742 15.4113 15.9239C15.6145
                15.819 15.7484 15.6261 15.7484 15.4121V10.7977C16.5336 10.0594 17 9.06102 17 7.95775C16.9954 5.97356 15.4252 4.31237 13.3469 3.94322ZM9.23662 12.6686H1.57023C1.41782 12.6686 1.29313 12.5596 1.29313
                12.4169V1.41788C1.29313 1.27945 1.41782 1.17458 1.57023 1.17458H11.7905C11.9429 1.17458 12.0538 1.27945 12.0538 1.41788V3.8803C9.79082 4.07326 7.9712 5.82674 7.9712 7.95356C7.9712 9.05682 8.45151 10.0594
                 9.23662 10.7935V12.6686ZM14.4553 14.3718L12.8343 13.4867C12.6265 13.3734 12.3586 13.3734 12.1508 13.4867L10.5297 14.3718V11.6451C11.1301 11.901 11.7952 12.0478 12.4925 12.0478C13.1899 12.0478 13.8549
                 11.9052 14.4553 11.6451V14.3718ZM12.4925 10.8732C10.7237 10.8732 9.2828 9.56441 9.2828 7.95775C9.2828 6.3511 10.7237 5.04229 12.4925 5.04229C14.2613 5.04229 15.7023 6.34691 15.7023 7.95775C15.7023 9.56441
                 14.2613 10.8732 12.4925 10.8732Z" fill="#9DDBA9" />
                    <path d="M8.35911 3.10413H2.54004V4.27871H8.35911V3.10413Z" fill="#9DDBA9" />
                    <path d="M6.4656 6.33429H2.54004V7.50887H6.4656V6.33429Z" fill="#9DDBA9" />
                    <path d="M7.34308 9.56445H2.54004V10.739H7.34308V9.56445Z" fill="#9DDBA9" />
                </svg>,
                other: <>
                    {/* <span className="body-md bold black-800 me-2 pe-1">
                            85%
                        </span>
                        <span className="body-md bold neutral-3" style={{ width: 'max-content' }}>
                            Taux de réussite
                        </span> */}
                </>,
                classNames: "ms-4 d-flex flex-row detail-liste-responsive-other flex-wrap ps-sm"


            }
        }))



    }, [props?.session])

 
    async function moveCard (dragIndex:any, hoverIndex:any){
        const dragCard = cards[dragIndex]
        let newList =await update(cards, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragCard],
            ],
        })
        if (dragCard != cards){
            await setCards(newList);

        }
    }



    useEffect(() => {
        if (!(props?.session?.subjectsBySession as Array<any>).sort(sortByIndex).every((subjectBySession: any, index: number) => subjectBySession.entityId == cards[index]?.entityId && subjectBySession.entityType === cards[index]?.entityType)) {
                        
            let training = {
                ...props?.session, type: "SESSION", subjectsBySession: props?.session?.subjectsBySession?.sort(sortByIndex).map((subjectBySession: any) => {
                    if (cards?.findIndex((e: any) => subjectBySession?.entityId === e?.entityId && subjectBySession?.entityType === e?.entityType) >= 0)
                        subjectBySession.index = cards?.findIndex((e: any) => subjectBySession?.entityId === e?.entityId && subjectBySession?.entityType === e?.entityType)

                    return subjectBySession

                })
            }
            
            props?.createTraining("sessions", training)
        }

    }, [cards])



    const options = useMemo(() => {
        if (Array.isArray(cards)) {
            return cards?.map((option: any, index: number) => { return { value: index + 1, label: option?.title, id: option?.id } })
        }
        return []
    }, [cards])

    const renderCard = (data: any, cardsLength: number, index: number) => {
        return (<SessionCardComponent t={props?.t} key={index} index={index} data={data} cardsLength={cardsLength} moveCard={moveCard} options={options} />);
    }; 
    return (<>
        {cards.map((card: any, index: number) => renderCard(card, cards.length, index))}
    </>);
}
const mapStateToProps = (state: AppState) => ({
    trainings: state.trainingReducer.trainings,
    session: state.trainingReducer.session
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            createTraining
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(FormationSessionCardContainer);