import { fetchData } from "../base/functions/Functions";
import axiosInstance from "../helpers/interceptors";
export const getFollowUpActivityApi = async (list: any, idSchool: any, idPerson: any) => {

    const baseUrl = process.env.REACT_APP_BASE_URL3;
    let allKpiFolowUp: any = await fetchData("POST", baseUrl + `kpi/KpiFollowUp/${idPerson}/${idSchool}`, {
        ids: list,
        times: localStorage?.getItem('lastConnexion') ? localStorage?.getItem('lastConnexion')?.replace(/\"/g, "")?.split("T")[0] : new Date().toISOString().split('T')[0],

    }).then(res => { return res }).catch(err => {
        return {
            "connectedStudentDto": {
                "studentNbre": 0,
                "lastDays": 0
            },
            "allFollowersNumber": 0,
            "appFileNumber": 0,
            "forNumber": 0,
            "studentDto": {
                "percentagemale": 0,
                "percentagefemale": 0,
                "avgage": 0,
                "ville": []
            },
            "suspendues": {
                "suspendueNbre": 0
            },
            "newCandidateNumber": 0,
            "inscritNumberProf": 0,
            "newInscribedNumber": 0,
            "years": [],
            "certifNumber": 0
        }
    });
    let data = {

        //old get ActivityApi exept visit and markMedian
        connectedList: allKpiFolowUp.connectedStudentDto, //connecter las 30 dernières jours
        followers: allKpiFolowUp.allFollowersNumber,// relation avec taux de transformation
        appFile: allKpiFolowUp.appFileNumber,//Condidats
        formation: allKpiFolowUp.forNumber,// cours
        certif: allKpiFolowUp.certifNumber,//Diplomés
        statistic: allKpiFolowUp.studentDto,//statistic,// Age moyen / % Homme / % Femme/ (Proffesseur ) & ville (Proffesseur et Directeur)
        refused: allKpiFolowUp.suspendues.suspendueNbre,//suspendus
        newCandidate: allKpiFolowUp.newCandidateNumber,//nouveaux candidats 
        // la premiere endpoint dans getNewCoursesApi
        newInscribed: allKpiFolowUp.newInscribedNumber,//nouveaux inscrits
        // all old getSubscriptionApi
        subscriptionProf: allKpiFolowUp.inscritNumberProf,//Inscrits (Proffesseur)    
        //all old getTimesConnectionApi
        year: allKpiFolowUp.years, //retourner les années  

    }
    return data
}

export const getPersonActivityApi = async (idSchool: any, list: any, idPerson: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL2;
    let allKpiPerson: any = await fetchData("POST", baseUrl + `person/kpiPerson/${idPerson}/${idSchool}`, {
        idSchool: idSchool,
        ids: list
    }).then(res => { return res }).catch(err => {
        return {
            "visitLast_30_Days": 0,
            "countSchoolActifStudent": 0,
            "genderInfos": {
                "malePour": 0,
                "femalePour": 0,
                "avg": 0
            }
        }
    });
    let data = {
        // 2eme dans getActivityApi
        visit: allKpiPerson.visitLast_30_Days,//visiteur lors de 30 dernières jours
        // 3eme api dans getActivityDirectorApi
        countSchoolActifStudent: allKpiPerson.countSchoolActifStudent, //Inscrits (Directeur )
        // all old getGenderStatsApi
        genderInfos: allKpiPerson.genderInfos // Age moyen / % Homme / % Femme (Directeur)


    }
    return data;

}

export const getTrainingActivityApi = async (list: any, idSchool: any, idPerson: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    let nbrProfs=  await fetchData('GET', process.env.REACT_APP_BASE_URL2 + `person/countTeachers/${Number(idSchool)}`, {})
    .then((response:any)=>{return response.teacherNumber})

    console.log("nbrProfs ", nbrProfs);
    
    let allKpiPerson: any = await fetchData("POST", baseUrl + `courses/KpiCourses/${idPerson}/${idSchool}`, {
        idSchool: idSchool,
        times: localStorage?.getItem('lastConnexion')?.replace(/\"/g, "")?.split("T")[0],
        ids: list
    }).then(res => { return res }).catch(err => {
        return {
            "newCoursesNumber": 0,
            "profsNumber": 0,
            "newProfsNumber": 0,
        }
    });
    let data = {
        // 2eme api dans getNewCoursesApi
        newCourses: allKpiPerson.newCoursesNumber,//new formations(directeur et proffesseur) 
        // 1ere et 2eme api dans getActivityDirectorApi
        profs: nbrProfs,//nombre Professeurs (Directeur)
        newProfs: allKpiPerson.newProfsNumber,//nombre Nouveau Professeurs (Directeur)

    }
    return data;

}

export const getProfActivityApi = async (list: any) => {
    const baseUrl1 = process.env.REACT_APP_BASE_URL2;
    const baseUrl2 = process.env.REACT_APP_BASE_URL;
    let visit: any = await fetchData("POST", baseUrl1 + 'visit/lastDays', { ids: list }).then(res => { return res }).catch(err => { return 0 });
    let newCourses: any = await fetchData("POST", baseUrl2 + 'courses/new', { times: localStorage?.getItem('lastConnexion')?.replace(/\"/g, "")?.split("T")[0], ids: list }).then(res => { return res }).catch(err => { return 0 });

    let data = {
        visitLast_30_Days: visit,//visiteur lors de 30 dernières jours
        newCoursesProf: newCourses,//new formations(proffesseur) 
    }
    return data;
}
export const getMarkMedianActivityApi = async (list: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL6;
    let markMedian: any = await fetchData("POST", baseUrl + 'progress/median/course', { ids: list }).then(res => { return res }).catch(err => { return 0 });

    let data = {
        markMedian: markMedian  //note mediane
    }
    return data;
}
export const getListCoursesForDirecteurApi = async (schoolId: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    let coursesList = await fetchData("GET", baseUrl + `training/getTrainingsIdsListBySchoolId/${schoolId}`, {}).then(res => { return res }).catch(err => { return { data: [] } });
    return coursesList;
}
export const getListCoursesForProfesseurApi = async (schoolId: any, profId: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    let coursesList = await fetchData("GET", baseUrl + `training/getTrainingsIdsBySchoolAndProfIdActivities/${schoolId}/${profId}`, {}).then(res => { return res }).catch(err => { return { data: [] } });
    return coursesList;
}

export const getStatisticsApi = async (year: any, list: any, schoolId: number) => {
    const baseUrl1 = process.env.REACT_APP_BASE_URL2;
    let appFile: any = await fetchData("GET", baseUrl1 + `person/getActifStudentPerYear/${schoolId}/${year}`, {})
    let visit: any = await fetchData("POST", baseUrl1 + `visit/period`, { year: year, ids: list }).then(res => { return res }).catch(err => { return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] });

    let data = {
        appFile: appFile,
        visit: visit,
    }
    return data
}

export const getTimesApi = async () => {
    const baseUrl = process.env.REACT_APP_BASE_URL3;
    let visit: any = await fetchData("GET", baseUrl + `kpi/years`, {}).then(res => { return res }).catch(err => { return [] });
    let data = {
        visit: visit,
    }
    return data;
}

export const getTimesConnectionApi = async () => {
    const baseUrl4 = process.env.REACT_APP_BASE_URL1;
    let connected: any = await fetchData("GET", baseUrl4 + `history/years`, { headers: { Authorization: 'Bearer ' + localStorage?.getItem('access_token')?.replace(/\"/g, "") } }).then(res => { return res }).catch(err => { return [] });
    let data = {
        connected: connected
    }
    return data
}

export const getStatisticsConnectionApi = async (year: any, list: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL3;

    let connexion: any = await fetchData("POST", baseUrl + `kpi/students/period/course`, { year: year, ids: list }).then(res => { return res }).catch(err => { return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] });
    let data = {
        connexion: connexion
    }
    return data
}

export const getStatisticsCertificationApi = async (year: any, list: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL3;

    let subscription: any = await fetchData("POST", baseUrl + `kpi/inscrit/period`, { year: year, ids: list }).then(res => { return res }).catch(err => { return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] });
    let certification: any = await fetchData("POST", baseUrl + `kpi/Certif/period`, { year: year, ids: list }).then(res => { return res }).catch(err => { return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] });

    let data = {
        subscription: subscription,
        certification: certification,
    }
    return data
}



// tous les restes sont non utilisable 
export const getDiplomaApi = async (list: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL3;
    let avgDuration: any = await fetchData("POST", baseUrl + `kpi/avgDuration`, { ids: list }).then(res => { return res }).catch(err => { return 0 });
    let diplomaDays: any = await fetchData("POST", baseUrl + `kpi/diplomaDays`, { ids: list }).then(res => { return res }).catch(err => { return 0 });

    let data = {

        avgDuration: avgDuration,
        diplomaDays: diplomaDays
    }
    return data
}

export const getActivityApi = async (list: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL3;
    const baseUrl1 = process.env.REACT_APP_BASE_URL2;
    const baseUrl3 = process.env.REACT_APP_BASE_URL6;
    let connectedList: any = await fetchData("POST", baseUrl + `kpi/students/course`, { ids: list }).then(res => { return res }).catch(err => {
        return {
            studentNbre: 0,
            lastDays: 0
        }
    });
    let followers: any = await fetchData("POST", baseUrl + 'kpi/all', { ids: list }).then(res => { return res }).catch(err => { return 0 });
    let visit: any = await fetchData("POST", baseUrl1 + 'visit/lastDays', { ids: list }).then(res => { return res }).catch(err => { return 0 });
    let appFile: any = await fetchData("POST", baseUrl + 'kpi/appFile/course', { ids: list }).then(res => { return res }).catch(err => { return 0 });
    let formation: any = await fetchData("POST", baseUrl + 'kpi/For/course', { ids: list }).then(res => { return res }).catch(err => { return 0 });
    let certif: any = await fetchData("POST", baseUrl + 'kpi/Certif/course', { ids: list }).then(res => { return res }).catch(err => { return 0 });
    let statistic: any = await fetchData("POST", baseUrl + 'kpi/statisticStudent/course', { ids: list }).then(res => { return res }).catch(err => {
        return {
            percentagemale: 0,
            percentagefemale: 0,
            avgage: 0,
            ville: []
        }
    });
    let refused: any = await fetchData("POST", baseUrl + 'kpi/suspendue', { ids: list }).then(res => { return res }).catch(err => { return { suspendueNbre: 0 } });
    let markMedian: any = await fetchData("POST", baseUrl3 + 'progress/median/course', { ids: list }).then(res => { return res }).catch(err => { return 0 });
    let date = localStorage?.getItem('lastConnexion') ? localStorage?.getItem('lastConnexion')?.replace(/\"/g, "")?.split("T")[0] : new Date().toISOString().split('T')[0];
    let newCandidate: number = 0;
    if (date !== undefined) {
        newCandidate = await fetchData("POST", baseUrl + 'kpi/For/new', { times: date, ids: list })
            .then(res => { return res })
            .catch(err => { return 0 });
    }

    let data = {
        connectedList: connectedList,
        followers: followers,
        visit: visit,
        appFile: appFile,
        formation: formation,
        certif: certif,
        statistic: statistic,
        refused: refused,
        newCandidate: newCandidate,
        markMedian: markMedian,
    }
    return data
}

export const getSubscriptionApi = async (list: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL3;
    let subscription: any = await fetchData("POST", baseUrl + 'kpi/allInscribedBySchools', { ids: Array.from(list) }).then(res => { return res }).catch(err => { return 0 });

    let data = {
        subscription: subscription,
    }
    return data;
}

export const getNewCoursesApi = async (list: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL3;
    const baseUrl2 = process.env.REACT_APP_BASE_URL;

    let newInscribed: any = await fetchData('POST', baseUrl + 'kpi/inscrit/new', { times: localStorage?.getItem('lastConnexion')?.replace(/\"/g, "")?.split("T")[0], ids: list }).then(res => { return res }).catch(err => { return 0 });
    let newCourses: any = await fetchData("POST", baseUrl2 + 'courses/new', { times: localStorage?.getItem('lastConnexion')?.replace(/\"/g, "")?.split("T")[0], ids: list }).then(res => { return res }).catch(err => { return 0 });

    let data = {
        newInscribed: newInscribed,
        newCourses: newCourses,
    }

    return data
}

export const getActivityDirectorApi = async (list: any) => {
    let subscriptionSchool: number = 0;
    const baseUrl2 = process.env.REACT_APP_BASE_URL;
    let profs: any = await fetchData('POST', baseUrl2 + `courses/profs`, { ids: [list] })
    let newProfs: any = await fetchData('POST', baseUrl2 + `courses/newProfs`, { times: localStorage?.getItem('lastConnexion')?.replace(/\"/g, "")?.split("T")[0], ids: [list] })
    if ((localStorage.getItem('access_token') !== undefined) && (localStorage.getItem('access_token') !== null) && (localStorage.getItem('access_token') !== '')) {
        subscriptionSchool = await fetchData('GET', process.env.REACT_APP_BASE_URL2 + `person/countSchoolActifStudent/${list}`, {})
    }

    let data = {
        profs: profs,
        newProfs: newProfs,
        subscriptionSchool: subscriptionSchool
    }

    return data
}
export const getGenderStatsApi = async (schoolId: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL2;
    let stats: any = await fetchData("GET", baseUrl + "person/countSchoolStudentGender/" + schoolId, {}).then(res => { return res }).catch(err => { return 0 });
    return stats;
}
