import { FC, useEffect, useState, ComponentProps } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppState } from "../../global-state/store/root-reducers";
import CardCatalogVerticalComponent from "./catalog-components/card-catalog-vertical-component";
import CardCatalogHorizontalComponent from "./catalog-components/card-catalog-horizontal-component";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import Axios from "../../helpers/interceptors";
import {
  redirect,
  clearSaveReturnPath
} from "../../global-state/actions/breadcrumb-actions";
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import EmptyListElement from "../../elements/empty-list/normal-empty-list-element";
import CardCourseVerticalLoading from "./catalog-components/card-course-vertical-loading";
import CardCourseHorizontalLoading from "./catalog-components/card-course-horizontal-loading";
import { returnStartPathUrl } from "../../helpers/domainCheck";
import { fetchData } from "../../base/functions/Functions";
import {
  getContent,
  getCourse
} from "../../global-state/actions/course-actions";
import SelectElement from "../../elements/select/select-element";

const CatalogComponent: FC<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ComponentProps<any>
> = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [loadingScroll, setLoadingScroll] = useState<boolean>(false);
  const [atBottom, setAtBottom] = useState(false);
  const [styleCardCourse, setStyleCardCourse] = useState("vertical");
  const [courses, setCourses] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [professors, setProfessors] = useState<any>([]);
  const [profId, setProfId] = useState<any>(null);
  const [thematiques, setThematiques] = useState<any>([]);
  const [theme, setTheme] = useState<any>(null);
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [filterOpened, setFilterOpened] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<string>("themes");

  const getData = async () => {
    let filter = {
      entityId: props?.schoolInfo?.id,
      entityType: "SCHOOL",
      status: "PUBLISHED",
      pageSize: 6,
      pageNumber: pageNumber,
      idProf: profId,
      theme: theme
    };
    await fetchCatalog(filter);
    let incremPageNumber = pageNumber + 1;
    setPageNumber(incremPageNumber);
  };

  const fetchCatalog = async (filter: Object) => {
    try {
      let fetchedCourses: never[] = await fetchData(
        "POST",
        `${process.env.REACT_APP_BASE_URL14}training/catalog`,
        filter
      );
      if (fetchedCourses.length < 5) {
        if (fetchedCourses.length > 0) {
          setCourses([...courses, ...fetchedCourses]);
        }
        setLoading(false);
        setLoadingScroll(false);
        return;
      }
      setCourses([...courses, ...fetchedCourses]);
      setLoading(false);
      setLoadingScroll(true);
    } catch (error) {
      console.error("error", error);
    }
  };

  const fetchProfessors = async () => {
    Axios.get(
      `${process.env.REACT_APP_BASE_URL2}person/getProfsPerSchoolId/${props.schoolInfo.id}`
    ).then((response) => {
      const data = [];
      data.push({ label: "Tous les professeurs", value: 0, id: 0 });
      response?.data?.forEach((el: any) => {
        data.push({ label: el.name, value: el.id, id: el.id });
      });
      setProfessors(data);
    });
  };

  const fetchThematiques = async () => {
    Axios.get(
      `${process.env.REACT_APP_BASE_URL}training/getThemeBySchoolId/${props.schoolInfo.id}`
    ).then((response) => {
      const data = [];
      data.push({ label: "Toutes les thématiques", value: 0, id: 0 });
      response?.data?.forEach((el: any) => {
        data.push({ label: el, value: el, id: el });
      });
      setThematiques(data);
    });
  };

  useEffect(() => {
    initFetch();
  }, []);

  const initFetch = async () => {
    let filter = {
      entityId: props?.schoolInfo?.id,
      entityType: "SCHOOL",
      status: "PUBLISHED",
      pageSize: 6,
      pageNumber: 0,
      idProf: null,
      theme: null
    };
    if (props?.user) fetchProfessors();
    fetchThematiques();
    await fetchCatalog(filter);
    setFirstRender(false);
  };

  useEffect(() => {
    setCourses([]);
    if (!firstRender) {
      getData();
    }
  }, [profId]);

  useEffect(() => {
    setCourses([]);
    if (!firstRender) {
      getData();
    }
  }, [theme]);

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  });

  const handleScroll = async () => {
    if (
      !atBottom &&
      window.innerHeight + window.scrollY + 1 >=
      document.documentElement.scrollHeight
    ) {
      loadingScroll && (await getData());
      document.removeEventListener("scroll", handleScroll);
    }
    if (
      window.innerHeight + window.scrollY <=
      document.documentElement.scrollHeight
    ) {
      setAtBottom(false);
    }
  };

  async function redirect(course: any) {
    if (course.type === "COURSE") {
      await props?.getCourse(course.id);
    } else {
      let filter = {
        trainingType: course.type,
        trainingId: Number(course.id)
      };
      await props?.getContent(filter);
    }
    props?.clearSaveReturnPath();
    history.push(
      `${returnStartPathUrl()}/catalog/previewtraining/${course.type.toLowerCase()}/${course.id}`
    );
  }

  const filterProf = async (e: any) => {
    setCourses([]);
    setPageNumber(0);
    if (e > 0) {
      setProfId(e);
    } else {
      setProfId(null);
    }
  };

  const filterThematique = async (e: any) => {
    setCourses([]);
    setPageNumber(0);
    if (e !== 0) {
      setTheme(e);
    } else {
      setTheme(null);
    }
  };

  const disableOverFLow = () => (
    <style>
      {`
            body{
                overflow:hidden;
            }
        `}
    </style>
  );

  useEffect(() => {
    return () => props?.clearSaveReturnPath()
  }, [])

  return (
    <>
      {filterOpened && disableOverFLow()}
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div
            className='col-md-12 py-5'
            style={{ backgroundColor: "#FFFFFF" }}
          >
            <div
              className='text-center'
              style={{ maxWidth: "1200px", margin: "auto" }}
            >
              <h2 className='H2-Headline' style={{ display: "inline-block" }}>
                {t("Catalogue")}
              </h2>
              <div
                className='d-flex align-items-center'
                style={{ float: "right" }}
              >
                <span
                  className='material-icons-outlined mt-2'
                  onClick={() => setStyleCardCourse("horizontal")}
                  style={{
                    cursor: "pointer",
                    color: `${styleCardCourse === "horizontal" ? "#272727" : "#CACBCE"}`,
                    fontSize: 30
                  }}
                >
                  {" "}
                  view_headline{" "}
                </span>
                <span
                  className='material-icons-outlined mt-2'
                  onClick={() => setStyleCardCourse("vertical")}
                  style={{
                    cursor: "pointer",
                    color: `${styleCardCourse === "vertical" ? "#272727" : "#CACBCE"}`,
                    fontSize: 30
                  }}
                >
                  {" "}
                  apps{" "}
                </span>
              </div>
            </div>
            <div
              className='d-none d-md-flex justify-content-end mt-4'
              style={{ maxWidth: "1200px", margin: "auto" }}
            >
              {props?.user && (
                <>
                  <div
                    style={{
                      maxWidth: "225px",
                      minWidth: "225px",
                      marginRight: "20px"
                    }}
                  >
                    <SelectElement
                      disabled={false}
                      taille={"lg"}
                      defaultValue={"none"}
                      options={thematiques}
                      onChange={(e: any) => {
                        filterThematique(e);
                      }}
                      placeholder={"Thématiques"}
                    />
                  </div>
                  <div style={{ maxWidth: "225px", minWidth: "225px" }}>
                    <SelectElement
                      disabled={false}
                      taille={"lg"}
                      defaultValue={"none"}
                      options={professors}
                      onChange={(e: any) => {
                        filterProf(e);
                      }}
                      placeholder={t("filterProfessors")}
                    />
                  </div>
                </>
              )}
            </div>
            <div className='d-block d-md-none'>
              <div className='d-flex d-lg-none align-items-center px-2'>
                <div className='w-100 relative mt-2'>
                  <label
                    htmlFor='search-catalog'
                    className='catalog-search-bar-icon'
                  >
                    <input
                      type='text'
                      className='w-100 py-3 relative border-0 catalog-search-bar'
                      placeholder='Que souhaitez vous apprendre ?'
                      name='search-catalog'
                    />
                  </label>
                </div>
                <div
                  onClick={() => setFilterOpened(!filterOpened)}
                  className='ms-3'
                >
                  <svg
                    width='28'
                    height='16'
                    viewBox='0 0 18 11'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M7.16667 11H10.8333V9.16667H7.16667V11ZM0.75 0V1.83333H17.25V0H0.75ZM3.5 6.41667H14.5V4.58333H3.5V6.41667Z'
                      fill='#272727'
                    />
                  </svg>
                </div>
                <div
                  className='p-4'
                  style={{
                    transform: filterOpened
                      ? "translateX(0)"
                      : "translateX(100%)",
                    transition: "transform 0.1s ease-in-out",
                    zIndex: 999,
                    width: "85%",
                    height: "calc(100vh - 184px)",
                    backgroundColor: "white",
                    position: "fixed",
                    right: "0%",
                    top: 184
                  }}
                >
                  <div
                    className='d-flex'
                    style={{ height: 28 }}
                    id='catalog-filter-header-responsive'
                  >
                    <div
                      style={{ marginRight: 16 }}
                      onClick={() => setFilterOpened(false)}
                      id='catalog-close-filter-drawer'
                    >
                      <svg
                        width='18'
                        height='18'
                        viewBox='0 0 12 12'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M10.1355 11.625L11.2686 10.492L6.77659 6L11.2686 1.50804L10.1355 0.375L5.64355 4.86696L1.15159 0.375L0.0185547 1.50804L4.51052 6L0.0185547 10.492L1.15159 11.625L5.64355 7.13304L10.1355 11.625Z'
                          fill='#323232'
                        />
                      </svg>
                    </div>
                    <div className='w-100 d-flex text-center'>
                      <div
                        onClick={() => setSelectedFilter("themes")}
                        style={
                          selectedFilter === "themes"
                            ? {
                              width: "50%",
                              borderBottom: "2px solid #272727",
                              fontWeight: "bold"
                            }
                            : {
                              width: "50%",
                              borderBottom: "1px solid #D9D9D9"
                            }
                        }
                      >
                        Thématique
                      </div>

                      <div
                        onClick={() => setSelectedFilter("profs")}
                        style={
                          selectedFilter === "profs"
                            ? {
                              width: "50%",
                              borderBottom: "2px solid #272727",
                              fontWeight: "bold"
                            }
                            : {
                              width: "50%",
                              borderBottom: "1px solid #D9D9D9"
                            }
                        }
                      >
                        Professeurs
                      </div>
                    </div>
                  </div>
                  <div
                    className='mt-4'
                    style={{
                      overflow: "auto",
                      height: "calc(100vh - 282px)",
                      overflowX: "hidden"
                    }}
                  >
                    {selectedFilter === "themes" && thematiques && (
                      <div>
                        {thematiques?.map((thematique: any) => (
                          <div
                            onClick={() => {
                              filterThematique(thematique.id);
                            }}
                            className='py-3'
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              fontWeight:
                                theme === thematique.id ||
                                  (theme === null && thematique.id === 0)
                                  ? "bold"
                                  : "normal"
                            }}
                          >
                            {thematique.label}
                          </div>
                        ))}
                      </div>
                    )}
                    {selectedFilter === "profs" && professors && (
                      <div>
                        {professors?.map((professor: any) => (
                          <div
                            onClick={() => {
                              filterProf(professor.id);
                            }}
                            className='py-3'
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              fontWeight:
                                profId === professor.id ||
                                  (profId === null && professor.id === 0)
                                  ? "bold"
                                  : "normal"
                            }}
                          >
                            {professor.label}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className='px-4'
            style={{ margin: "50px auto", maxWidth: "1248px" }}
          >
            {!loading && (
              <div
                className={
                  styleCardCourse === "vertical" && courses?.length > 0
                    ? "grid-cards"
                    : "d-flex w-100 flex-wrap align-items-center"
                }
              >
                {courses?.length === 0 && (
                  <div className='col-md-12 text-center mt-5 '>
                    <EmptyListElement
                      lines={[t("noCoursesPublished")]}
                    ></EmptyListElement>
                  </div>
                )}
                {courses?.map((course: any, index: number) => {
                  return styleCardCourse === "vertical" ? (
                    <div
                      className=' justify-content-center'
                      style={{ cursor: "pointer" }}
                      key={index}
                      id={course.id}
                    >
                      <CardCatalogVerticalComponent
                        t={t}
                        key={course.id}
                        course={course}
                        onClick={() => {
                          redirect(course);
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      className='col-md-12 mb-3'
                      style={{ cursor: "pointer" }}
                      key={index}
                    >
                      <CardCatalogHorizontalComponent
                        t={t}
                        key={course.id}
                        course={course}
                        onClick={() => {
                          redirect(course);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            )}
            {loading && (
              <div
                className={`${styleCardCourse === "vertical" ? "grid-cards p-0" : "d-flex w-100 flex-wrap align-items-center justify-content-between"}`}
              >
                {styleCardCourse === "vertical" ? (
                  <>
                    <div className='mb-3 justify-content-center'>
                      <CardCourseVerticalLoading />
                    </div>
                    <div className='mb-3 justify-content-center'>
                      <CardCourseVerticalLoading />
                    </div>
                    <div className='mb-3 justify-content-center'>
                      <CardCourseVerticalLoading />
                    </div>
                  </>
                ) : (
                  <>
                    <div className='mb-3 justify-content-center w-100'>
                      <CardCourseHorizontalLoading />
                    </div>
                    <div className='mb-3 justify-content-center w-100'>
                      <CardCourseHorizontalLoading />
                    </div>
                    <div className='mb-3 justify-content-center w-100'>
                      <CardCourseHorizontalLoading />
                    </div>
                  </>
                )}
              </div>
            )}
            {loadingScroll && courses?.length > 0 && (
              <div className='d-flex w-100 align-items-center justify-content-center'>
                <div className='m-3'>
                  <svg className='spinner' viewBox='0 0 50 50'>
                    <circle
                      className='path'
                      cx='25'
                      cy='25'
                      r='20'
                      fill='none'
                      strokeWidth='5'
                    ></circle>
                  </svg>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  user: state.authReducer.connectedUser
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getContent,
      getCourse,
      redirect,
      clearSaveReturnPath
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CatalogComponent);
